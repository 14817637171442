<template>
    <v-sheet>
        <template v-if="!readonly">
            <v-card-subtitle class="px-6"> 파일을 첨부하세요 </v-card-subtitle>
            <v-divider />
        </template>
        <v-row class="ma-0 pa-3">
            <v-col cols="12" class="pa-1">
                <image-input-btn v-model="form.receipt" title="영수증" large outlined block color="primary" v-bind="{ readonly }" accept="image/*"> <v-icon class="mr-10">mdi-camera-enhance</v-icon> </image-input-btn>
            </v-col>
            <v-col cols="6" class="pa-1"> <image-input-btn v-model="form.businessCard" title="명함" large outlined block color="primary" v-bind="{ readonly }" accept="image/*" /> </v-col>
            <v-col cols="6" class="pa-1"> <image-input-btn v-model="form.greetingCard" title="인사장" large outlined block color="primary" v-bind="{ readonly }" accept="image/*" /> </v-col>
        </v-row>
        <template v-if="!readonly">
            <v-divider />
            <v-card-actions>
                <v-btn x-large text block color="primary" @click="emit">첨부완료</v-btn>
            </v-card-actions>
        </template>
    </v-sheet>
</template>

<script>
import { attrs_input } from "@/assets/variables";

import ImageInputBtn from "@/components/console/dumb/image-input-btn.vue";

export default {
    components: {
        ImageInputBtn,
    },
    props: {
        value: { type: Object, default: () => ({}) }, // form
        readonly: { type: Boolean, default: false },
    },
    data: () => ({
        form: {
            receipt: null,
            businessCard: null,
            greetingCard: null,
        },

        attrs_input,
    }),
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.form = { ...this.value };
        },
        emit() {
            this.$emit("input", this.form);
            this.$emit("forward");
        },
    },
};
</script>

<style>
</style>