<template>
    <autocomplete-user v-model="query._manager" label="관리자" placeholder="전체" clearable v-bind="{ ...attrs_input, params }" rounded filled background-color="white" style="width: 180px" append-icon="" v-on="{ input }" />
</template>

<script>
import { attrs_input, USER_SCOPES } from "@/assets/variables";

import AutocompleteUser from "@/components/console/dumb/autocomplete-user.vue";

const params = {
    scope: { $in: [USER_SCOPES.console.value], $nin: [USER_SCOPES.executive.value] },
};

const initQuery = () => ({
    _manager: null,
});

export default {
    components: {
        AutocompleteUser,
    },
    data: () => ({
        query: initQuery(),

        params,
        attrs_input,
    }),
    mounted() {
        this.sync();
    },
    watch: {
        "$route.path"() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.query = {
                _manager: this.$route.query?._manager || null,
            };
        },
        input() {
            let { ...query } = this.query;

            if (!query._manager) delete query._manager;
            delete query.page;

            this.$router.push({ query });
        },
    },
};
</script>

<style>
</style>