<template>
    <v-dialog v-model="shows" max-width="320" persistent>
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on, disabled, title }" />
        </template>
        <v-card>
            <v-card-title>선택 입고/발송일 변경</v-card-title>
            <v-card-text class="pb-0">
                <v-row class="mx-n2">
                    <v-col cols="12" class="pa-2"> <table-targets-at-summary v-bind="{ selected }" /> </v-col>
                    <v-col cols="12" class="pa-2"> <v-text-field v-model="targetsAt" type="date" v-bind="{ ...attrs_input, disabled }" label="변경 입고/발송일" /> </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions class="justify-end">
                <v-subheader class="caption">
                    선택된 항목 총
                    <span class="mx-1 text-subtitle-1 font-weight-bold primary--text">
                        {{ selected.length }}
                    </span>
                    건
                </v-subheader>
                <v-spacer />
                <v-btn text color="primary" @click="shows = false">취소</v-btn>
                <v-btn text color="primary" v-bind="{ disabled, loading }" @click="save">변경</v-btn>
            </v-card-actions>
            <v-fade-transition>
                <v-overlay v-show="loading" absolute light color="white">
                    <v-sheet width="240" rounded style="overflow: hidden">
                        <v-progress-linear :value="(updatedLength / selected.length) * 100" height="24">
                            <span> {{ updatedLength }} / {{ selected.length }} </span>
                        </v-progress-linear>
                    </v-sheet>
                </v-overlay>
            </v-fade-transition>
        </v-card>
    </v-dialog>
</template>

<script>
import { attrs_input} from "@/assets/variables";

import TableTargetsAtSummary from "../dialog-targets-at-update/table-targets-at-summary.vue";

export default {
    components: {
        TableTargetsAtSummary,
    },
    props: {
        title: { type: String, default: "선택 입고/발송일 변경" },
        selected: { type: Array, default: () => [] },
    },
    data: () => ({
        shows: false,
        loading: false,

        updatedLength: 0,
        targetsAt: Date.now().toDate(),

        attrs_input,
    }),
    computed: {
        disabled() {
            return !this.selected.length;
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        shows(shows) {
            if (shows) this.init();
        },
    },
    methods: {
        init() {
            this.updatedLength = 0;
            this.targetsAt = Date.now().toDate();
        },
        close() {
            this.shows = false;
            this.loading = false;
        },
        async save() {
            if (this.loading) return;
            this.loading = true;
            let { targetsAt } = this;

            let items = this.selected.map((item) => ({ ...item, targetsAt }))

            this.$emit("updateItems", items);
            this.close();
        },
    },
};
</script>

<style>
</style>