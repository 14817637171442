<template>
    <list-search v-bind="{ showsSearch, loading }" v-on="{ search }">
        <v-row>
            <v-col col="6" lg="3">
                <v-select v-model="query._category" :items="categories" item-text="fullName" item-value="_id" label="카테고리" placeholder="전체" clearable v-bind="attrs_input" />
            </v-col>
            <v-col col="6" lg="3">
                <v-select v-model="query.searchKey" :items="searchKeys" item-text="text" item-value="value" label="검색대상" placeholder="전체" clearable v-bind="attrs_input" />
            </v-col>
            <v-col col="6" lg="3">
                <v-text-field v-model="query.searchValue" label="검색어" clearable v-bind="attrs_input" @keydown.enter="search" />
            </v-col>
        </v-row>
    </list-search>
</template>

<script>
import { attrs_input } from "@/assets/variables";

import ListSearch from "../../dumb/list-search.vue";

const initQuery = () => ({
    _category: null,
    searchKey: null,
    searchValue: null,
});

export default {
    components: {
        ListSearch,
    },
    props: {
        categories: { type: Array, default: () => [] },
        loading: { type: Boolean, default: false },
        showsSearch: { type: Boolean, default: false },
    },
    data: () => ({
        attrs_input,

        query: initQuery(),
    }),
    computed: {
        searchKeys() {
            const items = [];
            items.push({ text: "상품명", value: "name" });
            return items;
        },
    },
    mounted() {
        
        this.sync();
    },
    watch: {
        "$route.path"() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.query = {
                type: this.$route.query?._category || null,
                searchKey: this.$route.query?.searchKey || null,
                searchValue: this.$route.query?.searchValue || null
            };
        },
        search() {
            let { ...query } = this.query;

            if (!query._category) delete query._category;
            if (!query.searchKey) delete query.searchKey;
            if (!query.searchValue) delete query.searchValue;

            query.page = 1;

            this.$router.push({ query });
        },
    },
};
</script>

<style></style>
