var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.value.receiver ? _c('v-data-table', _vm._b({
    staticClass: "transparent",
    attrs: {
      "dense": "",
      "disable-filtering": "",
      "disable-sort": "",
      "hide-default-footer": ""
    },
    scopedSlots: _vm._u([{
      key: "top",
      fn: function fn() {
        return [_c('v-card-subtitle', {
          staticClass: "font-weight-bold",
          staticStyle: {
            "background-color": "rgba(0, 0, 0, 0.05)"
          }
        }, [_vm._v("받으시는분")]), _vm._v(" "), _c('v-divider')];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function fn() {
        return [_c('v-divider')];
      },
      proxy: true
    }], null, false, 1988007718)
  }, 'v-data-table', {
    headers: _vm.headers,
    items: _vm.items
  }, false)) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }