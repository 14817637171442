<template>
    <v-dialog v-model="shows" max-width="320">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on, disabled }" />
        </template>
        <v-card>
            <v-card-title>선택 발주제한</v-card-title>
            <v-card-text> <v-select v-model="isOrderDisabled" v-bind="{ ...attrs_input, items, disabled }" /> </v-card-text>
            <v-card-actions class="justify-end">
                <v-btn text color="primary" @click="shows = false">취소</v-btn>
                <v-btn text color="primary" v-bind="{ disabled }" @click="save">변경</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { attrs_input } from "@/assets/variables";
import api from "@/api";

const items = [
    { text: "활성화", value: true },
    { text: "비활성화", value: false },
];

export default {
    props: {
        selected: { type: Array, default: () => [] },
    },
    data: () => ({
        shows: false,

        isOrderDisabled: items[0].value,

        items,
        attrs_input,
    }),
    computed: {
        disabled() {
            return !(this.selected || []).length;
        },
    },
    methods: {
        async save() {
            let { isOrderDisabled } = this;
            let form = { isOrderDisabled };

            let users = await Promise.all(this.selected.map(async ({ _id }) => (await api.console.users.put({ _id, ...form }))?.user));
            alert("변경되었습니다");
            this.$emit("updateItems", users);
            this.shows = false;
        },
    },
};
</script>

<style>
</style>