var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "max-width": "320"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var attrs = _ref.attrs,
            on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs: attrs,
          on: on,
          disabled: _vm.disabled
        })];
      }
    }], null, true),
    model: {
      value: _vm.shows,
      callback: function callback($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v("선택 발주제한")]), _c('v-card-text', [_c('v-select', _vm._b({
    model: {
      value: _vm.isOrderDisabled,
      callback: function callback($$v) {
        _vm.isOrderDisabled = $$v;
      },
      expression: "isOrderDisabled"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_input, {
    items: _vm.items,
    disabled: _vm.disabled
  }), false))], 1), _c('v-card-actions', {
    staticClass: "justify-end"
  }, [_c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.shows = false;
      }
    }
  }, [_vm._v("취소")]), _c('v-btn', _vm._b({
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, 'v-btn', {
    disabled: _vm.disabled
  }, false), [_vm._v("변경")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }