<template>
    <v-layout v-if="ready" justify-center>
        <v-responsive max-width="1024px" width="100%" height="100%" class="shop-board-view">
            <board-view-heading v-model="board" />
            <v-card class="mt-6" v-if="board?._id">
                <v-card-title class="subtitle-1">기본정보</v-card-title>
                <v-divider />
                <v-card-text>
                    <v-row v-if="$route.params._board">
                        <v-col cols="6" class="py-0">
                            <v-text-field :value="(board.writer || {}).name" label="작성자" persistent-placeholder hide-details readonly disabled></v-text-field>
                        </v-col>
                        <v-col cols="6" class="py-0">
                            <v-datetime-field :value="board?.createdAt?.toDateTime?.()" label="작성일" persistent-placeholder hide-details @input="(value) => (board.createdAt = value)"></v-datetime-field>
                        </v-col>
                    </v-row>
                    <v-row class="mt-3" v-if="!['community'].includes(board?.code)">
                        <v-col cols="6" class="py-0">
                            <v-text-field v-model="board.subject" label="제목" persistent-placeholder hide-details />
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <template v-if="!['community'].includes(board?.code)">
                <board-view-files v-model="board.files" />
            </template>
            <template v-if="['community'].includes(board?.code)">
                <v-row class="mt-6">
                    <v-col cols="6">
                        <board-view-images v-model="board.files" />
                    </v-col>
                    <v-col cols="6">
                        <board-view-youtube v-model="board.youtube" />
                    </v-col>
                </v-row>
            </template>

            <v-card class="mt-6">
                <v-card-title class="subtitle-1">내용</v-card-title>
                <v-divider />
                <v-card-text>
                    <v-textarea v-if="['community'].includes(board?.code)" v-model="board.content" hide-details outlined />
                    <naver-smarteditor v-else v-model="board.content" id="content" rows="10"></naver-smarteditor>
                </v-card-text>
            </v-card>

            <v-card v-if="['interior-inquire'].includes(board?.code)" class="mt-6">
                <v-card-title class="subtitle-1">답변</v-card-title>
                <v-card-text>
                    <naver-smarteditor v-model="board.reply" id="reply" rows="10"></naver-smarteditor>
                </v-card-text>
            </v-card>

            <v-row justify="center">
                <v-col md="auto" sm="auto">
                    <v-btn color="primary" @click="save">저장</v-btn>
                </v-col>
            </v-row>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";

import VDatetimeField from "@/components/plugins/vuetify/v-datetime-field.vue";
import BoardViewFiles from "@/components/console/boards/board-view-files.vue";
import BoardViewImages from "@/components/console/boards/board-view-images.vue";
import BoardViewYoutube from "@/components/console/boards/board-view-youtube.vue";
import BoardViewHeading from "@/components/console/boards/board-view-heading.vue";
import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";

export default {
    components: {
        VDatetimeField,
        BoardViewFiles,
        BoardViewImages,
        BoardViewYoutube,
        BoardViewHeading,
        NaverSmarteditor,
    },
    data() {
        return {
            ready: false,

            ///////////////////////////////////////////////
            // 게시글 정보
            ///////////////////////////////////////////////
            board: {
                code: this.$route.query.code,
                category: undefined,

                subject: undefined,
                content: undefined,
                files: [],
                createdAt: undefined,

                sequence: 1,

                writer: {
                    name: undefined,
                    phone: undefined,
                    email: undefined,
                },
            },
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            try {
                if (this.$route.params._board) {
                    var { board } = await api.console.boards.get({ _id: this.$route.params._board });
                    this.board = board;

                    if (board.thumb) this.board.thumb = await api.getResource(board.thumb);
                    if (board.files) this.board.files = await Promise.all(this.board.files.map(async ({ url }) => await api.getResource(url)));
                }

                if (!this.$route.params._board === undefined) {
                    var { summary } = await api.console.boards.gets({
                        headers: { skip: 0, limit: 1 },
                        params: {
                            code: this.$route.query.code,
                        },
                    });
                    this.board.sequence = summary.totalCount + 1;
                }

                this.ready = true;
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
            }
        },
        async save() {
            try {
                const { _files, files, thumb, ...boardData } = this.board;
                ////////////////////////////////////////////////////////////////
                // 0. 리소스 리셋
                ////////////////////////////////////////////////////////////////
                if (_files?.length) {
                    for (const _file of _files) {
                        await api.console.boards.files.delete({ _id: _file });
                    }
                }

                ////////////////////////////////////////
                ////////////////////////
                // 1. 게시글 저장
                ////////////////////////////////////////////////////////////////
                var { board } = this.board._id ? await api.console.boards.put(boardData) : await api.console.boards.post(boardData);

                ////////////////////////////////////////////////////////////////
                // 2. 리소스 저장
                ////////////////////////////////////////////////////////////////
                if (thumb) await api.console.boards.postThumb(board, thumb);

                if (files?.length) {
                    for (const file of files) {
                        await api.console.boards.files.post({ _board: board._id }, file);
                    }
                }

                alert(this.board._id ? "수정되었습니다" : "저장되었습니다");

                this.$router.go(-1);
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },

        createObjectURL(file) {
            try {
                return URL.createObjectURL(file);
            } catch (error) {
                return;
            }
        },
    },
};
</script>
