<template>
    <v-chip-group v-if="notification.sites.length">
        <v-chip v-for="type in notification.sites" :key="type" outlined color="primary" disabled>{{ getName(type) }}</v-chip>
    </v-chip-group>
    <div v-else>-</div>
</template>

<script>
export default {
    props: ["notification"],
    methods: {
        getName(type) {
            if (type == "yknowledge") return "Y-Knowledge";
            if (type == "yfactory") return "Y-Factory";
            return type;
        },
    },
};
</script>

<style></style>
