<template>
    <v-layout justify-center>
        <v-responsive max-width="840" width="100%" height="100%" class="px-3 mx-n3">
            <list-heading title="카테고리 목록">
                <template #add-button>
                    <category-input @input="updateItem">
                        <template #activator="{ attrs, on }">
                            <v-btn small fab tile color="transparent primary--text" class="my-n1" v-bind="attrs" v-on="on"> <v-icon>mdi-plus</v-icon> </v-btn>
                        </template>
                    </category-input>
                </template>
            </list-heading>

            <v-data-table v-bind="{ loading, headers, items }" disable-sort disable-pagination hide-default-footer class="v-sheet--outlined">
                <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>
                <template #[`item.actions`]="{ item }">
                    <category-input v-bind="item" @input="updateItem">
                        <template #activator="{ attrs, on }">
                            <v-icon small v-bind="attrs" v-on="on"> mdi-pencil </v-icon>
                        </template>
                    </category-input>
                    <v-icon small @click="remove(item)" class="ml-2"> mdi-delete </v-icon>
                </template>
            </v-data-table>
            <v-pagination :value="page" :length="pageCount" :total-visible="11" color="primary" class="mt-2" @input="(page) => $router.push({ query: { ...$route.query, page } })"></v-pagination>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";

import ListHeading from "@/components/console/dumb/list-heading.vue";
import CategoryInput from "@/components/console/shop/category-input.vue";

const defualtFormatter = (value) => value ?? "-";

const headers = [
    { width: +80, text: "#", value: "index", divider: true },
    { width: +80, text: "분류", value: "depth", formatter: (value) => ["대분류", "중분류", "소분류"][value], divider: true },
    { width: 100, text: "상위", value: "parent.name", divider: true },
    { width: 140, text: "카테고리명", value: "name", divider: true },
    { width: 160, text: "하위", value: "children", formatter: (value) => (value || []).map(({ name }) => name).join(", ") || "-", divider: true, cellClass: "max-width-0 text-truncate" },
    { width: 170, text: "생성일자", value: "createdAt", formatter: (value) => value?.toDateTime?.(), divider: true },
    { width: +80, text: "", value: "actions", align: "center" },
].map((item) => ({ ...item, formatter: item.formatter ?? defualtFormatter }));

export default {
    components: {
        ListHeading,
        CategoryInput,
    },
    data() {
        return {
            categories: [],

            limit: 10,
            summary: { totalCount: 0 },

            headers,
            loading: false,
        };
    },
    computed: {
        items() {
            return this.categories.map((item, index) => ({ index: this.summary.totalCount - (this.page - 1) * this.limit - index, ...item }));
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;
            delete query.page;
            return { ...query };
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.search();
        },
    },
    methods: {
        async init() {
            this.search();
        },
        async search() {
            try {
                this.loading = true;
                let { skip, limit, params } = this;
                var { summary, categories } = await api.console.shop.categories.gets({
                    headers: { skip, limit },
                    params,
                });
                this.categories = categories;
                this.summary = summary;
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },

        async update(category) {
            try {
                category = (await api.console.shop.categories.put(category))?.category;
                this.updateItem(category);
                alert("성공적으로 처리되었습니다.");
            } catch (error) {
                this.$handleError(error);
            }
        },

        async remove(category) {
            const go = confirm(`"${category?.name}" 카테고리를 삭제하시겠습니까?`);
            if (go) {
                try {
                    [{ category }] = [await api.console.shop.categories.delete(category)];
                    this.categories = this.categories.filter(({ _id }) => _id !== category._id);
                    alert(`"${category?.name})" 카테고리가 삭제되었습니다.`);
                    this.summary.totalCount -= 1;
                } catch (error) {
                    this.$handleError(error);
                }
            }
        },

        updateItem(category) {
            const index = this.categories.findIndex(({ _id }) => _id == category._id);
            if (0 <= index) this.categories.splice(index, 1, category);
            else {
                this.categories = [category, ...this.categories];
                this.summary.totalCount += 1;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .max-width-0 {
        max-width: 0;
    }
    .white-space-pre-line {
        white-space: pre-line;
    }
    .v-pagination button {
        box-shadow: none !important;
        border: thin solid rgba(0, 0, 0, 0.12);
    }
}
</style>