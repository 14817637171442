var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "mt-6"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("첨부파일")]), _c('v-divider'), _c('v-card-text', [_c('v-row', {
    staticClass: "row--small",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "md": "11"
    }
  }, [_c('v-file-input', {
    attrs: {
      "dense": "",
      "hide-details": "",
      "outlined": ""
    },
    model: {
      value: _vm.temp,
      callback: function callback($$v) {
        _vm.temp = $$v;
      },
      expression: "temp"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "md": "1"
    }
  }, [_c('v-btn', {
    attrs: {
      "block": "",
      "outlined": "",
      "color": "primary"
    },
    on: {
      "click": _vm.addFile
    }
  }, [_vm._v("첨부")])], 1)], 1), _c('v-chip-group', {
    attrs: {
      "value": _vm.value,
      "multiple": ""
    }
  }, _vm._l(_vm.value, function (file, i) {
    return _c('v-chip', {
      key: i,
      attrs: {
        "value": file
      },
      on: {
        "click": function click($event) {
          return _vm.removeFile(file);
        }
      }
    }, [_c('span', [_vm._v(_vm._s(file.name))]), _c('v-icon', {
      attrs: {
        "small": ""
      }
    }, [_vm._v("mdi-close")])], 1);
  }), 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }