var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-data-table', _vm._b({
    attrs: {
      "fixed-header": "",
      "item-key": "tempId",
      "items-per-page": 15,
      "height": "calc(100% - 59px)"
    },
    scopedSlots: _vm._u([{
      key: "no-data",
      fn: function fn() {
        return [_c('v-overlay', {
          staticClass: "black--text",
          attrs: {
            "absolute": "",
            "light": "",
            "color": "white"
          }
        }, [_c('v-card-title', [_vm._v(" 데이터가 없거나 양식이 잘못되었습니다. ")])], 1)];
      },
      proxy: true
    }, _vm._l(_vm.headers, function (header) {
      return {
        key: "item.".concat(header.value),
        fn: function fn(_ref) {
          var value = _ref.value;
          return [value ? _c('v-tooltip', {
            key: header.value,
            attrs: {
              "bottom": ""
            },
            scopedSlots: _vm._u([{
              key: "activator",
              fn: function fn(_ref2) {
                var attrs = _ref2.attrs,
                    on = _ref2.on;
                return [_c('span', _vm._g(_vm._b({}, 'span', attrs, false), on), [_vm._v(_vm._s(value))])];
              }
            }], null, true)
          }, [_c('span', [_vm._v(_vm._s(value))])]) : _c('span', {
            key: header.value
          }, [_vm._v(" - ")])];
        }
      };
    }), {
      key: "foot",
      fn: function fn() {
        return [_c('tfoot', [_c('td', {
          attrs: {
            "colspan": _vm.headers.length + 1
          }
        }, [_c('v-divider')], 1)])];
      },
      proxy: true
    }, {
      key: "item.orderer",
      fn: function fn(_ref3) {
        var _value$group, _value$group2;

        var value = _ref3.value;
        return [value ? _c('div', [value !== null && value !== void 0 && (_value$group = value.group) !== null && _value$group !== void 0 && _value$group.name ? _c('div', {
          staticClass: "caption"
        }, [_vm._v(_vm._s((value === null || value === void 0 ? void 0 : (_value$group2 = value.group) === null || _value$group2 === void 0 ? void 0 : _value$group2.name) || ""))]) : _vm._e(), _vm._v(" " + _vm._s((value === null || value === void 0 ? void 0 : value.name) || "") + " ")]) : _c('div', [_vm._v("-")])];
      }
    }, {
      key: "footer.prepend",
      fn: function fn() {
        return [_vm.excel != 'invoice' ? _c('div', {
          staticStyle: {
            "margin": "0 auto 0 0"
          }
        }, [_c('dialog-excel-upload-orderer-input', _vm._g(_vm._b({
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref4) {
              var attrs = _ref4.attrs,
                  on = _ref4.on,
                  disabled = _ref4.disabled,
                  title = _ref4.title;
              return [_c('v-fade-transition', [_c('v-btn', _vm._g(_vm._b({
                directives: [{
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isBulk,
                  expression: "isBulk"
                }],
                attrs: {
                  "text": "",
                  "tile": "",
                  "color": "primary"
                }
              }, 'v-btn', Object.assign({}, attrs, {
                disabled: disabled,
                loading: _vm.loading
              }), false), on), [_vm._v(_vm._s(title))])], 1)];
            }
          }], null, false, 943275982)
        }, 'dialog-excel-upload-orderer-input', {
          selected: _vm.selected
        }, false), {
          updateItems: _vm.updateItems
        })), _c('dialog-excel-upload-orderer-input', _vm._g({
          attrs: {
            "title": "전체 주문자 변경",
            "selected": _vm.value
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref5) {
              var attrs = _ref5.attrs,
                  on = _ref5.on,
                  disabled = _ref5.disabled,
                  title = _ref5.title;
              return [_c('v-fade-transition', [_c('v-btn', _vm._g(_vm._b({
                directives: [{
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isBulk,
                  expression: "isBulk"
                }],
                attrs: {
                  "text": "",
                  "tile": "",
                  "color": "primary"
                }
              }, 'v-btn', Object.assign({}, attrs, {
                disabled: disabled,
                loading: _vm.loading
              }), false), on), [_vm._v(_vm._s(title))])], 1)];
            }
          }], null, false, 943275982)
        }, {
          updateItems: _vm.updateItems
        })), _c('dialog-excel-upload-targets-input', _vm._g(_vm._b({
          attrs: {
            "title": "선택 입고/발송일 변경"
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref6) {
              var attrs = _ref6.attrs,
                  on = _ref6.on,
                  disabled = _ref6.disabled,
                  title = _ref6.title;
              return [_c('v-fade-transition', [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "text": "",
                  "tile": "",
                  "color": "primary"
                }
              }, 'v-btn', Object.assign({}, attrs, {
                disabled: disabled,
                loading: _vm.loading
              }), false), on), [_vm._v(_vm._s(title) + " ")])], 1)];
            }
          }], null, false, 1772481564)
        }, 'dialog-excel-upload-targets-input', {
          selected: _vm.selected
        }, false), {
          updateItems: _vm.updateItems
        }))], 1) : _vm._e()];
      },
      proxy: true
    }], null, true),
    model: {
      value: _vm.selected,
      callback: function callback($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  }, 'v-data-table', {
    items: _vm.items,
    headers: _vm.headers,
    loading: _vm.loading,
    showSelect: _vm.showSelect
  }, false));
}
var staticRenderFns = []

export { render, staticRenderFns }