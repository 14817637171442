<template></template>

<script>
import XLSX from "xlsx";
import dayjs from 'dayjs';

const headers = [
    { text: "보내는사람(지정)", value: "sender.name" },
    { text: "전화번호1(지정)", value: "sender.phone" },
    { text: "주소(지정)", value: "sender.address" },
    { text: "받는사람", value: "receiver.name" },
    { text: "전화번호1", value: "receiver.phone" },
    { text: "우편번호", value: "receiver.postcode" },
    { text: "수량(A타입)", value: "products[0].quantity" },
    { text: "상품명1", value: "products[0].code" },
    { text: "배송메시지", value: "message" },
];

const nameData = (row) => ({
    ["조합원번호"]: row?.A || "",
    ["조합원명"]: row?.B || "",
    ["출고예정일"]: row?.C || "",
    ["출고순번"]: row?.D || "",
    ["보내는사람_보내는분"]: row?.E || "",
    ["보내는사람_우편1"]: row?.F || "",
    ["보내는사람_우편2"]: row?.G || "",
    ["보내는사람_주소1"]: row?.H || "",
    ["보내는사람_주소2"]: row?.I || "",
    ["보내는사람_전화"]: row?.J || "",
    ["보내는사람_핸드폰"]: row?.K || "",
    ["받는사람_받는분"]: row?.L || "",
    ["받는사람_우편1"]: row?.M || "",
    ["받는사람_우편2"]: row?.N || "",
    ["받는사람_주소1"]: row?.O || "",
    ["받는사람_주소2"]: row?.P || "",
    ["받는사람_전화"]: row?.Q || "",
    ["받는사람_핸드폰"]: row?.R || "",
    ["배송메시지"]: row?.S || "",
    ["Seq"]: row?.T || "",
    ["생활재번호"]: row?.U || "",
    ["생활재명"]: row?.V || "",
    ["판매금액"]: row?.W || "",
    ["주문수량"]: row?.X || "",
    ["집품수량"]: row?.Y || "",
    ["송장번호"]: row?.Z || "",
    ["출고수량"]: row?.AA || "",
    ["택배사코드"]: row?.AB || "",
    ["택배사명"]: row?.AC || "",

    rawData: row,
});

const structureData = ({ rawData, ...data }) => {
    return async ({ getProductByCodeAlt }) => ({
        sender: {
            name: data["보내는사람_보내는분"],
            phone: data["보내는사람_전화"],
            address: "경기도 안성시 대덕면 모산로 394",
        },

        receiver: {
            name: data["받는사람_받는분"],
            phone: data["받는사람_전화"],
            postcode: null,
            address1: data["받는사람_주소1"],
            address2: data["받는사람_주소2"],
        },

        products: [
            {
                name: data["생활재명"],
                codeAlt: data["생활재번호"],
                quantity: data["주문수량"],
                ...((await getProductByCodeAlt(data["생활재번호"])) || {}),
            },
        ],
        
        targetsAt: null,
        
        rawData,
    });
};

export default {
    props: {
        file: { type: File, default: null },
        value: { type: Array, default: () => [] }, // items
        getProductByCodeAlt: { type: Function, default: () => () => {} },
    },
    data: () => ({
        items: [],
    }),
    mounted() {
        this.processFile();
    },
    watch: {
        file() {
            this.processFile();
        },
    },
    methods: {
        processFile() {
            if (this.file) {
                try {
                    this.$emit("loading", true);

                    let reader = new FileReader();
                    reader.onload = async () => {
                        let workBook = XLSX.read(reader.result, { type: "binary" });
                        let workSheets = workBook.SheetNames.map((sheetName) => XLSX.utils.sheet_to_json(workBook.Sheets[sheetName], { range: 2, header: "A" }));
                        this.items = await Promise.all(workSheets[0].map(nameData).map(structureData).map(this.makeData));

                        this.$emit("loading", false);
                        this.$emit("input", this.items);
                    };
                    reader.readAsBinaryString(this.file);
                } catch (error) {}
            }
        },
        async makeData(callback) {
            let { getProductByCodeAlt } = this;
            return callback({ getProductByCodeAlt });
        },
    },
};
</script>

<style>
</style>