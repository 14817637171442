<template>
    <div><slot name="activator" v-bind="{ click, loading }" /></div>
</template>

<script>
import api from "@/api";
import XLSX from "xlsx";
import dayjs from "dayjs";
import { makeShippingMessage } from "@/assets/variables";

export default {
    props: {
        title: { type: String, default: null },
        excel: { type: String, default: null },
        params: { type: Object, default: null },
        selected: { type: Array, default: null },
    },
    data: () => ({
        loading: false,
    }),
    methods: {
        async click() {
            if (this.selected?.length == 0) {
                alert("항목을 선택하세요");
                return;
            }
            this.loading = true;
            try {
                let orders = this.selected || (await api.console.shop.purchaseOrders.gets({ params: this.params }))?.orders;
                let workbook = new XLSX.utils.book_new();
                if (["upload", "invoice"].includes(this.excel)) {
                    let rows = orders.flatMap(this.makeInvoiceItem);
                    let worksheet = XLSX.utils.json_to_sheet(rows);
                    XLSX.utils.book_append_sheet(workbook, worksheet, "송장목록");
                }
                let rows = orders.flatMap(this.makeDefaultItem);
                let worksheet = XLSX.utils.json_to_sheet(rows);
                XLSX.utils.book_append_sheet(workbook, worksheet, "일반목록");
                XLSX.writeFile(workbook, `${this.title.replace(/\s/g, "_")}_목록-${dayjs().format("YYMMDDHHmmss")}.xlsx`);
            } catch (error) {
                alert(error?.message || "오류가 발생했습니다");
            } finally {
                this.loading = false;
            }
        },
        makeInvoiceItem(order) {
            return (order?.products || []).map((product) => ({
                "보내는사람(지정)": order?.sender?.name || "",
                "전화번호1(지정)": order?.sender?.phone || "",
                "주소(지정)": order?.sender?.address || "",
                받는사람: order?.receiver?.name || "",
                전화번호1: order?.receiver?.phone || "",
                우편번호: order?.receiver?.postcode || "",
                주소: `${order?.receiver?.address1 || ""} ${order?.receiver?.address2 || ""}`,
                "수량(A타입)": product?.quantity || "",
                상품명1: product?.code || "",
                // "상품명(표기)": product?.name || "",
                배송메시지: makeShippingMessage(order),
                주문상태: order.stateText,
                발주번호: order?.number || "",
            }));
        },
        makeDefaultItem(order) {
            return {
                발주번호: order?.number || "-",
                "입고/발송일": order?.targetsAt || "-",
                발주유형: order?.typeTextShorten || "-",
                주문자그룹: order?.ordererGroup?.name || "-",
                주문자: order?.orderer?.usernmae || "-",
                주문상태: order?.stateText || "-",
                발주일시: order?.createdAt?.toDateTime?.() || "-",

                // // 보내시는 분
                // ...(() => (order?.sender ? {} : {}))(),
                // // 영수증
                // ...(() => (order?.receipt ? {} : {}))(),
                // // 인사장
                // ...(() => (order?.receipt ? {} : {}))(),
                // // 메시지
                // ...(() => (order?.receipt ? {} : {}))(),
                // // 받으시는 분
                // ...(() => (order?.receiver ? {} : {}))(),

                // 상품
                ...(order?.products || [])?.reduce(
                    (fields, item, index) => ({
                        ...fields,
                        [`상품명${index + 1}`]: item?.name || "-",
                        [`규격${index + 1}`]: item?.size || "-",
                        // [`암호코드${index + 1}`]: item?.code || "-",
                        [`상품코드${index + 1}`]: item?.codeAlt || "-",
                        [`수량${index + 1}`]: item?.quantity || "-",
                    }),
                    {}
                ),
            };
        },
    },
};
</script>

<style>
</style>