var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-edit-dialog', _vm._g({
    attrs: {
      "return-value": _vm.item.sender,
      "large": "",
      "cancel-text": "취소",
      "save-text": "변경"
    },
    on: {
      "update:returnValue": function updateReturnValue($event) {
        return _vm.$set(_vm.item, "sender", $event);
      },
      "update:return-value": function updateReturnValue($event) {
        return _vm.$set(_vm.item, "sender", $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "input",
      fn: function fn() {
        return [_c('v-text-field', _vm._b({
          staticClass: "mt-3",
          attrs: {
            "label": "보내시는분"
          },
          on: {
            "keydown": function keydown($event) {
              if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
              return _vm.save.apply(null, arguments);
            }
          },
          model: {
            value: _vm.item.sender.name,
            callback: function callback($$v) {
              _vm.$set(_vm.item.sender, "name", $$v);
            },
            expression: "item.sender.name"
          }
        }, 'v-text-field', _vm.attrs_input, false)), _c('v-text-field', _vm._b({
          staticClass: "mt-3",
          attrs: {
            "label": "연락처"
          },
          on: {
            "keydown": function keydown($event) {
              if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
              return _vm.save.apply(null, arguments);
            }
          },
          model: {
            value: _vm.item.sender.phone,
            callback: function callback($$v) {
              _vm.$set(_vm.item.sender, "phone", $$v);
            },
            expression: "item.sender.phone"
          }
        }, 'v-text-field', _vm.attrs_input, false))];
      },
      proxy: true
    }])
  }, {
    save: _vm.save
  }), [_vm._t("default")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }