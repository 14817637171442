<template>
    <v-dialog v-model="shows" max-width="880" persistent>
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card>
            <v-card-title> 클레임 생성 <v-spacer /> <v-icon @click="shows = false">mdi-close</v-icon> </v-card-title>
            <v-divider />
            <v-row class="ma-0 pa-2">
                <v-col cols="6" md="" class="pa-2"> <autocomplete-user-group v-model="form._orderGroup" label="발주자그룹" v-bind="attrs_input" /> </v-col>
                <v-col cols="6" md="" class="pa-2"> <autocomplete-user v-model="form._orderer" :params="{ _group: form._ordererGroup }" :disabled="!form._orderGroup" label="발주자" v-bind="attrs_input" /> </v-col>
                <v-col cols="12" md="" class="pa-2"> <autocomplete-purchase-order v-model="form._order" :params="{ _orderer: form._orderer }" :disabled="!form._orderer" label="구매주문" v-bind="attrs_input" ref="autocomplete-purchase-order" /> </v-col>
            </v-row>
            <v-card flat :disabled="!order">
                <v-divider />
                <table-info :value="order">
                    <template #title>
                        기본 정보 <v-spacer />
                        <purchase-order-view :item="order">
                            <template #activator="{ attrs, on }">
                                <v-icon v-bind="attrs" v-on="on" color="secondary" class="my-n1">mdi-file-document-outline</v-icon>
                            </template>
                        </purchase-order-view>
                    </template>
                </table-info>
                <table-products :value="order" :selected="[form._product]" showsSelect @select="selectProduct">
                    <template #title> 상품 선택 </template>
                </table-products>
                <v-divider />
                <v-row class="ma-0 pa-2">
                    <v-col cols="12" md="" class="pa-2"> <v-text-field v-model="form.query" label="클레임내용" :disabled="!form._product" v-bind="attrs_input" /> </v-col>
                    <v-col cols="12" md="" class="pa-2"> <v-text-field v-model="form.reply" label="클레임처리" :disabled="!form._product" v-bind="attrs_input" /> </v-col>
                    <v-col cols="12" md="" class="pa-2"> <v-text-field v-model="form.handlerName" label="처리자" :disabled="!form._product" v-bind="attrs_input" /> </v-col>
                </v-row>
            </v-card>
            <v-divider />
            <v-card-actions>
                <v-spacer />
                <v-btn text color="primary" v-bind="{ loading }" @click="save">저장</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { attrs_input } from "@/assets/variables";

import TableInfo from "../purchase-orders/table-info.vue";
import TableProducts from "../purchase-orders/table-products.vue";
import AutocompleteUser from "../../dumb/autocomplete-user.vue";
import AutocompleteUserGroup from "../../dumb/autocomplete-user-group.vue";
import AutocompletePurchaseOrder from "../../dumb/autocomplete-purchase-order.vue";
import PurchaseOrderView from "../purchase-orders/purchase-order-view.vue";
import api from "@/api";

const initForm = () => ({
    _order: undefined,
    _product: undefined,
    _orderer: undefined,
    _ordererGroup: undefined,
    query: undefined,
    reply: undefined,
    repliedAt: undefined,
    handlerName: undefined,
});

export default {
    components: {
        TableInfo,
        TableProducts,
        AutocompleteUser,
        AutocompleteUserGroup,
        AutocompletePurchaseOrder,
        PurchaseOrderView,
    },
    data: () => ({
        shows: false,
        loading: false,
        attrs_input,

        form: initForm(),
        order: undefined,
    }),
    watch: {
        "form._order"() {
            this.order = JSON.parse(JSON.stringify(this.$refs["autocomplete-purchase-order"]?.item || "undefined"));
        },
    },
    methods: {
        selectProduct(_id) {
            if (this.form._product == _id) this.form._product = null;
            else this.form._product = _id;
        },
        async save() {
            this.loading = true;
            try {
                let { type: orderType, number: orderNumber } = this.order;
                if (this.form.reply.trim().length > 0) this.form.repliedAt = Date.now();
                let { claim } = await api.console.shop.purchaseOrderClaims.post({
                    ...this.form,
                    orderType,
                    orderNumber,
                });
                this.$emit("input", claim);
                alert("저장되었습니다");
                this.shows = false;
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style>
</style>