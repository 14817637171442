var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "width": "320",
      "persistent": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var attrs = _ref.attrs,
            on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs: attrs,
          on: on
        })];
      }
    }], null, true),
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', {
    attrs: {
      "light": ""
    }
  }, [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.title) + " "), _c('v-spacer'), _vm._v(" "), _c('v-icon', {
    on: {
      "click": function click($event) {
        _vm.dialog = false;
      }
    }
  }, [_vm._v("mdi-close")])], 1), _c('v-card-text', {
    staticClass: "py-0"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "그룹명"
    },
    model: {
      value: _vm.inputs.name,
      callback: function callback($$v) {
        _vm.$set(_vm.inputs, "name", $$v);
      },
      expression: "inputs.name"
    }
  }, 'v-text-field', _vm.attrs_input, false))], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-autocomplete', _vm._b({
    attrs: {
      "label": "권한",
      "items": _vm.siteFeatures,
      "multiple": ""
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('v-chip', {
          staticClass: "ma-1",
          attrs: {
            "outlined": "",
            "label": "",
            "close": ""
          },
          on: {
            "click:close": function clickClose($event) {
              _vm.inputs.scope = _vm.inputs.scope.filter(function (scope) {
                return scope != item.value;
              });
            }
          }
        }, [_vm._v(_vm._s(item.text))])];
      }
    }]),
    model: {
      value: _vm.inputs.scope,
      callback: function callback($$v) {
        _vm.$set(_vm.inputs, "scope", $$v);
      },
      expression: "inputs.scope"
    }
  }, 'v-autocomplete', _vm.attrs_input, false))], 1)], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }