<template>
    <v-edit-dialog :return-value.sync="item.targetsAt" large cancel-text="취소" save-text="변경" v-on="{ save }">
        <slot />
        <template #input> <v-text-field v-model="item.targetsAt" type="date" v-bind="attrs_input" class="mt-3" @keydown.enter="save" /> </template>
    </v-edit-dialog>
</template>

<script>
import { attrs_input, PURCHASE_ORDER_UPDATE_COLUMN } from "@/assets/variables";

export default {
    props: {
        value: { type: Object, default: () => ({}) },
    },
    data: () => ({
        item: {},
        attrs_input,
    }),
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            let item = JSON.parse(JSON.stringify(this.value));
            item.targetsAt = item?.targetsAt?.toDate?.() || null;
            this.item = item;
        },
        save() {
            let { _id, targetsAt } = this.item;
            let form = { _id, targetsAt, updatedColumn: PURCHASE_ORDER_UPDATE_COLUMN.TARGETSAT.value };
            const message_change = `발주번호: ${this.item?.number}\n변경사항: ${this.value?.targetsAt?.toDate?.()} => ${targetsAt?.toDate?.()}`;
            const message_success = `입고/발송일 변경이 처리되었습니다.\n\n${message_change}`;
            const message_failure = `입고/발송일 변경에 실패하였습니다.\n\n${message_change}`;
            this.$emit("update", form, message_success, message_failure);
        },
    },
};
</script>

<style>
</style>