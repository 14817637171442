<template>
    <v-dialog v-model="shows" width="1680" persistent>
        <template v-slot:activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card class="d-flex flex-column align-stretch" height="90vh">
            <v-card-title> {{ text_title }} <v-spacer /> <v-icon @click="shows = false">mdi-close</v-icon> </v-card-title>
            <v-divider />
            <dialog-excel-upload-header v-model="form" v-bind="{ excel }" class="px-4" style="flex: 0 0 auto" />
            <v-divider />
            <dialog-excel-upload-table v-model="orders" v-bind="{ type, excel, loading }" class="d-flex flex-column fill-height" style="position: relative; flex: 0 0 calc(90vh - 58px - 1px - 56px - 1px - 1px - 52px); overflow: hidden" />
            <v-divider />
            <v-card-actions style="flex: 0 0 auto">
                <v-spacer />
                <v-btn text tile color="primary" v-bind="{ disabled, loading }" @click="save">{{ text_action }}</v-btn>
            </v-card-actions>
            <dialog-excel-upload-logic v-model="orders" v-bind="{ type, file }" @loading="(value) => (loading = value)" style="flex: 0 0 auto" />
            <v-fade-transition>
                <v-overlay v-show="loading" absolute light color="white">
                    <v-sheet width="320" rounded style="overflow: hidden">
                        <v-progress-linear :value="(updatedLength / orders.length) * 100" height="32">
                            <span> {{ updatedLength }} / {{ orders_computed.length }} </span>
                        </v-progress-linear>
                    </v-sheet>
                </v-overlay>
            </v-fade-transition>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import dayjs from "dayjs";
import { PURCHASE_ORDER_STATES, PURCHASE_ORDER_TYPES } from "@/assets/variables";

import DialogExcelUploadTable from "./dialog-excel-upload-table.vue";
import DialogExcelUploadHeader from "./dialog-excel-upload-header.vue";
import DialogExcelUploadLogic from "./dialog-excel-upload-logic.vue";

const initForm = (form = {}) => ({
    type: form.type ?? null,
    file: form.file ?? null,
});

const TABS = {
    UPLOAD_FILE: "UPLOAD_FILE",
    SELECT_USER: "SELECT_USER",
    CONFIRM_DATA: "CONFIRM_DATA",
};

const initialTab = TABS.UPLOAD_FILE;

export default {
    components: {
        DialogExcelUploadTable,
        DialogExcelUploadHeader,
        DialogExcelUploadLogic,
    },
    props: {
        excel: { type: String, default: "upload" },
    },
    data: () => ({
        TABS,
        tab: initialTab,
        shows: false,
        loading: false,
        updatedLength: 0,

        form: initForm(),
        orders: [],
        purchaseOrderNumber: null,
    }),
    computed: {
        text_title() {
            switch (this.excel) {
                case "invoice": {
                    return "엑셀 업데이트";
                }
                default: {
                    return "엑셀 발주";
                }
            }
        },
        text_action() {
            switch (this.excel) {
                case "invoice": {
                    return "업데이트하기";
                }
                default: {
                    return "발주하기";
                }
            }
        },
        type() {
            return this.form.type;
        },
        file() {
            return this.form.file;
        },
        isManualBulk() {
            return this.type == PURCHASE_ORDER_TYPES.ADMIN_MANUAL_ORDER_BULK.value;
        },
        disabled() {
            let hasNoItemToUpload = this.orders.length < 1;
            if (!this.isManualBulk) {
                return hasNoItemToUpload;
            }
            if (this.excel == "invoice") {
                const stateTextMatches = !this.orders.some((item) => ![...Object.values(PURCHASE_ORDER_STATES).map(({ text }) => text)].includes(item.stateText));

                return hasNoItemToUpload || !stateTextMatches;
            }

            let hasItemWithoutOrderer = this.orders.some(({ _orderer }) => !_orderer);
            return hasNoItemToUpload || hasItemWithoutOrderer;
        },
        orders_computed() {
            return this.orders.reduce((items, order) => {
                order = { ...order };
                if (!order.number) {
                    delete order.number;
                    items.push(order);
                } else {
                    let item = items.find((item) => item.number == order.number);
                    if (!item) {
                        item = { ...order, products: [] };
                        if (!item.targetsAt) delete item.targetsAt;
                        if (!item._orderer) delete item._orderer;
                        if (!item._ordererGroup) delete item._ordererGroup;
                        items.push(item);
                    }
                    item.products.push(...order.products);
                }
                return items;
            }, []);
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        shows() {
            this.init();
        },
        excel() {
            this.init();
        },
        form() {
            this.orders = [];
        },
    },
    methods: {
        async init() {
            await this.setPurchaseOrderNumber();
            if (!this.shows) {
                setTimeout(() => {
                    this.tab = initialTab;
                    this.updatedLength = 0;
                    this.form = initForm({
                        type: this.excel == "invoice" ? PURCHASE_ORDER_TYPES.ADMIN_MANUAL_ORDER_BULK.value : null,
                    });
                    this.orders = [];
                }, 500);
            }
        },

        async save() {
            if (this.loading) return;
            this.loading = true;

            try {
                const _manager = this.$store.state.payload?._user;
                let { type } = this.form;
                let { purchaseOrderNumber } = this;
                let { post, patch } = api.console.shop.purchaseOrders;
                for (const item of this.orders_computed) {
                    const isCreate = !item.number;

                    if (isCreate) {
                        if (this.excel != "invoice") {
                            await post({ _manager, ...item, purchaseOrderNumber, type });
                        }
                    } else {
                        await patch(item);
                    }

                    this.updatedLength += 1;
                }

                setTimeout(() => {
                    alert("업로드 되었습니다.");
                    this.loading = false;
                    this.shows = false;
                    this.$emit("input");
                }, 500);
            } catch (error) {
                console.error(error);
                alert(error?.message || "오류가 발생했습니다.");
            } finally {
                setTimeout(() => {
                    if (this.loading) this.loading = false;
                }, 500);
            }
        },

        async setPurchaseOrderNumber() {
            let { setting } = await api.v1.setting.get();
            let year = dayjs().format("YY");
            let digits = String((setting?.purchaseOrderNumberCount[year] || 0) + 1).padStart(5, "0");
            this.purchaseOrderNumber = `${year}_${digits}`;
        },
    },
};
</script>

<style></style>
