var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-autocomplete', _vm._b({
    on: {
      "click": _vm.alert
    },
    model: {
      value: _vm.$data._category,
      callback: function callback($$v) {
        _vm.$set(_vm.$data, "_category", $$v);
      },
      expression: "$data._category"
    }
  }, 'v-autocomplete', Object.assign({}, _vm.$attrs, {
    items: _vm.items,
    itemText: _vm.itemText,
    itemValue: '_id'
  }), false));
}
var staticRenderFns = []

export { render, staticRenderFns }