var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "width": "320",
      "persistent": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var attrs = _ref.attrs,
            on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs: attrs,
          on: on
        })];
      }
    }], null, true),
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', {
    attrs: {
      "light": ""
    }
  }, [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.title) + " "), _c('v-spacer'), _vm._v(" "), _c('v-icon', {
    on: {
      "click": function click($event) {
        _vm.dialog = false;
      }
    }
  }, [_vm._v("mdi-close")])], 1), _c('v-card-text', {
    staticClass: "py-0"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('autocomplete-user-group', _vm._b({
    attrs: {
      "items": _vm.userTypes,
      "label": "사용자 그룹"
    },
    model: {
      value: _vm.inputs._group,
      callback: function callback($$v) {
        _vm.$set(_vm.inputs, "_group", $$v);
      },
      expression: "inputs._group"
    }
  }, 'autocomplete-user-group', _vm.attrs_input, false))], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "지점명"
    },
    model: {
      value: _vm.inputs.name,
      callback: function callback($$v) {
        _vm.$set(_vm.inputs, "name", $$v);
      },
      expression: "inputs.name"
    }
  }, 'v-text-field', _vm.attrs_input, false))], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "disabled": !_vm.isCreate,
      "label": "아이디"
    },
    model: {
      value: _vm.inputs.username,
      callback: function callback($$v) {
        _vm.$set(_vm.inputs, "username", $$v);
      },
      expression: "inputs.username"
    }
  }, 'v-text-field', _vm.attrs_input, false))], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "비밀번호"
    },
    model: {
      value: _vm.inputs.password,
      callback: function callback($$v) {
        _vm.$set(_vm.inputs, "password", $$v);
      },
      expression: "inputs.password"
    }
  }, 'v-text-field', _vm.attrs_input, false))], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-switch', _vm._b({
    staticClass: "d-inline-block",
    attrs: {
      "label": "발주제한"
    },
    model: {
      value: _vm.inputs.isOrderDisabled,
      callback: function callback($$v) {
        _vm.$set(_vm.inputs, "isOrderDisabled", $$v);
      },
      expression: "inputs.isOrderDisabled"
    }
  }, 'v-switch', _vm.attrs_switch, false))], 1)], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }