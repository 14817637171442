var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "headline text-start",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v(_vm._s(_vm.title) + " "), _vm._t("add-button")], 2), _c('v-spacer'), _vm.showsFilterButton ? _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('v-row', {
    staticClass: "my-n3",
    attrs: {
      "align": "center"
    }
  }, [_vm.$slots['buttons'] ? _c('v-col', [_vm._t("buttons")], 2) : _vm._e(), _c('v-spacer', {
    staticClass: "d-block d-md-none"
  }), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "my-n1",
    attrs: {
      "color": "secondary",
      "rounded": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('input', !_vm.value);
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-filter-variant")]), _vm._v("검색필터")], 1)], 1)], 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }