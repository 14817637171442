<template>
    <v-dialog v-model="dialog" width="320" persistent>
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card light>
            <v-card-title> {{ title }} <v-spacer /> <v-icon @click="dialog = false">mdi-close</v-icon> </v-card-title>
            <v-card-text class="py-0">
                <v-row>
                    <v-col cols="12"> <v-select v-model="inputs.depth" label="분류" :items="depthItems" v-bind="attrs_input" @change="setForeignKeys" /> </v-col>
                    <v-expand-transition>
                        <v-col v-show="inputs.depth == 1" cols="12"> <autocomplete-shop-category v-model="inputs._parent" label="상위 카테고리" :depth="0" placeholder="없음" :test="(item) => item._id != _id" v-bind="attrs_input" ref="parent-autocomplete" /> </v-col>
                    </v-expand-transition>
                    <v-col cols="12"> <v-text-field v-model="inputs.name" label="카테고리명" :test="(item) => item._id != _id" v-bind="attrs_input" /> </v-col>
                    <v-expand-transition>
                        <v-col v-show="inputs.depth == 0" cols="12"> <autocomplete-shop-category v-model="inputs._children" label="하위 카테고리" placeholder="없음" :depth="1" multiple v-bind="attrs_input" ref="children-autocomplete" /> </v-col>
                    </v-expand-transition>
                    <v-col cols="12"> <autocomplete-user-group v-model="inputs.access._userGroups" label="노출그룹" multiple v-bind="attrs_input" ref="group-autocomplete" /> </v-col>
                    <v-col cols="12"> <v-select v-model="inputs.access.siteFeatures" multiple :items="siteFeatures" label="노출기능" v-bind="attrs_input" /> </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn text color="primary" @click="save">저장</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import { attrs_input, SITE_FEATURES } from "@/assets/variables";
import AutocompleteUserGroup from "../dumb/autocomplete-user-group.vue";
import AutocompleteShopCategory from "../dumb/autocomplete-shop-category.vue";

const initInputs = () => ({
    _parent: null,
    _children: [],
    name: "",
    depth: 0,
    access: {
        _userGroups: [],
        siteFeatures: [],
    },
});

const depthItems = [
    { text: "대분류", value: 0 },
    { text: "중분류", value: 1 },
];

const siteFeatures = Object.values(SITE_FEATURES);

export default {
    components: {
        AutocompleteUserGroup,
        AutocompleteShopCategory,
    },
    props: {
        _id: { type: String, default: null },
    },
    data: () => ({
        inputs: initInputs(),

        dialog: false,

        depthItems,
        attrs_input,
        siteFeatures,
    }),
    computed: {
        isCreate() {
            return !this._id;
        },
        title() {
            let title = "카테고리";
            title += this.isCreate ? " 생성" : " 수정";
            return title;
        },
    },
    watch: {
        dialog() {
            if (this.dialog) this.init();
        },
    },
    methods: {
        async init() {
            if (this.isCreate) this.inputs = initInputs();
            else {
                let { _id } = this;
                this.inputs = (await api.console.shop.categories.get({ _id }))?.category;
                this.$emit("input", { ...this.inputs });
            }

            this.$refs["parent-autocomplete"]?.init?.();
            this.$refs["children-autocomplete"]?.init?.();
        },
        validates() {
            let { depth, _parent, _children, name } = this.inputs;
            if (!name || name.replace(/\s/, "").length == 0) throw new Error("카테고리명을 작성해주세요");
            if (depth == 0) {
                if (_parent) throw new Error("잘못된 요청입니다");
            }
            if (depth == 1) {
                if (_children.length > 0) throw new Error("잘못된 요청입니다");
            }
        },
        async save() {
            try {
                this.validates();

                let { post, put } = api.console.shop.categories;
                let category = (await (this.isCreate ? post : put)(this.inputs))?.category;

                alert(this.isCreate ? "생성되었습니다" : "수정되었습니다");
                this.$emit("input", category);
                this.dialog = false;
            } catch (error) {
                this.$handleError(error);
            }
        },
        setForeignKeys(depth) {
            if (depth == 0) this.inputs._parent = null;
            if (depth == 1) this.inputs._children = [];
        },
    },
};
</script>
