var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-data-table', _vm._b({
    staticClass: "transparent",
    attrs: {
      "dense": "",
      "disable-filtering": "",
      "disable-sort": ""
    },
    scopedSlots: _vm._u([{
      key: "top",
      fn: function fn() {
        return [_c('v-card-subtitle', {
          staticClass: "font-weight-bold",
          staticStyle: {
            "background-color": "rgba(0, 0, 0, 0.05)"
          }
        }, [_vm.$slots['title'] ? _vm._t("title") : _c('span', [_vm._v("상품")])], 2), _vm._v(" "), _c('v-divider')];
      },
      proxy: true
    }, {
      key: "item.code",
      fn: function fn(_ref) {
        var item = _ref.item,
            value = _ref.value;
        return [_vm._v(" " + _vm._s(value ? value : item.name || "-"))];
      }
    }, {
      key: "item.codeAlt",
      fn: function fn(_ref2) {
        var item = _ref2.item,
            value = _ref2.value;
        return [_vm._v(" " + _vm._s(value ? value : item.name || "-"))];
      }
    }, {
      key: "item.select",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('v-simple-checkbox', {
          staticClass: "mr-n2",
          attrs: {
            "value": _vm.selected.includes(item._id)
          },
          on: {
            "click": function click($event) {
              return _vm.$emit('select', item._id);
            }
          }
        })];
      }
    }], null, true)
  }, 'v-data-table', {
    headers: _vm.headers,
    items: _vm.items
  }, false));
}
var staticRenderFns = []

export { render, staticRenderFns }