var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "max-width": "880",
      "persistent": "",
      "fullscreen": !_vm.$vuetify.breakpoint.lgAndUp
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var attrs = _ref.attrs,
            on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs: attrs,
          on: on,
          disabled: _vm.disabled
        })];
      }
    }], null, true),
    model: {
      value: _vm.shows,
      callback: function callback($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_c('v-card', {
    attrs: {
      "tile": ""
    }
  }, [_c('v-card-title', [_vm._v("선택 받으시는분 변경")]), _c('v-card-text', {
    staticClass: "pb-0"
  }, [_c('v-row', {
    staticClass: "mx-n2"
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "lg": "7"
    }
  }, [_c('v-row', {
    staticClass: "mx-n2"
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('table-receiver-summary', _vm._b({}, 'table-receiver-summary', {
    selected: _vm.selected
  }, false))], 1)], 1)], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "lg": "5"
    }
  }, [_c('v-row', {
    staticClass: "mx-n2"
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "받으시는분"
    },
    on: {
      "input": function input($event) {
        return _vm.input('RECEIVER_NAME');
      }
    },
    model: {
      value: _vm.receiver.name,
      callback: function callback($$v) {
        _vm.$set(_vm.receiver, "name", $$v);
      },
      expression: "receiver.name"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input, {
    disabled: _vm.disabled
  }), false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-phone-field', _vm._b({
    attrs: {
      "colAttrs": {
        class: 'pa-2'
      },
      "rowAttrs": {
        class: 'ma-n2'
      },
      "label": "연락처"
    },
    on: {
      "input": function input($event) {
        return _vm.input('RECEIVER_PHONE');
      }
    },
    model: {
      value: _vm.receiver.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.receiver, "phone", $$v);
      },
      expression: "receiver.phone"
    }
  }, 'v-phone-field', Object.assign({}, _vm.attrs_input, {
    disabled: _vm.disabled
  }), false))], 1), _c('v-col', {
    staticClass: "pa-2 pt-0 mt-n2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-address-field', _vm._b({
    staticStyle: {
      "flex": "0 0 auto"
    },
    attrs: {
      "noRules": "",
      "className": "mt-4",
      "btnAttrs": {
        className: 'mt-n2 mb-n1',
        color: 'secondary',
        height: '40'
      },
      "label": "연락처"
    },
    on: {
      "input": function input($event) {
        return _vm.input('RECEIVER_ADDRESS');
      }
    },
    model: {
      value: _vm.receiver,
      callback: function callback($$v) {
        _vm.receiver = $$v;
      },
      expression: "receiver"
    }
  }, 'v-address-field', Object.assign({}, _vm.attrs_input, {
    disabled: _vm.disabled
  }), false))], 1)], 1)], 1)], 1)], 1), _c('v-card-actions', {
    staticClass: "justify-end"
  }, [_c('v-subheader', {
    staticClass: "caption"
  }, [_vm._v(" 선택된 항목 총 "), _c('span', {
    staticClass: "mx-1 text-subtitle-1 font-weight-bold primary--text"
  }, [_vm._v(" " + _vm._s(_vm.selected.length) + " ")]), _vm._v(" 건 ")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.shows = false;
      }
    }
  }, [_vm._v("취소")]), _c('v-btn', _vm._b({
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, 'v-btn', {
    disabled: _vm.disabled
  }, false), [_vm._v("변경")])], 1), _c('v-fade-transition', [_c('v-overlay', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      "absolute": "",
      "light": "",
      "color": "white"
    }
  }, [_c('v-sheet', {
    staticStyle: {
      "overflow": "hidden"
    },
    attrs: {
      "width": "240",
      "rounded": ""
    }
  }, [_c('v-progress-linear', {
    attrs: {
      "value": _vm.updatedLength / _vm.selected.length * 100,
      "height": "24"
    }
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.updatedLength) + " / " + _vm._s(_vm.selected.length) + " ")])])], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }