var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    staticClass: "px-3 mx-n3",
    attrs: {
      "max-width": "1080",
      "width": "100%",
      "height": "calc(100vh - 48px - 12px - 12px)"
    }
  }, [_c('v-layout', {
    attrs: {
      "d-flex": "",
      "column": "",
      "fill-height": ""
    }
  }, [_c('list-heading', {
    staticStyle: {
      "flex": "0 1 auto"
    },
    attrs: {
      "title": "롯데백화점 구매주문 집계",
      "showsFilterButton": ""
    },
    model: {
      value: _vm.showsSearch,
      callback: function callback($$v) {
        _vm.showsSearch = $$v;
      },
      expression: "showsSearch"
    }
  }), _c('purchase-order-status-search', _vm._b({
    staticStyle: {
      "flex": "0 1 auto"
    }
  }, 'purchase-order-status-search', {
    loading: _vm.loading,
    showsSearch: _vm.showsSearch
  }, false)), _c('v-row', {
    staticClass: "mr-n2",
    attrs: {
      "no-gutters": "",
      "justify": "end"
    }
  }, [[_c('v-col', {
    staticClass: "pb-2 pr-2",
    attrs: {
      "lg": "auto"
    }
  }, [_c('excel-download-purchase-order-status', _vm._b({
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var click = _ref.click,
            loading = _ref.loading;
        return [_c('v-sheet', {
          attrs: {
            "outlined": "",
            "rounded": ""
          }
        }, [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "block": "",
            "color": "white green--text"
          }
        }, 'v-btn', {
          loading: loading
        }, false), {
          click: click
        }), [_c('v-icon', {
          staticClass: "mr-1"
        }, [_vm._v("mdi-microsoft-excel")]), _vm._v(" 전체 다운로드 ")], 1)], 1)];
      }
    }])
  }, 'excel-download-purchase-order-status', {
    title: _vm.title,
    items: _vm.items,
    foot: _vm.foot
  }, false))], 1)]], 2), _c('v-data-table', _vm._b({
    staticClass: "v-sheet--outlined",
    staticStyle: {
      "flex": "0 1 auto",
      "overflow": "hidden"
    },
    attrs: {
      "fixed-header": "",
      "disable-filtering": "",
      "disable-sort": "",
      "disable-pagination": "",
      "hide-default-footer": "",
      "items-per-page": -1,
      "height": "100%",
      "mobile-breakpoint": "0"
    },
    scopedSlots: _vm._u([_vm._l(_vm.headers.filter(function (header) {
      return header.hasOwnProperty('formatter');
    }), function (header) {
      return {
        key: "item.".concat(header.value),
        fn: function fn(_ref2) {
          var value = _ref2.value;
          return [_vm._v(" " + _vm._s(header.formatter(value)) + " ")];
        }
      };
    }), _vm.items.length ? {
      key: "foot",
      fn: function fn() {
        return [_c('tfoot', {
          staticStyle: {
            "position": "sticky",
            "bottom": "0",
            "background-color": "white"
          }
        }, [_c('td', {
          attrs: {
            "colspan": _vm.headers.length
          }
        }, [_c('v-divider')], 1), _c('tr', {
          staticClass: "subtitle-2 text-center font-weight-bold primary--text"
        }, _vm._l(_vm.headers, function (_ref3, index) {
          var value = _ref3.value;
          return _c('td', {
            key: value,
            class: {
              'sticky-left': index == 0,
              'v-data-table__divider': index != _vm.headers.length - 1
            }
          }, [_vm._v(_vm._s(_vm.foot[value] || "-"))]);
        }), 0)])];
      },
      proxy: true
    } : null], null, true)
  }, 'v-data-table', {
    headers: _vm.headers,
    items: _vm.items,
    loading: _vm.loading
  }, false))], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }