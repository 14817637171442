var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-data-table', _vm._b({
    attrs: {
      "disable-filtering": "",
      "disable-sort": "",
      "hide-default-footer": "",
      "mobile-breakpoint": "10000"
    },
    scopedSlots: _vm._u([_vm.showsProducts && !_vm.readonlyProducts ? {
      key: "top",
      fn: function fn() {
        return [_vm.isBulkOrder ? _c('v-row', {
          attrs: {
            "no-gutters": ""
          }
        }, [_c('v-col', {
          staticClass: "py-2",
          attrs: {
            "cols": "auto"
          }
        }, [_c('v-btn', {
          attrs: {
            "text": "",
            "tile": ""
          },
          on: {
            "!click": function click($event) {
              $event.preventDefault();
              $event.stopPropagation();
              return _vm.toggleSelectedAll.apply(null, arguments);
            }
          }
        }, [_c('v-simple-checkbox', {
          attrs: {
            "value": _vm.isAllSelected,
            "hide-details": "",
            "color": "primary"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
            }
          }
        }), _vm._v(" 전체선택 ")], 1)], 1), _c('v-spacer'), _c('v-col', {
          staticClass: "py-2 px-4",
          attrs: {
            "cols": "auto"
          }
        }, [_c('dialog-products-select', _vm._b({
          on: {
            "input": _vm.setSelectedProducts
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref) {
              var attrs = _ref.attrs,
                  on = _ref.on;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "outlined": "",
                  "block": "",
                  "color": "primary",
                  "disabled": !_vm.selected.length
                }
              }, 'v-btn', attrs, false), on), [_vm._v("일괄 상품추가")])];
            }
          }], null, false, 1045041820)
        }, 'dialog-products-select', {
          form: _vm.form
        }, false))], 1)], 1) : _c('v-row', {
          attrs: {
            "no-gutters": ""
          }
        }, [_c('v-spacer'), _c('v-col', {
          staticClass: "py-2 px-4",
          attrs: {
            "cols": "auto"
          }
        }, [_c('dialog-products-select', _vm._b({
          on: {
            "input": function input(items) {
              return _vm.setProducts(items, _vm.orders[0].tempId);
            }
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref2) {
              var attrs = _ref2.attrs,
                  on = _ref2.on;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "outlined": "",
                  "color": "primary"
                }
              }, 'v-btn', attrs, false), on), [_vm._v("개별 상품추가")])];
            }
          }], null, false, 49568799)
        }, 'dialog-products-select', {
          form: _vm.form
        }, false))], 1)], 1), _c('v-divider')];
      },
      proxy: true
    } : null, {
      key: "item.select",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('v-row', {
          staticClass: "mx-n4",
          staticStyle: {
            "width": "400px"
          },
          attrs: {
            "no-gutters": ""
          }
        }, [_c('v-col', {
          staticClass: "px-2",
          attrs: {
            "cols": "auto"
          }
        }, [_c('v-btn', {
          attrs: {
            "text": "",
            "icon": ""
          },
          on: {
            "!click": function click($event) {
              $event.preventDefault();
              $event.stopPropagation();
              return _vm.toggleSelected(item);
            }
          }
        }, [_c('v-simple-checkbox', {
          staticClass: "mr-n2",
          attrs: {
            "value": _vm.selected.includes(item.tempId),
            "hide-details": "",
            "color": "primary"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
            }
          }
        })], 1)], 1), _c('v-spacer'), _c('v-col', {
          staticClass: "px-4",
          attrs: {
            "cols": "auto"
          }
        }, [_c('dialog-products-select', _vm._b({
          on: {
            "input": function input(items) {
              return _vm.setProducts(items, item.tempId);
            }
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref4) {
              var attrs = _ref4.attrs,
                  on = _ref4.on;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "outlined": "",
                  "color": "primary"
                }
              }, 'v-btn', attrs, false), on), [_vm._v("개별 상품추가")])];
            }
          }], null, true)
        }, 'dialog-products-select', {
          form: _vm.form
        }, false))], 1)], 1)];
      }
    }, {
      key: "item.receiver.name",
      fn: function fn(_ref5) {
        var item = _ref5.item,
            value = _ref5.value;
        return [_vm.readonly ? [_vm._v(_vm._s(value || "-"))] : _c('dialog-input', _vm._b({
          attrs: {
            "label": "이름"
          },
          on: {
            "input": function input($event) {
              return _vm.update(item);
            }
          },
          model: {
            value: item.receiver.name,
            callback: function callback($$v) {
              _vm.$set(item.receiver, "name", $$v);
            },
            expression: "item.receiver.name"
          }
        }, 'dialog-input', _vm.attrs_input, false))];
      }
    }, {
      key: "item.receiver.phone",
      fn: function fn(_ref6) {
        var item = _ref6.item,
            value = _ref6.value;
        return [_vm.readonly ? [_vm._v(_vm._s(value || "-"))] : _c('dialog-input', _vm._b({
          attrs: {
            "label": "연락처"
          },
          on: {
            "input": function input($event) {
              return _vm.update(item);
            }
          },
          model: {
            value: item.receiver.phone,
            callback: function callback($$v) {
              _vm.$set(item.receiver, "phone", $$v);
            },
            expression: "item.receiver.phone"
          }
        }, 'dialog-input', _vm.attrs_input, false))];
      }
    }, {
      key: "item.receiver.address",
      fn: function fn(_ref7) {
        var item = _ref7.item,
            value = _ref7.value;
        return [_vm.readonly ? [_vm._v(_vm._s(value || "-"))] : _c('dialog-address', _vm._b({
          on: {
            "input": function input($event) {
              return _vm.update(item);
            }
          },
          model: {
            value: item.receiver,
            callback: function callback($$v) {
              _vm.$set(item, "receiver", $$v);
            },
            expression: "item.receiver"
          }
        }, 'dialog-address', _vm.attrs_input, false))];
      }
    }, {
      key: "item.products",
      fn: function fn(_ref8) {
        var item = _ref8.item;
        return [_c('div', {
          staticStyle: {
            "position": "relative"
          }
        }, [_c('v-divider', {
          staticStyle: {
            "position": "absolute",
            "top": "-1px",
            "left": "calc(-28px - 16px - 16px - 17px)",
            "width": "400px",
            "max-width": "400px"
          }
        })], 1), _c('div', {
          staticClass: "mr-n4",
          staticStyle: {
            "border-left": "thin solid rgba(0, 0, 0, 0.12)"
          }
        }, [_c('table-products', {
          staticStyle: {
            "width": "calc(400px - 16px - 28px - 16px - 17px)"
          },
          attrs: {
            "readonly": _vm.readonlyProducts,
            "hideRemoveBtn": _vm.readonlyProducts
          },
          on: {
            "input": function input($event) {
              return _vm.update(item);
            }
          },
          model: {
            value: item.products,
            callback: function callback($$v) {
              _vm.$set(item, "products", $$v);
            },
            expression: "item.products"
          }
        })], 1)];
      }
    }, {
      key: "item.actions",
      fn: function fn(_ref9) {
        var item = _ref9.item;
        return [!_vm.readonly ? _c('v-icon', {
          attrs: {
            "color": "error"
          },
          on: {
            "click": function click($event) {
              return _vm.remove(item);
            }
          }
        }, [_vm._v("mdi-delete")]) : _vm._e()];
      }
    }, _vm.isBulkOrder && !_vm.readonly ? {
      key: "foot",
      fn: function fn() {
        return [_c('v-divider'), _c('v-btn', {
          attrs: {
            "x-large": "",
            "text": "",
            "block": "",
            "color": "primary"
          },
          on: {
            "click": _vm.addItem
          }
        }, [_c('v-icon', {
          staticClass: "mr-1"
        }, [_vm._v("mdi-plus")]), _vm._v(" 항목 추가 ")], 1)];
      },
      proxy: true
    } : null], null, true)
  }, 'v-data-table', {
    headers: _vm.headers,
    items: _vm.items
  }, false));
}
var staticRenderFns = []

export { render, staticRenderFns }