var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', [_c('v-card-subtitle', {
    staticClass: "px-6"
  }, [_vm._v(_vm._s(_vm.subtitle))]), _c('v-divider'), _c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('autocomplete-user-group', _vm._b({
    attrs: {
      "label": "주문자그룹"
    },
    on: {
      "input": function input($event) {
        ((_vm.form._orderer = null) || 1) && _vm.emit();
      }
    },
    model: {
      value: _vm.form._ordererGroup,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "_ordererGroup", $$v);
      },
      expression: "form._ordererGroup"
    }
  }, 'autocomplete-user-group', _vm.attrs_input, false))], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('autocomplete-user', _vm._b({
    ref: "autocomplete-user",
    attrs: {
      "label": "주문자"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form._orderer,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "_orderer", $$v);
      },
      expression: "form._orderer"
    }
  }, 'autocomplete-user', Object.assign({}, _vm.attrs_input, {
    params: _vm.params,
    disabled: _vm.disabled
  }), false))], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "x-large": "",
      "text": "",
      "block": "",
      "color": "primary",
      "disabled": !_vm.form._orderer
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('forward');
      }
    }
  }, [_vm._v("선택완료")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }