<template>
    <v-row justify="center" align="center">
        <v-col cols="auto" class="headline text-start">{{ title }} <slot name="add-button" /></v-col>
        <v-spacer />
        <v-col v-if="showsFilterButton" cols="12" md="auto">
            <v-row align="center" class="my-n3">
                <v-col v-if="$slots['buttons']"> <slot name="buttons" /> </v-col>
                <v-spacer class="d-block d-md-none" />
                <v-col cols="auto">
                    <v-btn color="secondary" rounded @click="$emit('input', !value)" class="my-n1"><v-icon small>mdi-filter-variant</v-icon>검색필터</v-btn>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
export default {
    props: {
        value: { type: Boolean, default: false },

        title: { type: String, default: "목록" },
        showsFilterButton: { type: Boolean, default: false },
    },
};
</script>

<style></style>
