var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-combobox', _vm._b({
    attrs: {
      "prefix": "페이지 당",
      "suffix": "건"
    },
    on: {
      "change": _vm.pushQuery
    },
    model: {
      value: _vm.limit,
      callback: function callback($$v) {
        _vm.limit = $$v;
      },
      expression: "limit"
    }
  }, 'v-combobox', Object.assign({}, _vm.$attrs, {
    items: _vm.items
  }), false));
}
var staticRenderFns = []

export { render, staticRenderFns }