<template>
    <v-edit-dialog :return-value.sync="item.state" large cancel-text="취소" save-text="변경" v-on="{ save }">
        <slot />
        <template #input> <v-select v-model="item.state" :items="states" v-bind="attrs_input" class="mt-3" /> </template>
    </v-edit-dialog>
</template>

<script>
import { attrs_input, PURCHASE_ORDER_STATES } from "@/assets/variables";
const states = Object.values(PURCHASE_ORDER_STATES);

export default {
    props: {
        value: { type: Object, default: () => ({}) },
    },
    data: () => ({
        item: {},
        states,
        attrs_input,
    }),
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.item = JSON.parse(JSON.stringify(this.value));
        },
        save() {
            let { _id, state } = this.item;
            let form = { _id, state };

            switch (state) {
                case PURCHASE_ORDER_STATES.CANCELED.value: {
                    form.canceledAt = Date.now();
                    break;
                }
                case PURCHASE_ORDER_STATES.TRANSPORT.value: {
                    form.transportAt = Date.now();
                    break;
                }
                case PURCHASE_ORDER_STATES.PROCESSED.value: {
                    form.processedAt = Date.now();
                    break;
                }
            }

            const message_change = `발주번호: ${this.item?.number}\n변경사항: ${PURCHASE_ORDER_STATES[this.value?.state]?.text || "-"} => ${PURCHASE_ORDER_STATES[state]?.text || "-"}`;
            const message_success = `주문상태 변경이 처리되었습니다.\n\n${message_change}`;
            const message_failure = `주문상태 변경에 실패하였습니다.\n\n${message_change}`;

            this.$emit("update", form, message_success, message_failure);
        },
    },
};
</script>

<style>
</style>