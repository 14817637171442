<template>
    <v-layout justify-center>
        <v-responsive width="100%" height="100%">
            <list-heading :title="`${title} 목록`" showsFilterButton v-model="showsSearch">
                <template #buttons> <manager-search class="my-n2" /> </template>
            </list-heading>
            <purchase-orders-search v-bind="{ excel, types, states, columns, loading, showsSearch }" />

            <v-row no-gutters justify="end" class="mr-n2">
                <v-col lg="auto" class="pb-2 pr-2">
                    <dialog-state-update v-bind="{ selected }" v-on="{ updateItems }">
                        <template #activator="{ attrs, on, disabled }">
                            <v-card outlined rounded v-bind="{ disabled }">
                                <v-btn block color="white secondary--text" v-bind="{ ...attrs }" v-on="on"> <v-icon class="mr-1">mdi-form-select</v-icon> 선택 상태 변경 </v-btn>
                            </v-card>
                        </template>
                    </dialog-state-update>
                </v-col>
                <v-col lg="auto" class="pb-2 pr-2">
                    <dialog-targets-at-update v-bind="{ selected }" v-on="{ updateItems }">
                        <template #activator="{ attrs, on, disabled }">
                            <v-card outlined rounded v-bind="{ disabled }">
                                <v-btn block color="white secondary--text" v-bind="{ ...attrs }" v-on="on"> <v-icon class="mr-1">mdi-calendar-blank</v-icon> 선택 입고/발송일 변경 </v-btn>
                            </v-card>
                        </template>
                    </dialog-targets-at-update>
                </v-col>
                <v-col lg="auto" class="pb-2 pr-2" v-if="['upload', 'invoice'].includes(excel)">
                    <dialog-sender-update v-bind="{ selected }" v-on="{ updateItems }">
                        <template #activator="{ attrs, on, disabled }">
                            <v-card outlined rounded v-bind="{ disabled }">
                                <v-btn block color="white secondary--text" v-bind="{ ...attrs }" v-on="on"> <v-icon class="mr-1">mdi-account-arrow-right</v-icon> 선택 보내시는분 변경 </v-btn>
                            </v-card>
                        </template>
                    </dialog-sender-update>
                </v-col>
                <v-col lg="auto" class="pb-2 pr-2" v-if="['upload', 'invoice'].includes(excel)">
                    <dialog-receiver-update v-bind="{ selected }" v-on="{ updateItems }">
                        <template #activator="{ attrs, on, disabled }">
                            <v-card outlined rounded v-bind="{ disabled }">
                                <v-btn block color="white secondary--text" v-bind="{ ...attrs }" v-on="on"> <v-icon class="mr-1">mdi-account-arrow-left</v-icon> 선택 받으시는분 변경 </v-btn>
                            </v-card>
                        </template>
                    </dialog-receiver-update>
                </v-col>
                <v-spacer class="d-none d-lg-block" />
                <template v-if="['upload'].includes(excel)">
                    <v-col lg="auto" class="pb-2 pr-2">
                        <dialog-order-input @input="init">
                            <template #activator="{ attrs, on }">
                                <v-sheet outlined rounded>
                                    <v-btn block color="white primary--text" v-bind="{ ...attrs, loading }" v-on="on">
                                        <v-icon class="mr-1">mdi-text-box-plus-outline</v-icon>
                                        수동 발주
                                    </v-btn>
                                </v-sheet>
                            </template>
                        </dialog-order-input>
                    </v-col>
                </template>
                <template v-if="['upload', 'invoice'].includes(excel)">
                    <v-col lg="auto" class="pb-2 pr-2">
                        <dialog-excel-upload v-bind="{ excel }" @input="init">
                            <template #activator="{ attrs, on }">
                                <v-sheet outlined rounded>
                                    <v-btn block color="white primary--text" v-bind="{ ...attrs, loading }" v-on="on">
                                        <v-icon class="mr-1">mdi-table-arrow-up</v-icon>
                                        <template v-if="excel == 'upload'"> 엑셀 발주 </template>
                                        <template v-if="excel == 'invoice'"> 엑셀 업데이트 </template>
                                    </v-btn>
                                </v-sheet>
                            </template>
                        </dialog-excel-upload>
                    </v-col>
                </template>
                <template v-if="['default', 'invoice', 'upload'].includes(excel)">
                    <v-col lg="auto" class="pb-2 pr-2">
                        <excel-download-purchase-order v-bind="{ title, excel, selected }">
                            <template #activator="{ click, loading }">
                                <v-sheet outlined rounded>
                                    <v-btn block color="white green--text" v-bind="{ loading }" v-on="{ click }">
                                        <v-icon class="mr-1">mdi-microsoft-excel</v-icon>
                                        선택 다운로드
                                    </v-btn>
                                </v-sheet>
                            </template>
                        </excel-download-purchase-order>
                    </v-col>
                    <v-col lg="auto" class="pb-2 pr-2">
                        <excel-download-purchase-order v-bind="{ title, excel, params }">
                            <template #activator="{ click, loading }">
                                <v-sheet outlined rounded>
                                    <v-btn block color="white green--text" v-bind="{ loading }" v-on="{ click }">
                                        <v-icon class="mr-1">mdi-microsoft-excel</v-icon>
                                        전체 다운로드
                                    </v-btn>
                                </v-sheet>
                            </template>
                        </excel-download-purchase-order>
                    </v-col>
                </template>
                <v-col lg="auto" class="pb-2 pr-2">
                    <v-sheet outlined rounded class="d-none d-lg-block" style="overflow: hidden" height="38" width="160">
                        <select-list-limit :value="limit" v-bind="{ loading }" solo flat dense hide-details />
                    </v-sheet>
                    <v-sheet outlined rounded class="d-block d-lg-none" style="overflow: hidden" height="38" min-width="160">
                        <select-list-limit :value="limit" v-bind="{ loading }" solo flat dense hide-details />
                    </v-sheet>
                </v-col>
            </v-row>
            <v-row no-gutters justify="start" class="mr-n2">
                <v-col lg="auto" class="pb-2 pr-2">
                    <dialog-businesscard-update v-bind="{ selected }" v-on="{ updateItems }">
                        <template #activator="{ attrs, on, disabled }">
                            <v-card outlined rounded v-bind="{ disabled }">
                                <v-btn block color="white secondary--text" v-bind="{ ...attrs }" v-on="on"> <v-icon class="mr-1">mdi-form-select</v-icon>선택 명함 업로드</v-btn>
                            </v-card>
                        </template>
                    </dialog-businesscard-update>
                </v-col>
                <v-col lg="auto" class="pb-2 pr-2">
                    <dialog-greetingcard-update v-bind="{ selected }" v-on="{ updateItems }">
                        <template #activator="{ attrs, on, disabled }">
                            <v-card outlined rounded v-bind="{ disabled }">
                                <v-btn block color="white secondary--text" v-bind="{ ...attrs }" v-on="on"> <v-icon class="mr-1">mdi-form-select</v-icon>선택 인사장 업로드</v-btn>
                            </v-card>
                        </template>
                    </dialog-greetingcard-update>
                </v-col>
                <v-col lg="auto" class="pb-2 pr-2">
                    <dialog-lms-update v-bind="{ selected }" v-on="{ updateItems }">
                        <template #activator="{ attrs, on, disabled }">
                            <v-card outlined rounded v-bind="{ disabled }">
                                <v-btn block color="white secondary--text" v-bind="{ ...attrs }" v-on="on"> <v-icon class="mr-1">mdi-message-alert-outline</v-icon>선택 문자여부 변경</v-btn>
                            </v-card>
                        </template>
                    </dialog-lms-update>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    {{ `검색 수: ${summary.totalCount.format()}  선택된 현황: ${selected.length}` }}
                </v-col>
            </v-row>
            <v-data-table v-model="selected" v-bind="{ headers, items, loading, itemClass }" show-select disable-filtering disable-sort disable-pagination hide-default-footer :items-per-page="-1" class="v-sheet--outlined purchase-order-list-table">
                <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>
                <template #[`item.actions`]="{ item }">
                    <purchase-order-view v-bind="{ item }" v-on="{ update }">
                        <template #activator="{ attrs, on }">
                            <v-icon v-bind="attrs" :color="isClaimed(item) ? 'error' : isSpecial(item) ? 'primary' : undefined" v-on="on">mdi-file-document-outline</v-icon>
                        </template>
                    </purchase-order-view>
                </template>
                <template #[`item.targetsAt`]="{ item, value }">
                    <edit-dialog-targets-at-update :value="item" v-on="{ update }">
                        <v-btn text tile class="caption" :color="isClaimed(item) ? 'error' : isSpecial(item) ? 'primary' : undefined">{{ value?.toDate?.() || "-" }}</v-btn>
                    </edit-dialog-targets-at-update>
                </template>
                <template #[`item.sender`]="{ item, value }">
                    <edit-dialog-sender-update :value="item" v-on="{ update }">
                        <div>
                            {{ value?.name || "-" }} <br />
                            {{ value?.phone || "-" }}
                        </div>
                    </edit-dialog-sender-update>
                </template>
                <template #[`item.receiver`]="{ item, value }">
                    <edit-dialog-receiver-update :value="item" v-on="{ update }">
                        <div>{{ value?.name || "-" }} / {{ value?.phone || "-" }}</div>
                        <v-tooltip bottom nudge-top="34">
                            <template #activator="{ attrs, on }">
                                <div class="text-truncate" v-bind="attrs" v-on="on">{{ value?.address || "-" }}</div>
                            </template>
                            {{ value?.address || "-" }}
                        </v-tooltip>
                    </edit-dialog-receiver-update>
                </template>
                <template #[`item.products.quantity`]="{ item }">
                    <v-tooltip bottom nudge-top="34">
                        <template #activator="{ attrs, on }">
                            <div class="text-truncate" v-bind="attrs" v-on="on">{{ item?.products[0]?.quantity || "-" }}</div>
                        </template>
                        {{ item?.products[0]?.quantity || "-" }}
                    </v-tooltip>
                </template>
                <template #[`item.products.code`]="{ item }">
                    <v-tooltip bottom nudge-top="34">
                        <template #activator="{ attrs, on }">
                            <div class="text-truncate" v-bind="attrs" v-on="on">{{ item?.products[0]?.code || "-" }}</div>
                        </template>
                        {{ item?.products[0]?.code || "-" }}
                    </v-tooltip>
                </template>
                <template #[`item.message`]="{ item, value }">
                    <edit-dialog-message-update :value="item" v-on="{ update }">
                        <v-tooltip bottom nudge-top="34">
                            <template #activator="{ attrs, on }">
                                <div class="text-truncate" v-bind="attrs" v-on="on">{{ value || "-" }}</div>
                            </template>
                            {{ value || "-" }}
                        </v-tooltip>
                    </edit-dialog-message-update>
                </template>
                <template #[`item.shippingMessage`]="{ item, value }">
                    <edit-dialog-shipping-message-update :value="item" v-on="{ update }">
                        <v-tooltip bottom nudge-top="34">
                            <template #activator="{ attrs, on }">
                                <div class="text-truncate" v-bind="attrs" v-on="on">{{ value || "-" }}</div>
                            </template>
                            {{ value || "-" }}
                        </v-tooltip>
                    </edit-dialog-shipping-message-update>
                </template>
                <template #[`item.stateText`]="{ item, value }">
                    <v-row no-gutters>
                        <v-col>
                            <edit-dialog-state-update :value="item" v-on="{ update }">
                                <v-btn text tile class="caption" :color="isClaimed(item) ? 'error' : isSpecial(item) ? 'primary' : undefined">{{ value || "-" }}</v-btn>
                            </edit-dialog-state-update>
                        </v-col>
                        <v-col cols="auto">
                            <v-btn v-if="isClaimed(item)" text icon tile color="error" :to="{ path: '/console/shop/purchase-order-claims', query: { orderNumber: item.number } }"> <v-icon size="18">mdi-link-variant</v-icon> </v-btn>
                        </v-col>
                    </v-row>
                </template>
                <template #[`item.number`]="{ item, value }">
                    <span :class="{ 'error--text': isClaimed(item), 'primary--text': !isClaimed(item) && isSpecial(item) }">{{ value }}</span>
                </template>
                <template #[`item.smsEnabled`]="{ item }">
                    <v-switch v-model="item.smsEnabled" @change="update({ _id: item._id, smsEnabled: item.smsEnabled }, '문자여부 변경이 처리되었습니다', '문자여부 변경에 실패하였습니다.')" />
                </template>
            </v-data-table>
            <v-pagination :value="page" :length="pageCount" :total-visible="11" color="primary" class="mt-2" @input="(page) => $router.push({ query: { ...$route.query, page } })"></v-pagination>
            <v-fade-transition>
                <v-overlay v-show="loading" color="white">
                    <v-progress-circular size="200" width="10" color="primary" indeterminate />
                </v-overlay>
            </v-fade-transition>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import dayjs from "dayjs";
import { PURCHASE_ORDER_STATES, PURCHASE_ORDER_TYPES, PURCHASE_ORDER_UPDATE_COLUMN } from "@/assets/variables";

import ListHeading from "@/components/console/dumb/list-heading.vue";
import SelectListLimit from "@/components/console/dumb/select-list-limit.vue";
import PurchaseOrderView from "@/components/console/shop/purchase-orders/purchase-order-view.vue";
import PurchaseOrdersSearch from "@/components/console/shop/purchase-orders/purchase-orders-search.vue";
import DialogOrderInput from "@/components/console/shop/purchase-orders/dialog-order-input/dialog-order-input.vue";
import DialogExcelUpload from "@/components/console/shop/purchase-orders/dialog-excel-upload/dialog-excel-upload.vue";
import DialogStateUpdate from "@/components/console/shop/purchase-orders/dialog-state-update/dialog-state-update.vue";
import DialogBusinesscardUpdate from "@/components/console/shop/purchase-orders/dialog-file-update/dialog-businesscard-update.vue";
import DialogGreetingcardUpdate from "@/components/console/shop/purchase-orders/dialog-file-update/dialog-greetingcard-update.vue";
import DialogLmsUpdate from "@/components/console/shop/purchase-orders/dialog-lms-update/dialog-lms-update.vue";
import DialogSenderUpdate from "@/components/console/shop/purchase-orders/dialog-sender-update/dialog-sender-update.vue";
import DialogReceiverUpdate from "@/components/console/shop/purchase-orders/dialog-receiver-update/dialog-receiver-update.vue";
import DialogTargetsAtUpdate from "@/components/console/shop/purchase-orders/dialog-targets-at-update/dialog-targets-at-update.vue";
import EditDialogStateUpdate from "@/components/console/shop/purchase-orders/edit-dialog-state-update.vue";
import EditDialogSenderUpdate from "@/components/console/shop/purchase-orders/edit-dialog-sender-update.vue";
import EditDialogReceiverUpdate from "@/components/console/shop/purchase-orders/edit-dialog-receiver-update.vue";
import EditDialogTargetsAtUpdate from "@/components/console/shop/purchase-orders/edit-dialog-targets-at-update.vue";
import ExcelDownloadPurchaseOrder from "@/components/console/shop/purchase-orders/excel-download-purchase-order.vue";
import EditDialogShippingMessageUpdate from "@/components/console/shop/purchase-orders/edit-dialog-shipping-message-update.vue";
import EditDialogMessageUpdate from "@/components/console/shop/purchase-orders/edit-dialog-message-update.vue";
import ManagerSearch from "@/components/console/shop/purchase-orders/manager-search.vue";

const formatOrderer = (value) => {
    const { username, group } = value || {};
    let text = "";
    if (group?.name) text += `${group.name}\n`;
    if (username) text += username;
    return text || "-";
};

export default {
    components: {
        ListHeading,
        SelectListLimit,
        PurchaseOrderView,
        PurchaseOrdersSearch,
        DialogOrderInput,
        DialogExcelUpload,
        DialogStateUpdate,
        DialogBusinesscardUpdate,
        DialogGreetingcardUpdate,
        DialogLmsUpdate,
        DialogSenderUpdate,
        DialogReceiverUpdate,
        DialogTargetsAtUpdate,
        EditDialogStateUpdate,
        EditDialogSenderUpdate,
        EditDialogReceiverUpdate,
        EditDialogTargetsAtUpdate,
        ExcelDownloadPurchaseOrder,
        EditDialogShippingMessageUpdate,
        EditDialogMessageUpdate,
        ManagerSearch,
    },
    props: {
        sort: { type: String, default: "createdAt_desc" },
        title: { type: String, default: "구매주문" },
        excel: { type: String, default: null },
        types: { type: Array, default: () => [] },
        states: { type: Array, default: () => [] },
        columns: { type: Array, default: () => [] },
    },
    data: () => ({
        loading: false,
        orders: [],
        selected: [],

        summary: { totalCount: 0 },

        showsSearch: true,
    }),
    computed: {
        headers() {
            let headers = [];

            headers.push({ width: +60, text: "상세", value: "actions", align: "left" });
            headers.push({ width: 80, text: "발주차수", value: "purchaseOrderNumber" });
            headers.push({ width: 100, text: "입고/발송일", value: "targetsAt", cellClass: "pa-0" });
            headers.push({ width: +90, text: "발주유형", value: "type", formatter: (value) => PURCHASE_ORDER_TYPES[value]?.textShorten || "-" });
            headers.push({ width: 100, text: "주문자", value: "orderer", cellClass: "white-space-pre-line", formatter: formatOrderer });

            if (["upload", "invoice"].includes(this.excel)) {
                headers.push({ width: 120, text: "보내시는분", value: "sender" });
                headers.push({ width: 260, text: "받으시는분", value: "receiver", cellClass: "max-width-0" });
                headers.push({ width: 100, text: "수량(A타입)", value: "products.quantity", cellClass: "max-width-0" });
                headers.push({ width: 170, text: "암호코드", value: "products.code", cellClass: "max-width-0" });
                headers.push({ width: 180, text: "메시지", value: "message", cellClass: "max-width-0" });
                headers.push({ width: 180, text: "배송메시지", value: "shippingMessage", cellClass: "max-width-0" });
            }

            headers.push({ width: +90, text: "주문상태", value: "stateText", cellClass: "pa-0" });
            headers.push({ width: 100, text: "발주일시", value: "createdAt", formatter: (value) => value?.toDateTime?.() || value || "-" });
            headers.push({ width: 130, text: "발주번호", value: "number" });
            headers.push({ width: 100, text: "문자여부", value: "smsEnabled" });

            return headers.map((item, index, array) => ({ ...item, divider: index != array.length - 1, formatter: item.formatter ?? ((value) => value ?? "-"), cellClass: (item?.cellClass || "") + " caption line-height-1-5" }));
        },
        items() {
            return this.orders.map((item, index) => ({ ...item, index: this.summary.totalCount - (this.page - 1) * this.limit - index }));
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        limit() {
            return +(this.$route.query.limit || "10");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;
            if (!query.state) query.state = this.states.map(({ value }) => value);
            if (!query.column) query.column = this.columns.map(({ value }) => value);
            if (!query.type) query.type = this.types.map(({ value }) => value);
            if (query.searchDateValue) query.searchDateValue = [query.searchDateValue[0] ? dayjs(query.searchDateValue[0]).startOf("day").toISOString() : null, query.searchDateValue[1] ? dayjs(query.searchDateValue[1]).endOf("day").toISOString() : null];

            let { sort } = this.$props;
            return { ...query, sort };
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            window.scrollTo({ top: 0, behavior: "smooth" });
            this.init();
        },
    },
    methods: {
        async init() {
            this.selected = [];
            this.search();
        },

        async search() {
            if (this.loading) return;
            this.loading = true;
            try {
                let { skip, limit, params } = this;
                var { summary, orders } = await api.console.shop.purchaseOrders.gets({
                    headers: { skip, limit },
                    params: { ...params, withHasSameOrder: true },
                });

                this.orders = orders;
                this.summary = summary;
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },

        async update(order, message_success, message_failure) {
            console.log(order, message_success, message_failure);
            this.loading = true;
            try {
                order = (await api.console.shop.purchaseOrders.put(order))?.order;
                this.updateItem(order);
                alert(message_success);

                this.loading = false;
                this.init();
            } catch {
                alert(message_failure);
            } finally {
                if (this.loading) this.loading = false;
            }
        },

        updateItems(items) {
            for (const order of items) {
                this.updateItem(order);
            }
            this.init();
        },

        async updateItem(order) {
            const index = this.orders.findIndex(({ _id }) => _id == order._id);
            if (0 <= index) this.orders.splice(index, 1, order);
            else {
                if (this.sort.split("_").pop() == "desc") this.orders = [order, ...this.orders];
                if (this.sort.split("_").pop() == "asc") this.orders.push(order);
                this.summary.totalCount += 1;
            }
        },

        isClaimed(item) {
            return item?.state == PURCHASE_ORDER_STATES.CLAIM_RECEIVED.value;
        },
        isSpecial(item) {
            const { businessCard, greetingCard } = item;
            return [businessCard, greetingCard].some((value) => value);
        },

        itemClass(item) {
            let backgroundColor = "";
            if (item?.updatedColumn === PURCHASE_ORDER_UPDATE_COLUMN.ERROR.value) backgroundColor = "red darken-4";
            if (item?.updatedColumn === PURCHASE_ORDER_UPDATE_COLUMN.TARGETSAT.value) backgroundColor = "red lighten-5";
            if (item?.updatedColumn === PURCHASE_ORDER_UPDATE_COLUMN.SENDER.value) backgroundColor = "orange lighten-5";
            if (item?.updatedColumn === PURCHASE_ORDER_UPDATE_COLUMN.RECEIVER_NAME.value) backgroundColor = "yellow lighten-5";
            if (item?.updatedColumn === PURCHASE_ORDER_UPDATE_COLUMN.RECEIVER_PHONE.value) backgroundColor = "green lighten-5";
            if (item?.updatedColumn === PURCHASE_ORDER_UPDATE_COLUMN.RECEIVER_ADDRESS.value) backgroundColor = "blue lighten-5";
            if (item?.updatedColumn === PURCHASE_ORDER_UPDATE_COLUMN.SHIPPINGMESSAGE.value) backgroundColor = "purple lighten-5";
            if (item?.hasSameOrders) return "orange";
            if (this.isClaimed(item)) return `error--text ${backgroundColor}`;
            if (this.isSpecial(item)) return `primary--text ${backgroundColor}`;
            return backgroundColor;
        },

        getRowBackgroundColor(item) {
            // 여기에서 각 행의 배경 색상을 동적으로 계산할 수 있는 로직을 추가합니다.
            // 예를 들어, 특정 조건에 따라 다른 색상을 지정할 수 있습니다.
            if (this.isClaimed(item)) {
                return "red"; // 예: 주문이 클레임된 경우 빨간색 배경
            } else if (this.isSpecial(item)) {
                return "blue"; // 예: 특별한 경우 파란색 배경
            } else {
                return ""; // 특별한 조건이 없는 경우 기본값 (배경 없음)
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .line-height-1-5 {
        line-height: 1.5;
    }
    .max-width-0 {
        max-width: 0;
    }
    .white-space-pre-line {
        white-space: pre-line;
    }
    .cursor-pointer {
        cursor: pointer;
    }
    .v-pagination button {
        box-shadow: none !important;
        border: thin solid rgba(0, 0, 0, 0.12);
    }
    .v-small-dialog__activator__content {
        width: 100%;
    }

    .purchase-order-list-table {
        .v-data-table__mobile-row {
            width: calc(100vw - 12px - 1px - 1px - 12px);
        }
        .v-data-table__mobile-row__header {
            width: 80px !important;
        }
        .v-data-table__mobile-row__cell {
            width: calc(100% - 16px - 80px - 16px) !important;
        }
    }
}
</style>