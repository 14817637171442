<template>
    <div><slot name="activator" v-bind="{ click, loading }" /></div>
</template>

<script>
import XLSX from "xlsx";
import dayjs from "dayjs";

export default {
    props: {
        title: { type: String, default: null },
        items: { type: Array, default: null },
        foot: { type: Object, default: null }
    },
    data: () => ({
        loading: false,
    }),
    methods: {
        async click() {
            if (this.items.length == 0) {
                alert("구매주문 집계를 검색해주세요");
                return;
            }
            this.loading = true;
            try {
                let workbook = new XLSX.utils.book_new();
                this.items = [...this.items, this.foot]
                let rows = this.items.flatMap(this.makeDefaultItem);

                let worksheet = XLSX.utils.json_to_sheet(rows);
                XLSX.utils.book_append_sheet(workbook, worksheet, "일반목록");
                XLSX.writeFile(workbook, `${this.title.replace(/\s/g, "_")}_목록-${dayjs().format("YYMMDDHHmmss")}.xlsx`);
            } catch (error) {
                alert(error?.message || "오류가 발생했습니다");
            } finally {
                this.loading = false;
            }
        },
        makeDefaultItem(item) {
            return {
                상품: item?.code || "-",
                합계: item?.total || "-",
                롯데마트: item?.lottemart || "-",
                홈플러스: item?.homeplus || "-",
                CU: item?.cu || "-",
                생협: item?.sh || "-",
                비앤씨: item?.bc || "-",
                특판: item?.special || "-",
            };
        },
    },
};
</script>

<style>
</style>