<template>
    <v-hover v-if="immersive" v-slot="{ hover }">
        <v-img v-bind="{ ...$attrs, src, size, rounded }">
            <v-file-input v-show="false" v-model="file" :label="label" accept="image/*" ref="file-input" />
            <v-overlay v-if="!src" absolute z-index="0">
                이미지가 없습니다
            </v-overlay>
            <v-fade-transition>
                <v-overlay v-show="hover" absolute z-index="0">
                    <v-btn fab color="primary" @click="onClick">
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                </v-overlay>
            </v-fade-transition>
        </v-img>
    </v-hover>
    <v-row v-else :class="{ 'flex-row-reverse': reversed }" align="baseline">
        <v-col cols="auto" :class="{ 'pr-0': !reversed, 'pl-0': reversed }">
            <image-popup :src="src" :size="size" :rounded="rounded" />
        </v-col>
        <v-col><v-file-input v-model="file" :label="label" accept="image/*" v-bind="{ ...inputAttrs, ...$attrs }"/></v-col>
    </v-row>
</template>

<script>
import ImagePopup from "./image-popup.vue";
const inputAttrs = {
    dense: true,
    outlined: true,
    persistentPlaceholder: true,
    "hide-details": true,
};
export default {
    components: { ImagePopup },
    props: {
        value: { type: File, default: null },
        size: { type: String, default: "40" },
        label: { type: String, default: "이미지" },
        rounded: { type: Boolean, default: false },
        reversed: { type: Boolean, default: false },
        imageSrc: { type: String, default: undefined },
        immersive: { type: Boolean, default: false },
    },
    computed: {
        isOutSync() {
            if (this.value || this.file) return this.value != this.file;
            else return false;
        },
        src() {
            if (this.file) return URL.createObjectURL(this.file);
            else return this.imageSrc;
        },
    },
    data() {
        return {
            file: null,
            inputAttrs,
        };
    },
    methods: {
        init() {
            this.file = this.value;
        },
        emit() {
            this.$emit("input", this.file);
        },
        onClick() {
            this.$refs["file-input"]?.$el?.getElementsByTagName("input")?.[0]?.click();
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        value() {
            if (this.isOutSync) this.init();
        },
        file() {
            if (this.isOutSync) this.emit();
        },
    },
};
</script>

<style></style>
