<template>
    <v-sheet>
        <v-card-subtitle class="px-6"> 유형을 선택하세요 </v-card-subtitle>
        <v-divider />
        <v-card-text>
            <v-list outlined>
                <v-list-item-group v-model="form.type" color="primary" @change="emit">
                    <v-list-item v-for="{ text, value } in types" :key="text" :value="value" dense tile class="px-6">
                        <v-list-item-title class="text-center">{{ text }}</v-list-item-title>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-card-text>
        <v-divider />
        <v-card-actions>
            <v-btn x-large text block color="primary" :disabled="!form.type" @click="$emit('forward')">선택완료</v-btn>
        </v-card-actions>
    </v-sheet>
</template>

<script>
import { attrs_input } from "@/assets/variables";
import { PURCHASE_ORDER_TYPES } from "@/assets/variables";

let { ADMIN_MANUAL_ORDER_BULK, ADMIN_MANUAL_ORDER_UNIT } = PURCHASE_ORDER_TYPES;
let types = [ADMIN_MANUAL_ORDER_BULK, ADMIN_MANUAL_ORDER_UNIT];

export default {
    props: {
        value: { type: Object, default: () => ({ type: null }) }, // form
    },
    data: () => ({
        types,
        attrs_input,

        form: {
            type: null,
        },
    }),
    computed: {
        disabled() {
            return !this.form?.type;
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.form = { ...this.value };
        },
        emit() {
            this.$emit("input", this.form);
        },
    },
};
</script>

<style>
</style>