<template>
    <list-search :showsSearch="showsSearch" @search="search">
        <v-row>
            <v-col cols="6" md="4" lg="3">
                <v-text-field v-model="query.createdAt[0]" type="date" label="클레임일(시작)" clearable v-bind="attrs_input" />
            </v-col>
            <v-col cols="6" md="4" lg="3">
                <v-text-field v-model="query.createdAt[1]" type="date" label="클레임일(종료)" clearable v-bind="attrs_input" />
            </v-col>
            <v-col cols="12" md="4" lg="3">
                <v-text-field v-model="query.orderNumber" label="발주번호" clearable v-bind="attrs_input" @keydown.enter="search" />
            </v-col>
        </v-row>
    </list-search>
</template>

<script>
import { attrs_input } from "@/assets/variables";

import ListSearch from "@/components/console/dumb/list-search.vue";

const initQuery = () => ({
    createdAt: [null, null],
    orderNumber: null,
});

export default {
    components: {
        ListSearch,
    },
    props: {
        showsSearch: { type: Boolean, default: false },
    },
    data: () => ({
        attrs_input,

        query: initQuery(),
    }),
    mounted() {
        this.sync();
    },

    methods: {
        sync() {
            this.query = {
                createdAt: this.$route.query?.createdAt || [null, null],
                orderNumber: this.$route.query?.orderNumber || null,
            };
        },
        search() {
            let { ...query } = this.query;

            if (!query.type) delete query.type;
            if (!query.orderNumber) delete query.orderNumber;

            query.page = 1;

            this.$router.push({ query });
        },
    },
};
</script>

<style></style>
