<template>
    <div class="headline text-start mt-8">{{ title }}</div>
</template>

<script>
export default {
    props: ["value"],
    computed: {
        code() {
            return this.$route.query.code || this.value?.code;
        },
        isReference() {
            return ["yknowledge-reference", "yfactory-reference"].includes(this.code);
        },
        isCreate() {
            return !this.value._id
        },
        title() {
            if (this.isReference) {
                if (this.isCreate) return "자료실 글등록";
                else return "자료실 글상세";
            } else if (this.isCreate) return "게시글 등록";
            else return "게시글 상세";
        },
    },
};
</script>

<style></style>
