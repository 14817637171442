var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-data-table', _vm._b({
    attrs: {
      "dense": "",
      "disable-filtering": "",
      "disable-sort": "",
      "hide-default-footer": "",
      "items-per-page": -1
    },
    scopedSlots: _vm._u([{
      key: "no-data",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.noDataText) + " ")];
      },
      proxy: true
    }, {
      key: "item.quantity",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_vm.readonly ? [_vm._v(" " + _vm._s(item.quantity) + " ")] : _c('v-text-field', {
          staticClass: "border-bottom-0 text-center-field",
          attrs: {
            "type": "number",
            "dense": "",
            "hide-details": ""
          },
          on: {
            "input": function input($event) {
              return _vm.update(item);
            }
          },
          model: {
            value: item.quantity,
            callback: function callback($$v) {
              _vm.$set(item, "quantity", $$v);
            },
            expression: "item.quantity"
          }
        })];
      }
    }, {
      key: "item.actions",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('v-btn', {
          attrs: {
            "text": "",
            "icon": ""
          },
          on: {
            "click": function click($event) {
              return _vm.remove(item);
            }
          }
        }, [_c('v-icon', [_vm._v("mdi-close")])], 1)];
      }
    }], null, true)
  }, 'v-data-table', {
    headers: _vm.headers,
    items: _vm.items,
    hideDefaultHeader: _vm.hideDefaultHeader
  }, false));
}
var staticRenderFns = []

export { render, staticRenderFns }