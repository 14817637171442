<template>
    <v-data-table v-bind="{ headers, items }" mobile-breakpoint="10000" disable-filtering disable-pagination disable-sort hide-default-footer :items-per-page="-1" dense>
        <template #[`item._ordererGroup`]>
            <div style="position: relative">
                <autocomplete-user-group v-model="form._ordererGroup" readonly dense hide-details />
            </div>
        </template>
        <template #[`item._orderer`]>
            <div style="position: relative">
                <autocomplete-user v-model="form._orderer" readonly dense hide-details />
            </div>
        </template>
    </v-data-table>
</template>

<script>
import AutocompleteUser from "@/components/console/dumb/autocomplete-user.vue";
import AutocompleteUserGroup from "@/components/console/dumb/autocomplete-user-group.vue";

const headers = [
    { text: "주문자그룹", value: "_ordererGroup" },
    { text: "주문자", value: "_orderer" },
];

export default {
    components: {
        AutocompleteUser,
        AutocompleteUserGroup,
    },
    props: {
        form: { type: Object, default: () => ({}) },
    },
    data: () => ({
        headers,
    }),
    computed: {
        items() {
            return [this.form];
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .v-data-table__mobile-table-row {
        display: block;
        padding: 12px 0;
    }
    .v-data-table__mobile-row {
        height: 24px !important;
        min-height: 24px;
    }

    .v-input__slot::before {
        content: none !important;
    }
    .v-input__append-inner {
        display: none;
    }
    input {
        font-size: 0.875rem;
        text-align: end;
    }
}
</style>
