<template>
    <v-dialog v-model="shows" width="320">
        <template #activator="{ attrs, on }">
            <v-btn text tile class="mr-n4" v-bind="attrs" v-on="on">
                <span class="font-weight-regular" style="text-transform: initial"> {{ value || "-" }} </span>
            </v-btn>
        </template>

        <v-card>
            <v-card-text class="pa-4">
                <v-text-field v-model="data" autofocus v-bind="$attrs" @keydown.enter="emit" />
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        value: { type: String, default: null }, // data
    },
    data: () => ({
        shows: false,
        data: null,
    }),
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
        shows() {
            if (this.shows) this.sync();
        },
    },
    methods: {
        sync() {
            this.data = this.value;
        },
        emit() {
            this.$emit("input", this.data);
            setTimeout(() => (this.shows = false), 50);
        },
    },
};
</script>

<style>
</style>