var render = function () {
  var _vm$board, _vm$board2, _vm$board2$createdAt, _vm$board2$createdAt$, _vm$board3, _vm$board4, _vm$board5, _vm$board6, _vm$board7;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.ready ? _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    staticClass: "shop-board-view",
    attrs: {
      "max-width": "1024px",
      "width": "100%",
      "height": "100%"
    }
  }, [_c('board-view-heading', {
    model: {
      value: _vm.board,
      callback: function callback($$v) {
        _vm.board = $$v;
      },
      expression: "board"
    }
  }), (_vm$board = _vm.board) !== null && _vm$board !== void 0 && _vm$board._id ? _c('v-card', {
    staticClass: "mt-6"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("기본정보")]), _c('v-divider'), _c('v-card-text', [_vm.$route.params._board ? _c('v-row', [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": (_vm.board.writer || {}).name,
      "label": "작성자",
      "persistent-placeholder": "",
      "hide-details": "",
      "readonly": "",
      "disabled": ""
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-datetime-field', {
    attrs: {
      "value": (_vm$board2 = _vm.board) === null || _vm$board2 === void 0 ? void 0 : (_vm$board2$createdAt = _vm$board2.createdAt) === null || _vm$board2$createdAt === void 0 ? void 0 : (_vm$board2$createdAt$ = _vm$board2$createdAt.toDateTime) === null || _vm$board2$createdAt$ === void 0 ? void 0 : _vm$board2$createdAt$.call(_vm$board2$createdAt),
      "label": "작성일",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    on: {
      "input": function input(value) {
        return _vm.board.createdAt = value;
      }
    }
  })], 1)], 1) : _vm._e(), !['community'].includes((_vm$board3 = _vm.board) === null || _vm$board3 === void 0 ? void 0 : _vm$board3.code) ? _c('v-row', {
    staticClass: "mt-3"
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "제목",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.board.subject,
      callback: function callback($$v) {
        _vm.$set(_vm.board, "subject", $$v);
      },
      expression: "board.subject"
    }
  })], 1)], 1) : _vm._e()], 1)], 1) : _vm._e(), !['community'].includes((_vm$board4 = _vm.board) === null || _vm$board4 === void 0 ? void 0 : _vm$board4.code) ? [_c('board-view-files', {
    model: {
      value: _vm.board.files,
      callback: function callback($$v) {
        _vm.$set(_vm.board, "files", $$v);
      },
      expression: "board.files"
    }
  })] : _vm._e(), ['community'].includes((_vm$board5 = _vm.board) === null || _vm$board5 === void 0 ? void 0 : _vm$board5.code) ? [_c('v-row', {
    staticClass: "mt-6"
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('board-view-images', {
    model: {
      value: _vm.board.files,
      callback: function callback($$v) {
        _vm.$set(_vm.board, "files", $$v);
      },
      expression: "board.files"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('board-view-youtube', {
    model: {
      value: _vm.board.youtube,
      callback: function callback($$v) {
        _vm.$set(_vm.board, "youtube", $$v);
      },
      expression: "board.youtube"
    }
  })], 1)], 1)] : _vm._e(), _c('v-card', {
    staticClass: "mt-6"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("내용")]), _c('v-divider'), _c('v-card-text', [['community'].includes((_vm$board6 = _vm.board) === null || _vm$board6 === void 0 ? void 0 : _vm$board6.code) ? _c('v-textarea', {
    attrs: {
      "hide-details": "",
      "outlined": ""
    },
    model: {
      value: _vm.board.content,
      callback: function callback($$v) {
        _vm.$set(_vm.board, "content", $$v);
      },
      expression: "board.content"
    }
  }) : _c('naver-smarteditor', {
    attrs: {
      "id": "content",
      "rows": "10"
    },
    model: {
      value: _vm.board.content,
      callback: function callback($$v) {
        _vm.$set(_vm.board, "content", $$v);
      },
      expression: "board.content"
    }
  })], 1)], 1), ['interior-inquire'].includes((_vm$board7 = _vm.board) === null || _vm$board7 === void 0 ? void 0 : _vm$board7.code) ? _c('v-card', {
    staticClass: "mt-6"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("답변")]), _c('v-card-text', [_c('naver-smarteditor', {
    attrs: {
      "id": "reply",
      "rows": "10"
    },
    model: {
      value: _vm.board.reply,
      callback: function callback($$v) {
        _vm.$set(_vm.board, "reply", $$v);
      },
      expression: "board.reply"
    }
  })], 1)], 1) : _vm._e(), _c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "md": "auto",
      "sm": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1)], 1)], 2)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }