<template>
    <v-card>
        <v-card-title class="subtitle-1">영상(유튜브)</v-card-title>
        <v-divider />
        <v-card-text>
            <v-text-field v-model="youtube" dense outlined label="URL" persistent-placeholder>
                <template #append>
                    <v-btn small text height="40" class="ma-0 mt-n2" color="primary" @click="setVideoId"> 미리보기 </v-btn>
                </template>
            </v-text-field>
            <v-expand-transition>
                <v-responsive v-show="ready" max-height="300">
                    <v-sheet>
                        <youtube ref="youtube" v-bind="{ videoId }" fitParent @ready="check" />
                    </v-sheet>
                </v-responsive>
            </v-expand-transition>
            <v-expand-transition>
                <v-sheet v-show="!ready">
                    <v-skeleton-loader type="image" />
                </v-sheet>
            </v-expand-transition>
        </v-card-text>
    </v-card>
</template>

<script>
import urlParser from "js-video-url-parser";

export default {
    props: {
        value: String,
    },
    data: () => ({
        youtube: "",
        videoId: "",
        ready: false,
        videoWidth: 16,
        videoHeight: 9,
    }),
    mounted() {
        this.youtube = this.value;
    },
    methods: {
        setVideoId() {
            this.videoId = urlParser.parse(this.youtube)?.id || "";
        },
        check(data) {
            const { width, height } = data?.i?.h || {};
            this.videoWidth = width;
            this.videoHeight = height;
            this.ready = true;
        },
    },
};
</script>

<style></style>
