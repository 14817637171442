<template>
    <v-menu v-model="showTimePicker" ref="timeMenu" :close-on-content-click="false" :return-value.sync="time" transition="scale-transition" offset-y min-width="290px">
        <template #activator="timePicker">
            <v-menu v-model="showDatePicker" ref="dateMenu" :close-on-content-click="false" :return-value.sync="date" transition="scale-transition" offset-y min-width="290px">
                <template #activator="{ attrs, on }">
                    <v-text-field v-model="datetime" v-bind="{ ...attrs, ...timePicker.attrs, ...$attrs, label, placeholder }" v-on="on" append-icon="event" readonly></v-text-field>
                </template>
                <v-date-picker v-model="date" v-bind="{ type }" no-title scrollable @input="($refs.dateMenu.save(date) || true) && ((showDatePicker = false) || true) && (showTimePicker = true)" />
            </v-menu>
        </template>
        <v-time-picker v-if="showTimePicker" v-model="time" no-title scrollable @click:minute="($refs.timeMenu.save(time) || true) && ((showTimepicker = false) || true) && (datetime = [date, time].join(' '))" />
    </v-menu>
</template>

<script>
export default {
    props: {
        value: { type: String, default: undefined },

        type: { type: String, default: "date" },
        label: { type: String, default: undefined },
        placeholder: { type: String, default: undefined },
    },
    data: () => ({
        datetime: null,

        date: null,
        time: null,

        showDatePicker: false,
        showTimePicker: false,
    }),
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
        datetime() {
            this.$emit("input", this.datetime ? new Date(this.datetime).toISOString().toDateTime() : this.datetime);
        },
    },
    methods: {
        sync() {
            this.datetime = this.value;
            this.date = this.value?.split?.(" ")?.[0] || null;
            this.time = this.value?.split?.(" ")?.[1] || null;
        },
    },
};
</script>
