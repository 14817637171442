<template>
    <v-layout justify-center>
        <v-responsive max-width="1280px" width="100%">
            <v-row class="mt-8" justify="center" align="center">
                <board-list-heading />
                <v-spacer></v-spacer>
                <v-col cols="auto" class="pt-0 pb-0">
                    <v-btn class="ma-2" color="secondary" rounded @click="showsSearch = !showsSearch"><v-icon small class="mr-2">mdi-filter-variant</v-icon>검색필터</v-btn>
                </v-col>
            </v-row>

            <v-expand-transition>
                <v-card v-show="showsSearch" class="elevation-1 mx-auto">
                    <v-card-title class="subtitle-2 font-weight-bold">검색필터</v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" sm="6" class="py-0">
                                <v-date-field v-model="filter.createdAt[0]" label="작성일자" persistent-placeholder hide-details></v-date-field>
                            </v-col>
                            <v-col cols="12" sm="6" class="py-0">
                                <v-date-field v-model="filter.createdAt[1]" label="작성일자" persistent-placeholder hide-details></v-date-field>
                            </v-col>
                        </v-row>
                        <v-row class="mt-3">
                            <v-col cols="6" md="3" class="py-0">
                                <v-select v-model="filter.searchKey" :items="searchKeys" label="검색조건" hide-details persistent-placeholder></v-select>
                            </v-col>
                            <v-col cols="6" md="3" class="py-0">
                                <v-text-field v-model="filter.searchValue" label="검색어" persistent-placeholder hide-details :disabled="!filter.searchKey"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" rounded text @click="(page = 1) && search()"><v-icon small class="mt-1 mr-2">mdi-magnify</v-icon>검색</v-btn>
                    </v-card-actions>
                </v-card>
            </v-expand-transition>

            <v-data-table :items="boards" :headers="headers.filter((header) => (header.test ? header.test($route.query.code) : true))" disable-sort disable-pagination hide-default-footer class="elevation-1 mt-4">
                <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" v-slot:[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>

                <template #[`item.no`]="{ item }"> {{ totalCount - (page - 1) * limit - +boards.indexOf(item) }} </template>
                <template #[`item.thumb`]="{ item }">
                    <v-row>
                        <v-img v-if="item.thumb" :src="item.thumb" width="54" height="54" contain />
                    </v-row>
                </template>
                <template #[`item.content`]="{ item, value }">
                    <v-menu max-width="480" bottom :close-on-content-click="false">
                        <template #activator="{ attrs, on }">
                            <span v-bind="attrs" v-on="on"> {{ value || "내용이 없습니다" }} </span>
                        </template>
                        <v-card>
                            <v-card-text style="white-space: pre-line"> {{ value }} </v-card-text>
                            <v-divider />
                            <v-card-actions>
                                <v-btn :href="`/honja/community/${item._id}`" target="_blank" color="primary" outlined> 미리보기 </v-btn>
                                <v-spacer />
                                <v-btn :to="`/console/boards/${item._id}`" color="primary"> 상세보기 </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-menu>
                </template>
                <template #[`item.media`]="{ item }">
                    <template v-if="!!item?.youtube">
                        <v-btn :href="item?.youtube" target="_blank" icon text>
                            <v-icon>mdi-youtube</v-icon>
                        </v-btn>
                    </template>
                    <template v-else-if="item?.files?.length > 0">
                        <images-overlay :imgs="item.files">
                            <template #activator="{ attrs, on }">
                                <v-img width="32" height="32" :src="item.files?.[0]?.url" v-bind="attrs" v-on="on" style="cursor: pointer" class="mx-auto" />
                            </template>
                        </images-overlay>
                    </template>
                    <template v-else>-</template>
                </template>
                <template #[`item.writer`]="{ value }">
                    <user-avatar-dialog v-bind="{ value }" disabled />
                </template>
                <template #[`item.actions`]="{ item }">
                    <v-icon small @click="edit(item)" title="수정">mdi-pencil</v-icon>
                    <v-icon v-if="false" small @click="copy(item)" title="복사" class="ml-2">mdi-content-copy</v-icon>
                    <v-icon small @click="remove(item)" title="삭제" class="ml-2">mdi-delete</v-icon>
                </template>
            </v-data-table>
            <v-pagination v-model="page" :length="pageCount" :total-visible="11" class="mt-4 mb-12" @input="search()"></v-pagination>

            <v-btn bottom color="accent" fab fixed right @click="create()">
                <v-icon>mdi-pencil</v-icon>
            </v-btn>
        </v-responsive>
    </v-layout>
</template>

<script>
import VDateField from "@/components/plugins/vuetify/v-date-field.vue";
import ImagesOverlay from "@/components/console/dumb/images-overlay.vue";
import BoardListHeading from "@/components/console/boards/board-list-heading.vue";
import UserAvatarDialog from "@/components/console/user/user-avatar-dialog.vue";

import api from "@/api";
const headers = [
    { width: 40, text: "no", value: "no" },
    { width: 50, text: "썸네일", value: "thumb", test: (code) => !["community"].includes(code) },
    { width: 60, text: "카테고리", value: "category", test: (code) => !["community"].includes(code) },
    { width: 250, text: "제목", value: "subject", test: (code) => !["community"].includes(code) },
    { width: 100, text: "기간", value: "period", test: (code) => ["store", "event"].includes(code) },
    { text: "글내용", value: "content", test: (code) => ["community"].includes(code), cellClass: "text-truncate" },
    { width: 60, text: "", value: "media", align: "center", test: (code) => ["community"].includes(code) },
    { width: 50, text: "작성자", value: "writer.name", test: (code) => !["community"].includes(code) },
    { width: 170, text: "작성자", value: "writer", test: (code) => ["community"].includes(code) },
    { width: 170, text: "작성일", value: "createdAt", formatter: (value) => value?.toDateTime?.() || value },
    { width: 40, text: "Actions", value: "actions" },
];

export default {
    components: {
        VDateField,
        ImagesOverlay,
        BoardListHeading,
        UserAvatarDialog,
    },
    data() {
        return {
            ///////////////////////////////////////////////
            // 검색필터
            ///////////////////////////////////////////////
            showsSearch: true,

            category: null,
            filter: {
                code: this.$route.query.code,
                searchKey: this.$route.query.searchKey,
                searchValue: this.$route.query.searchValue,
                createdAt: [(this.$route.query.createdAt || [])[0], (this.$route.query.createdAt || [])[1]],
            },

            page: +this.$route.query.page || 1,
            totalCount: 0,
            pageCount: 0,
            limit: 10,

            boards: [],
            headers,

            searchKeys: [
                { text: "전체", value: null },
                // { text: "작성자", value: "writer.name" },
                { text: "제목", value: "subject" },
                { text: "내용", value: "content" },
            ],
            searchKey: null,
            searchValue: null,
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            this.search(false);
        },
        async search(routable = true) {
            try {
                if (routable) {
                    this.$router.push({
                        query: Object.assign({}, this.filter, { page: this.page }),
                    });
                }

                var { summary, boards } = await api.console.boards.gets({
                    headers: {
                        skip: (this.page - 1) * this.limit,
                        limit: this.limit,
                    },
                    params: this.filter,
                });

                this.totalCount = summary.totalCount;
                this.pageCount = Math.ceil(summary.totalCount / this.limit);
                this.boards = boards;
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        create() {
            this.$router.push(`/console/boards/create?code=${this.$route.query.code}`);
        },
        edit(board) {
            this.$router.push(`/console/boards/${board._id}`);
        },
        async remove(board) {
            try {
                if (confirm("게시글을 삭제하시겠습니까?")) {
                    await api.console.boards.delete(board);
                    await this.search(false);

                    alert("삭제되었습니다");
                }
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
    },
    watch: {
        $route() {
            this.search(false);

            Object.assign(this.filter, this.$route.query);
            this.page = +this.$route.query.page || 1;
        },
    },
};
</script>
<style lang="scss" scoped>
::v-deep td.text-truncate {
    max-width: 1px;
}
</style>
