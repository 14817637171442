var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', [_c('v-card-subtitle', {
    staticClass: "px-6"
  }, [_vm._v(" 배송지를 입력하세요 ")]), _c('v-divider'), _c('table-receivers', _vm._b({
    model: {
      value: _vm.orders,
      callback: function callback($$v) {
        _vm.orders = $$v;
      },
      expression: "orders"
    }
  }, 'table-receivers', {
    isBulkOrder: _vm.isBulkOrder
  }, false)), _c('v-divider'), _c('v-card-actions', [_c('v-btn', _vm._b({
    attrs: {
      "x-large": "",
      "text": "",
      "block": "",
      "color": "primary"
    },
    on: {
      "click": _vm.emit
    }
  }, 'v-btn', {
    disabled: _vm.disabled,
    isBulkOrder: _vm.isBulkOrder
  }, false), [_vm._v("입력완료")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }