var render = function () {
  var _class;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', _vm._b({
    class: (_class = {}, _class[_vm.rowAttrs.class || ''] = true, _class.hasCertification = _vm.hasCertification, _class),
    attrs: {
      "align": "center"
    }
  }, 'v-row', _vm.rowAttrs, false), [_c('v-col', _vm._b({}, 'v-col', _vm.colAttrs, false), [_c('v-text-field', _vm._g(_vm._b({
    model: {
      value: _vm.phone1,
      callback: function callback($$v) {
        _vm.phone1 = $$v;
      },
      expression: "phone1"
    }
  }, 'v-text-field', Object.assign({}, _vm.$attrs, {
    label: _vm.label,
    readonly: _vm.readonly,
    dense: _vm.dense
  }), false), {
    input: _vm.input,
    click: _vm.click
  }))], 1), _c('v-col', {
    staticClass: "pa-0",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("-")]), _c('v-col', _vm._b({}, 'v-col', _vm.colAttrs, false), [_c('v-text-field', _vm._g(_vm._b({
    model: {
      value: _vm.phone2,
      callback: function callback($$v) {
        _vm.phone2 = $$v;
      },
      expression: "phone2"
    }
  }, 'v-text-field', Object.assign({}, _vm.$attrs, {
    readonly: _vm.readonly,
    dense: _vm.dense
  }), false), {
    input: _vm.input,
    click: _vm.click
  }))], 1), _c('v-col', {
    staticClass: "pa-0",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("-")]), _c('v-col', _vm._b({}, 'v-col', _vm.colAttrs, false), [_c('v-text-field', _vm._g(_vm._b({
    model: {
      value: _vm.phone3,
      callback: function callback($$v) {
        _vm.phone3 = $$v;
      },
      expression: "phone3"
    }
  }, 'v-text-field', Object.assign({}, _vm.$attrs, {
    readonly: _vm.readonly,
    dense: _vm.dense
  }), false), {
    input: _vm.input,
    click: _vm.click
  }))], 1), _vm.hasCertification ? [_c('v-col', _vm._b({
    attrs: {
      "cols": "12",
      "sm": "auto"
    }
  }, 'v-col', _vm.colAttrs, false), [_c('v-btn', _vm._b({
    attrs: {
      "x-large": _vm.dense == false ? true : false
    },
    on: {
      "click": _vm.certify
    }
  }, 'v-btn', Object.assign({}, _vm.btnAttrs, {
    dense: _vm.dense
  }), false), [_vm._v("본인인증")])], 1), _c('kcp-cert', {
    ref: "kcp-cert",
    on: {
      "input": _vm.processCertifiedData
    }
  })] : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }