var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "width": "320",
      "persistent": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var attrs = _ref.attrs,
            on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs: attrs,
          on: on
        })];
      }
    }], null, true),
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', {
    attrs: {
      "light": ""
    }
  }, [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.title) + " "), _c('v-spacer'), _vm._v(" "), _c('v-icon', {
    on: {
      "click": function click($event) {
        _vm.dialog = false;
      }
    }
  }, [_vm._v("mdi-close")])], 1), _c('v-card-text', {
    staticClass: "py-0"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-select', _vm._b({
    attrs: {
      "label": "분류",
      "items": _vm.depthItems
    },
    on: {
      "change": _vm.setForeignKeys
    },
    model: {
      value: _vm.inputs.depth,
      callback: function callback($$v) {
        _vm.$set(_vm.inputs, "depth", $$v);
      },
      expression: "inputs.depth"
    }
  }, 'v-select', _vm.attrs_input, false))], 1), _c('v-expand-transition', [_c('v-col', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.inputs.depth == 1,
      expression: "inputs.depth == 1"
    }],
    attrs: {
      "cols": "12"
    }
  }, [_c('autocomplete-shop-category', _vm._b({
    ref: "parent-autocomplete",
    attrs: {
      "label": "상위 카테고리",
      "depth": 0,
      "placeholder": "없음",
      "test": function test(item) {
        return item._id != _vm._id;
      }
    },
    model: {
      value: _vm.inputs._parent,
      callback: function callback($$v) {
        _vm.$set(_vm.inputs, "_parent", $$v);
      },
      expression: "inputs._parent"
    }
  }, 'autocomplete-shop-category', _vm.attrs_input, false))], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "카테고리명",
      "test": function test(item) {
        return item._id != _vm._id;
      }
    },
    model: {
      value: _vm.inputs.name,
      callback: function callback($$v) {
        _vm.$set(_vm.inputs, "name", $$v);
      },
      expression: "inputs.name"
    }
  }, 'v-text-field', _vm.attrs_input, false))], 1), _c('v-expand-transition', [_c('v-col', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.inputs.depth == 0,
      expression: "inputs.depth == 0"
    }],
    attrs: {
      "cols": "12"
    }
  }, [_c('autocomplete-shop-category', _vm._b({
    ref: "children-autocomplete",
    attrs: {
      "label": "하위 카테고리",
      "placeholder": "없음",
      "depth": 1,
      "multiple": ""
    },
    model: {
      value: _vm.inputs._children,
      callback: function callback($$v) {
        _vm.$set(_vm.inputs, "_children", $$v);
      },
      expression: "inputs._children"
    }
  }, 'autocomplete-shop-category', _vm.attrs_input, false))], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('autocomplete-user-group', _vm._b({
    ref: "group-autocomplete",
    attrs: {
      "label": "노출그룹",
      "multiple": ""
    },
    model: {
      value: _vm.inputs.access._userGroups,
      callback: function callback($$v) {
        _vm.$set(_vm.inputs.access, "_userGroups", $$v);
      },
      expression: "inputs.access._userGroups"
    }
  }, 'autocomplete-user-group', _vm.attrs_input, false))], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-select', _vm._b({
    attrs: {
      "multiple": "",
      "items": _vm.siteFeatures,
      "label": "노출기능"
    },
    model: {
      value: _vm.inputs.access.siteFeatures,
      callback: function callback($$v) {
        _vm.$set(_vm.inputs.access, "siteFeatures", $$v);
      },
      expression: "inputs.access.siteFeatures"
    }
  }, 'v-select', _vm.attrs_input, false))], 1)], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }