var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-data-table', _vm._b({
    attrs: {
      "mobile-breakpoint": "10000",
      "disable-filtering": "",
      "disable-pagination": "",
      "disable-sort": "",
      "hide-default-footer": "",
      "items-per-page": -1,
      "dense": ""
    },
    scopedSlots: _vm._u([{
      key: "item._ordererGroup",
      fn: function fn() {
        return [_c('div', {
          staticStyle: {
            "position": "relative"
          }
        }, [_c('autocomplete-user-group', {
          attrs: {
            "readonly": "",
            "dense": "",
            "hide-details": ""
          },
          model: {
            value: _vm.form._ordererGroup,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "_ordererGroup", $$v);
            },
            expression: "form._ordererGroup"
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "item._orderer",
      fn: function fn() {
        return [_c('div', {
          staticStyle: {
            "position": "relative"
          }
        }, [_c('autocomplete-user', {
          attrs: {
            "readonly": "",
            "dense": "",
            "hide-details": ""
          },
          model: {
            value: _vm.form._orderer,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "_orderer", $$v);
            },
            expression: "form._orderer"
          }
        })], 1)];
      },
      proxy: true
    }], null, true)
  }, 'v-data-table', {
    headers: _vm.headers,
    items: _vm.items
  }, false));
}
var staticRenderFns = []

export { render, staticRenderFns }