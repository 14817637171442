var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "max-width": "880",
      "persistent": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var attrs = _ref.attrs,
            on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs: attrs,
          on: on
        })];
      }
    }], null, true),
    model: {
      value: _vm.shows,
      callback: function callback($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(" 클레임 생성 "), _c('v-spacer'), _vm._v(" "), _c('v-icon', {
    on: {
      "click": function click($event) {
        _vm.shows = false;
      }
    }
  }, [_vm._v("mdi-close")])], 1), _c('v-divider'), _c('v-row', {
    staticClass: "ma-0 pa-2"
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "6",
      "md": ""
    }
  }, [_c('autocomplete-user-group', _vm._b({
    attrs: {
      "label": "발주자그룹"
    },
    model: {
      value: _vm.form._orderGroup,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "_orderGroup", $$v);
      },
      expression: "form._orderGroup"
    }
  }, 'autocomplete-user-group', _vm.attrs_input, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "6",
      "md": ""
    }
  }, [_c('autocomplete-user', _vm._b({
    attrs: {
      "params": {
        _group: _vm.form._ordererGroup
      },
      "disabled": !_vm.form._orderGroup,
      "label": "발주자"
    },
    model: {
      value: _vm.form._orderer,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "_orderer", $$v);
      },
      expression: "form._orderer"
    }
  }, 'autocomplete-user', _vm.attrs_input, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('autocomplete-purchase-order', _vm._b({
    ref: "autocomplete-purchase-order",
    attrs: {
      "params": {
        _orderer: _vm.form._orderer
      },
      "disabled": !_vm.form._orderer,
      "label": "구매주문"
    },
    model: {
      value: _vm.form._order,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "_order", $$v);
      },
      expression: "form._order"
    }
  }, 'autocomplete-purchase-order', _vm.attrs_input, false))], 1)], 1), _c('v-card', {
    attrs: {
      "flat": "",
      "disabled": !_vm.order
    }
  }, [_c('v-divider'), _c('table-info', {
    attrs: {
      "value": _vm.order
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_vm._v(" 기본 정보 "), _c('v-spacer'), _c('purchase-order-view', {
          attrs: {
            "item": _vm.order
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref2) {
              var attrs = _ref2.attrs,
                  on = _ref2.on;
              return [_c('v-icon', _vm._g(_vm._b({
                staticClass: "my-n1",
                attrs: {
                  "color": "secondary"
                }
              }, 'v-icon', attrs, false), on), [_vm._v("mdi-file-document-outline")])];
            }
          }])
        })];
      },
      proxy: true
    }])
  }), _c('table-products', {
    attrs: {
      "value": _vm.order,
      "selected": [_vm.form._product],
      "showsSelect": ""
    },
    on: {
      "select": _vm.selectProduct
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_vm._v(" 상품 선택 ")];
      },
      proxy: true
    }])
  }), _c('v-divider'), _c('v-row', {
    staticClass: "ma-0 pa-2"
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "클레임내용",
      "disabled": !_vm.form._product
    },
    model: {
      value: _vm.form.query,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "query", $$v);
      },
      expression: "form.query"
    }
  }, 'v-text-field', _vm.attrs_input, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "클레임처리",
      "disabled": !_vm.form._product
    },
    model: {
      value: _vm.form.reply,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "reply", $$v);
      },
      expression: "form.reply"
    }
  }, 'v-text-field', _vm.attrs_input, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "처리자",
      "disabled": !_vm.form._product
    },
    model: {
      value: _vm.form.handlerName,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "handlerName", $$v);
      },
      expression: "form.handlerName"
    }
  }, 'v-text-field', _vm.attrs_input, false))], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', _vm._b({
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, 'v-btn', {
    loading: _vm.loading
  }, false), [_vm._v("저장")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }