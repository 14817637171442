<template></template>

<script>
import { makeShippingMessage } from "@/assets/variables";
import XLSX from "xlsx";
import dayjs from 'dayjs';

const nameData = (row) => ({
    rawData: row,

    ["사이트"]: row?.A || "",
    ["출고지시일"]: row?.B || "",
    ["주문번호"]: row?.C || "",
    ["출점주문번호"]: row?.D || "",
    ["주문순번"]: row?.E || "",
    ["주문구분"]: row?.F || "",
    ["주문상태"]: row?.G || "",
    ["출고상태"]: row?.H || "",
    ["택배사"]: row?.I || "",
    ["송장번호"]: row?.J || "",
    ["배송기일"]: row?.K || "",
    ["배송수단"]: row?.L || "",
    ["배송유형"]: row?.M || "",
    ["배송상품"]: row?.N || "",
    ["선물하기여부"]: row?.O || "",
    ["선물포장여부"]: row?.P || "",
    ["상품번호"]: row?.Q || "",
    ["출점상품번호"]: row?.R || "",
    ["단품번호"]: row?.S || "",
    ["모델번호"]: row?.T || "",
    ["상품명"]: row?.U || "",
    ["단품명"]: row?.V || "",
    ["MD상품군"]: row?.W || "",
    ["브랜드"]: row?.X || "",
    ["판매가"]: row?.Y || "",
    ["주문수량"]: row?.Z || "",
    ["개인통관번호"]: row?.AA || "",
    ["주문자명"]: row?.AB || "",
    ["휴대폰번호"]: row?.AC || "",
    ["수취인명"]: row?.AD || "",
    ["수취인휴대폰번호"]: row?.AE || "",
    ["우편번호"]: row?.AF || "",
    ["배송지"]: row?.AG || "",
    ["배송메시지"]: row?.AH || "",
    ["보내는사람(메시지카드用)"]: row?.AI || "",
    ["받는사람(메시지카드用)"]: row?.AJ || "",
    ["매장메모내용"]: row?.AK || "",
    ["보내는사람(운송장)"]: row?.AL || "",
    ["선물메시지"]: row?.AM || "",
    ["스마트픽장소"]: row?.AN || "",
    ["픽업예정일"]: row?.AO || "",
    ["픽업완료일"]: row?.AP || "",
    ["처리번호"]: row?.AQ || "",
    ["처리구분"]: row?.AR || ""
});

const structureData = ({ rawData, ...data }) => {
    return async ({ getProductByCodeAlt }) => ({
        shippingMessage: makeShippingMessage({ orderer: { name: data["주문번호"] } }),

        sender: {
            name: data["주문자명"],
            phone: data["휴대폰번호"],
            address: "경기도 안성시 대덕면 모산로 394",
        },

        receiver: {
            name: data["수취인명"],
            phone: data["수취인휴대폰번호"],
            postcode: null,
            address1: data["배송지"],
            address2: null,
        },

        products: [
            {
                name: data["모델번호"],
                codeAlt: data["상품번호"],
                quantity: data["주문수량"],
                ...((await getProductByCodeAlt(data["상품번호"])) || {}),
            },
        ],

        targetsAt: null,

        rawData,
    });
};

export default {
    props: {
        file: { type: File, default: null },
        value: { type: Array, default: () => [] }, // items
        getProductByCodeAlt: { type: Function, default: () => () => {} },
    },
    data: () => ({
        items: [],
    }),
    mounted() {
        this.processFile();
    },
    watch: {
        file() {
            this.processFile();
        },
    },
    methods: {
        processFile() {
            if (this.file) {
                try {
                    this.$emit("loading", true);

                    let reader = new FileReader();
                    reader.onload = async () => {
                        let workBook = XLSX.read(reader.result, { type: "binary" });
                        let workSheets = workBook.SheetNames.map((sheetName) => XLSX.utils.sheet_to_json(workBook.Sheets[sheetName], { range: 2, header: "A" }));
                        this.items = await Promise.all(workSheets[0].map(nameData).map(structureData).map(this.makeData));

                        this.$emit("loading", false);
                        this.$emit("input", this.items);
                    };
                    reader.readAsBinaryString(this.file);
                } catch (error) {}
            }
        },
        async makeData(callback) {
            let { getProductByCodeAlt } = this;
            return callback({ getProductByCodeAlt });
        },
    },
};
</script>

<style>
</style>