<template>
    <v-dialog v-model="shows" max-width="480">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on, disabled, title }" />
        </template>
        <v-card>
            <v-card-title>{{ title }}</v-card-title>
            <v-card-text class="py-0">
                <v-row>
                    <v-col cols="6"> <autocomplete-user-group v-model="form._ordererGroup" label="주문자그룹" v-bind="attrs_input" @input="form._orderer = null" ref="autocomplete-user-group" /> </v-col>
                    <v-col cols="6"> <autocomplete-user v-model="form._orderer" label="주문자" :disabled="!form?._ordererGroup" v-bind="{ ...attrs_input, params }" ref="autocomplete-user" /> </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions class="justify-end">
                <v-btn text color="primary" @click="close">취소</v-btn>
                <v-btn text color="primary" v-bind="{ disabled }" @click="save">변경</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { attrs_input } from "@/assets/variables";

import AutocompleteUser from "@/components/console/dumb/autocomplete-user.vue";
import AutocompleteUserGroup from "@/components/console/dumb/autocomplete-user-group.vue";

export default {
    components: {
        AutocompleteUser,
        AutocompleteUserGroup,
    },
    props: {
        title: { type: String, default: "선택 주문자 변경" },
        selected: { type: Array, default: () => [] },
    },
    data: () => ({
        shows: false,

        form: {
            _orderer: null,
            _ordererGroup: null,
        },

        attrs_input,
    }),
    computed: {
        params() {
            return { _group: this.form?._ordererGroup };
        },
        disabled() {
            return !this.selected.length;
        },
    },
    methods: {
        close() {
            this.shows = false;
        },
        async save() {
            let { _orderer, _ordererGroup } = this.form;
            let orderer = this.$refs["autocomplete-user"]?.item;
            let ordererGroup = this.$refs["autocomplete-user-group"]?.item;

            let items = this.selected.map((item) => ({ ...item, _orderer, _ordererGroup, orderer, ordererGroup }));

            this.$emit("updateItems", items);
            this.close();
        },
    },
};
</script>

<style>
</style>