var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "width": "880"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var attrs = _ref.attrs,
            on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs: attrs,
          on: on
        })];
      }
    }], null, true),
    model: {
      value: _vm.shows,
      callback: function callback($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v("구매주문 상세 "), _c('v-spacer'), _vm._v(" "), _c('v-icon', {
    on: {
      "click": function click($event) {
        _vm.shows = false;
      }
    }
  }, [_vm._v("mdi-close")])], 1), _c('v-divider'), _c('v-card-text', {
    staticClass: "pa-0",
    staticStyle: {
      "max-height": "calc(90vh - 16px - 32px - 10px - 1px)",
      "overflow-y": "auto"
    }
  }, [_c('table-info', {
    attrs: {
      "value": _vm.item
    }
  }), _c('table-sender', {
    attrs: {
      "value": _vm.item
    }
  }), _c('table-files', _vm._g({
    attrs: {
      "value": _vm.item
    }
  }, {
    save: _vm.save
  })), _c('table-receiver', {
    attrs: {
      "value": _vm.item
    }
  }), _c('table-shipping-message', {
    attrs: {
      "value": _vm.item
    }
  }), _c('table-products', {
    attrs: {
      "value": _vm.item
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }