<template>
    <list-search v-bind="{ showsSearch, loading }" v-on="{ search }">
        <v-row>
            <v-col cols="6" md="4">
                <v-text-field v-model="query.createdAt[0]" type="date" label="생성일(시작)" clearable v-bind="attrs_input" />
            </v-col>
            <v-col cols="6" md="4">
                <v-text-field v-model="query.createdAt[1]" type="date" label="생성일(종료)" clearable v-bind="attrs_input" />
            </v-col>
            <v-col cols="6" md="4">
                <autocomplete-user-group v-model="query._group" label="그룹" placeholder="전체" clearable v-bind="attrs_input" />
            </v-col>
            <v-col col="6" md="4">
                <v-select v-model="query.searchKey" :items="searchKeys" item-text="text" item-value="value" label="검색대상" placeholder="전체" clearable v-bind="attrs_input" />
            </v-col>
            <v-col col="6" md="4">
                <v-text-field v-model="query.searchValue" label="검색어" clearable v-bind="attrs_input" @keydown.enter="search" />
            </v-col>
        </v-row>
    </list-search>
</template>

<script>
import { attrs_input } from "@/assets/variables";

import ListSearch from "@/components/console/dumb//list-search.vue";
import AutocompleteUserGroup from "@/components/console/dumb/autocomplete-user-group.vue";

const initQuery = () => ({
    createdAt: [null, null],
    _group: null,
    searchKey: null,
    searchValue: null,
});

const searchKeys = [
    { text: "지점명", value: "name" },
    { text: "아이디", value: "username" },
];

export default {
    components: {
        ListSearch,
        AutocompleteUserGroup,
    },
    props: {
        loading: { type: Boolean, default: false },
        showsSearch: { type: Boolean, default: false },
    },
    data: () => ({
        searchKeys,
        attrs_input,

        query: initQuery(),
    }),
    mounted() {
        this.sync();
    },
    watch: {
        "$route.path"() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.query = {
                _group: this.$route.query?._group || null,
                createdAt: JSON.parse(JSON.stringify(this.$route.query?.createdAt || [null, null])),
                searchKey: this.$route.query?.searchKey || null,
                searchValue: this.$route.query?.searchValue || null,
            };
        },
        search() {
            let { ...query } = this.query;

            if (!query._group) delete query._group;
            if (!query.searchKey) delete query.searchKey;
            if (!query.searchValue) delete query.searchValue;

            query.page = 1;

            this.$router.push({ query });
        },
    },
};
</script>

<style></style>
