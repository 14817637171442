<template>
    <v-dialog v-model="shows" width="400" persistent>
        <template v-slot:activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card>
            <v-card-title> 수동 발주 <v-spacer /> <v-icon @click="shows = false">mdi-close</v-icon> </v-card-title>
            <v-divider />
            <v-tabs-items v-model="tab" style="max-height: calc(90vh - 58px - 1px); overflow-y: auto">
                <v-tab-item value="select-user">
                    <card-select-user
                        v-model="form"
                        v-on="{
                            forward: () => (tab = 'select-type'),
                        }"
                    />
                </v-tab-item>
                <v-tab-item value="select-type">
                    <card-select-type
                        v-model="form"
                        v-bind="{ isBulkOrder }"
                        v-on="{
                            forward: () => (tab = 'input-receivers'),
                        }"
                    />
                </v-tab-item>
                <v-tab-item value="input-receivers">
                    <card-input-receivers
                        v-model="orders"
                        v-bind="{ isBulkOrder }"
                        v-on="{
                            forward: () => (tab = 'select-products'),
                        }"
                    />
                </v-tab-item>
                <v-tab-item value="select-products">
                    <card-select-products
                        v-model="orders"
                        v-bind="{ form, isBulkOrder }"
                        v-on="{
                            backward: () => (tab = 'input-receivers'),
                            forward: () => (tab = 'attach-files'),
                        }"
                    />
                </v-tab-item>
                <v-tab-item value="attach-files">
                    <card-attach-files
                        v-model="form"
                        v-on="{
                            backward: () => (tab = 'select-products'),
                            forward: () => (tab = 'confirm-data'),
                        }"
                    />
                </v-tab-item>
                <v-tab-item value="confirm-data">
                    <card-confirm-data
                        v-bind="{ form, orders, isBulkOrder }"
                        v-on="{
                            forward: post,
                        }"
                    />
                </v-tab-item>
            </v-tabs-items>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import dayjs from "dayjs";
import { initReceiver } from "@/lib";
import { PURCHASE_ORDER_TYPES } from "@/assets/variables";

import CardSelectUser from "@/components/console/dumb/card-select-user.vue";
import CardSelectType from "./card-select-type.vue";
import CardAttachFiles from "./card-attach-files.vue";
import CardConfirmData from "./card-confirm-data.vue";
import CardInputReceivers from "./card-input-receivers.vue";
import CardSelectProducts from "./card-select-products.vue";

let initialTab = "select-user";

let initForm = () => ({
    _orderer: null,
    _ordererGroup: null,

    type: undefined,
    message: null,
    targetsAt: null,

    _receipt: undefined,
    _businessCard: undefined,
    _greetingCard: undefined,

    receipt: null,
    businessCard: null,
    greetingCard: null,
});

export default {
    components: {
        CardSelectUser,
        CardSelectType,
        CardAttachFiles,
        CardConfirmData,
        CardInputReceivers,
        CardSelectProducts,
    },
    data: () => ({
        tab: initialTab,
        shows: false,
        loading: false,

        form: initForm(),
        orders: [
            {
                tempId: Math.random(),
                receiver: initReceiver(),
                products: [],
            },
        ],
    }),
    computed: {
        isBulkOrder() {
            return this.form.type == PURCHASE_ORDER_TYPES.ADMIN_MANUAL_ORDER_BULK.value;
        },
        targetsAt() {
            return this.form.targetsAt;
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        shows() {
            this.init();
        },
    },
    methods: {
        init() {
            if (!this.shows)
                setTimeout(() => {
                    this.tab = initialTab;
                    this.form = initForm();
                }, 500);
        },
        async post() {
            this.loading = true;

            try {
                const _manager = this.$store.state.payload?._user;
                let { receipt, businessCard, greetingCard, ...form } = this.form;

                if (receipt) {
                    receipt = (await api.v1.me.purchaseOrders.files.post(receipt))?.file;
                    form._receipt = receipt._id;
                } else delete form._receipt;

                if (businessCard) {
                    businessCard = (await api.v1.me.purchaseOrders.files.post(businessCard))?.file;
                    form._businessCard = businessCard._id;
                } else delete form._businessCard;

                if (greetingCard) {
                    greetingCard = (await api.v1.me.purchaseOrders.files.post(greetingCard))?.file;
                    form._greetingCard = greetingCard._id;
                } else delete form._greetingCard;

                let targetsAt = dayjs().add(1, "day").format("YYYY-MM-DD");
                if (this.isBulkOrder) {
                    for (let { receiver, products } of this.orders) {
                        await api.console.shop.purchaseOrders.post({ _manager, ...form, receiver, products, targetsAt });
                    }
                } else {
                    let { receiver, products } = this.orders[0] || {};
                    await api.console.shop.purchaseOrders.post({ _manager, ...form, receiver, products, targetsAt });
                }

                alert("발주가 완료되었습니다.");
                this.shows = false;
                this.$emit("input");
            } catch (error) {
                console.error(error);
                alert(error?.message || "오류가 발생했습니다.");
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style></style>
