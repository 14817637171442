<template>
    <v-data-table  v-if="[ 'CUSTOMER_ORDER_BY_UNIT', 'CUSTOMER_ORDER_BY_BULK', 'ADMIN_MANUAL_ORDER_BULK', 'ADMIN_MANUAL_ORDER_UNIT' ].includes(this.value.type)" v-bind="{ headers, items }" dense disable-filtering disable-sort hide-default-footer class="transparent">
        <template #top> <v-card-subtitle class="font-weight-bold" style="background-color: rgba(0, 0, 0, 0.05)">첨부 파일</v-card-subtitle> <v-divider /> </template>
        <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>
        <template #[`item.receipt`]="{ value }">
            <template v-if="value">
                <v-btn small text icon tile :href="value.src" target="_blank"> <v-icon>mdi-image</v-icon> </v-btn>
                <v-btn small text icon tile :href="value.src" download> <v-icon>mdi-download</v-icon> </v-btn>
                <v-btn small text icon tile @click="remove('receipt')"> <v-icon>mdi-close</v-icon> </v-btn>
            </template>
            <template v-else>
                <v-btn small text icon tile @click="inputOpen('receipt')"><v-icon>mdi-upload</v-icon></v-btn>
                <v-file-input ref="receipt" class="d-none" @change="(file) => upload(file, 'receipt')"/>
            </template>
        </template>
        <template #[`item.businessCard`]="{ value }">
            <template v-if="value">
                <v-btn small text icon tile :href="value.src" target="_blank"> <v-icon>mdi-image</v-icon> </v-btn>
                <v-btn small text icon tile :href="value.src" download> <v-icon>mdi-download</v-icon> </v-btn>
                <v-btn small text icon tile @click="remove('businessCard')"> <v-icon>mdi-close</v-icon> </v-btn>
            </template>
            <template v-else>
                <v-btn small text icon tile @click="inputOpen('businessCard')"> <v-icon>mdi-upload</v-icon> </v-btn>
                <v-file-input ref="businessCard" class="d-none" @change="(file) => upload(file, 'businessCard')"/>
            </template>
        </template>
        <template #[`item.greetingCard`]="{ value }">
            <template v-if="value">
                <v-btn small text icon tile :href="value.src" target="_blank"> <v-icon>mdi-image</v-icon> </v-btn>
                <v-btn small text icon tile :href="value.src" download> <v-icon>mdi-download</v-icon> </v-btn>
                <v-btn small text icon tile @click="remove('greetingCard')"> <v-icon>mdi-close</v-icon> </v-btn>
            </template>
            <template v-else>
                <v-btn small text icon tile @click="inputOpen('greetingCard')"> <v-icon>mdi-upload</v-icon> </v-btn>
                <v-file-input ref="greetingCard" class="d-none" @change="(file) => upload(file, 'greetingCard')"/>
            </template>
        </template>
        <template #footer> <v-divider /> </template>
    </v-data-table>
</template>

<script>
import api from "@/api";
import ImagePopup from "../../dumb/image-popup.vue";

const headers = [
    { text: "영수증", value: "receipt" },
    { text: "명함", value: "businessCard" },
    { text: "인사장", value: "greetingCard" },
    { text: "메시지", value: "message", formatter: (value) => value || "-" },
].map((item, index, array) => ({ ...item, divider: index != array.length - 1, align: item.align ?? "center", cellClass: item.cellClass || "caption", formatter: item.formatter ?? ((value) => value ?? "-") }));

export default {
    components: {
        ImagePopup,
    },
    props: {
        value: { type: Object, default: () => ({}) }, // order
    },
    data: () => ({
        headers,
    }),
    methods: {
        inputOpen(refs) {
            this.$refs[`${refs}`].$el.getElementsByTagName('input')[0].click()
        },
        async upload(files, code) {
            let { file } = await api.console.shop.purchaseOrders.files.post(files);
            this.$emit("save", file, code)
        },
        remove(code) {
            this.$emit("save", null, code)
        }
    },
    computed: {
        items() {
            let items = [];
            let { receipt, businessCard, greetingCard, message } = this.value;
            if (receipt || !receipt) {
                if (!items[0]) items[0] = {};
                items[0] = { ...items[0], receipt };
            }
            if (businessCard || !businessCard) {
                if (!items[0]) items[0] = {};
                items[0] = { ...items[0], businessCard };
            }
            if (greetingCard || !greetingCard) {
                if (!items[0]) items[0] = {};
                items[0] = { ...items[0], greetingCard };
            }
            if (message || !message) {
                if (!items[0]) items[0] = {};
                items[0] = { ...items[0], message };
            }
            return items;
        },
    },
};
</script>

<style>
</style>