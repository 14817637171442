<template>
    <v-data-table v-bind="{ headers, items }" dense disable-filtering disable-sort hide-default-footer class="transparent">
        <template #top>
            <v-card-subtitle class="d-flex font-weight-bold" style="background-color: rgba(0, 0, 0, 0.05)"> <slot v-if="$slots['title']" name="title" /> <span v-else>기본 정보</span> </v-card-subtitle>
            <v-divider />
        </template>
        <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>
        <template #footer> <v-divider /> </template>
    </v-data-table>
</template>

<script>
import { PURCHASE_ORDER_TYPES } from "@/assets/variables";

const headers = [
    { width: 140, text: "발주번호", value: "number" },
    { width: 120, text: "입고/발송일", value: "targetsAt", formatter: (value) => value?.toDate?.() || value || "-" },
    { width: +90, text: "발주유형", value: "type", formatter: (value) => PURCHASE_ORDER_TYPES[value]?.textShorten || "-" },
    { width: 140, text: "주문자그룹", value: "ordererGroup.name" },
    { width: 140, text: "주문자", value: "orderer.username" },
    { width: +90, text: "주문상태", value: "stateText" },
    { width: 160, text: "발주일시", value: "createdAt", formatter: (value) => value?.toDateTime?.() || value || "-" },
].map((item, index, array) => ({ ...item, divider: index != array.length - 1, align: item.align ?? "center", cellClass: item.cellClass || "caption", formatter: item.formatter ?? ((value) => value ?? "-") }));

export default {
    props: {
        value: { type: Object, default: () => ({}) }, // order
    },
    data: () => ({
        headers,
    }),
    computed: {
        items() {
            return [this.value];
        },
    },
};
</script>

<style>
</style>