var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "kcp-cert",
    attrs: {
      "id": "kcp-cert"
    }
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.show,
      expression: "show"
    }],
    ref: "screen",
    staticClass: "screen",
    staticStyle: {
      "display": "none"
    }
  }, [_c('iframe', {
    ref: "iframe",
    attrs: {
      "width": "100%",
      "height": "700",
      "frameborder": "0",
      "scrolling": "true"
    }
  })]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.show,
      expression: "show"
    }],
    ref: "progress",
    staticClass: "progress",
    staticStyle: {
      "display": "none"
    }
  }, [_c('div', {
    staticClass: "spinner"
  })]), _vm._t("activator", null, {
    "on": {
      click: _vm.auth
    }
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }