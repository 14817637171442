<template>
    <v-data-table v-model="selected" v-bind="{ items, headers, loading, showSelect }" fixed-header item-key="tempId" :items-per-page="15" height="calc(100% - 59px)">
        <template #no-data>
            <v-overlay absolute light color="white" class="black--text">
                <v-card-title> 데이터가 없거나 양식이 잘못되었습니다. </v-card-title>
            </v-overlay>
        </template>
        <template v-for="header in headers" #[`item.${header.value}`]="{ value }">
            <v-tooltip v-if="value" :key="header.value" bottom>
                <template #activator="{ attrs, on }">
                    <span v-bind="attrs" v-on="on">{{ value }}</span>
                </template>
                <span>{{ value }}</span>
            </v-tooltip>
            <span v-else :key="header.value"> - </span>
        </template>
        <template #foot>
            <tfoot>
                <td :colspan="headers.length + 1">
                    <v-divider />
                </td>
            </tfoot>
        </template>
        <template #[`item.orderer`]="{ value }">
            <div v-if="value">
                <div v-if="value?.group?.name" class="caption">{{ value?.group?.name || "" }}</div>
                {{ value?.name || "" }}
            </div>
            <div v-else>-</div>
        </template>
        <template #[`footer.prepend`]>
            <div style="margin: 0 auto 0 0" v-if="excel != 'invoice'">
                <dialog-excel-upload-orderer-input v-bind="{ selected }" v-on="{ updateItems }">
                    <template #activator="{ attrs, on, disabled, title }">
                        <v-fade-transition>
                            <v-btn v-show="isBulk" text tile color="primary" v-bind="{ ...attrs, disabled, loading }" v-on="on">{{ title }}</v-btn>
                        </v-fade-transition>
                    </template>
                </dialog-excel-upload-orderer-input>
                <dialog-excel-upload-orderer-input title="전체 주문자 변경" :selected="value" v-on="{ updateItems }">
                    <template #activator="{ attrs, on, disabled, title }">
                        <v-fade-transition>
                            <v-btn v-show="isBulk" text tile color="primary" v-bind="{ ...attrs, disabled, loading }" v-on="on">{{ title }}</v-btn>
                        </v-fade-transition>
                    </template>
                </dialog-excel-upload-orderer-input>
                <dialog-excel-upload-targets-input title="선택 입고/발송일 변경" v-bind="{ selected }" v-on="{ updateItems }">
                    <template #activator="{ attrs, on, disabled, title }">
                        <v-fade-transition>
                            <v-btn text tile color="primary" v-bind="{ ...attrs, disabled, loading }" v-on="on">{{ title }} </v-btn>
                        </v-fade-transition>
                    </template>
                </dialog-excel-upload-targets-input>
            </div>
        </template>
    </v-data-table>
</template>

<script>
import { PURCHASE_ORDER_TYPES } from "@/assets/variables";

import DialogExcelUploadOrdererInput from "./dialog-excel-upload-orderer-input.vue";
import DialogExcelUploadTargetsInput from "./dialog-excel-upload-targets-input.vue";

export default {
    components: {
        DialogExcelUploadOrdererInput,
        DialogExcelUploadTargetsInput,
    },
    props: {
        type: { type: String },
        excel: { type: String },
        value: { type: Array, default: () => [] }, // orders
        loading: { type: Boolean, default: false },
    },
    data: () => ({
        selected: [],
    }),
    computed: {
        items() {
            return this.value.map((item) => ({ ...item, receiver: { ...item.receiver, address: `${item?.receiver?.address1 || ""} ${item?.receiver?.address2 || ""}`.trim() } }));
        },
        headers() {
            return [
                { width: 140, text: "주문자", value: "orderer", divider: true, hide: !this.isBulk || !this.showSelect },
                { width: 120, text: "보내는사람\n(지정)", value: "sender.name" },
                { width: 140, text: "전화번호1\n(지정)", value: "sender.phone", divider: true },
                // { width: 180, text: "주소\n(지정)", value: "sender.address", divider: true },
                { width: 120, text: "받는사람", value: "receiver.name" },
                { width: 140, text: "전화번호1", value: "receiver.phone" },
                { width: 100, text: "우편번호", value: "receiver.postcode" },
                { width: 290, text: "주소", value: "receiver.address", divider: true },
                { width: 100, text: "수량\n(A타입)", value: "products[0].quantity" },
                { width: 160, text: "상품명1", value: "products[0].code" },
                // { width: 200, text: "배송메시지", value: "message" },
                { width: 290, text: "상품명(표기)", value: "products[0].name", divider: true, hide: this.type == PURCHASE_ORDER_TYPES.ADMIN_MANUAL_ORDER_BULK.value },
                { width: 160, text: "주문상태", value: "stateText", hide: !this.isBulk },
                { width: 290, text: "입고/발송일", value: "targetsAt", hide: this.excel == "invoice" },
            ]
                .filter(({ hide }) => !hide)
                .map((item) => ({
                    ...item,
                    class: `${item?.class || ""} white-space-pre-line`.trim(),
                    cellClass: "max-width-0 text-truncate",
                }));
        },
        isBulk() {
            return this.type == PURCHASE_ORDER_TYPES.ADMIN_MANUAL_ORDER_BULK.value;
        },
        showSelect() {
            return this.excel != "invoice";
        },
    },
    watch: {
        value() {
            this.selected = [];
        },
    },
    methods: {
        updateItems(items) {
            let orders = [...this.value];
            for (const order of items) {
                const index = orders.findIndex(({ tempId }) => tempId == order.tempId);
                if (0 <= index) orders.splice(index, 1, order);
                else orders = [order, ...orders];
            }
            this.$emit("input", orders);
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .v-data-table__wrapper {
        flex: 1 0 auto;
    }
    .v-data-footer {
        background-color: white !important;
        margin-right: 0 !important;
        margin-top: -1px !important;
    }
    .white-space-pre-line {
        white-space: pre-line;
    }
    .max-width-0 {
        max-width: 0 !important;
    }
}
</style>