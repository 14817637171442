var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', [!_vm.readonly ? [_c('v-card-subtitle', {
    staticClass: "px-6"
  }, [_vm._v(" 파일을 첨부하세요 ")]), _c('v-divider')] : _vm._e(), _c('v-row', {
    staticClass: "ma-0 pa-3"
  }, [_c('v-col', {
    staticClass: "pa-1",
    attrs: {
      "cols": "12"
    }
  }, [_c('image-input-btn', _vm._b({
    attrs: {
      "title": "영수증",
      "large": "",
      "outlined": "",
      "block": "",
      "color": "primary",
      "accept": "image/*"
    },
    model: {
      value: _vm.form.receipt,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "receipt", $$v);
      },
      expression: "form.receipt"
    }
  }, 'image-input-btn', {
    readonly: _vm.readonly
  }, false), [_c('v-icon', {
    staticClass: "mr-10"
  }, [_vm._v("mdi-camera-enhance")])], 1)], 1), _c('v-col', {
    staticClass: "pa-1",
    attrs: {
      "cols": "6"
    }
  }, [_c('image-input-btn', _vm._b({
    attrs: {
      "title": "명함",
      "large": "",
      "outlined": "",
      "block": "",
      "color": "primary",
      "accept": "image/*"
    },
    model: {
      value: _vm.form.businessCard,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "businessCard", $$v);
      },
      expression: "form.businessCard"
    }
  }, 'image-input-btn', {
    readonly: _vm.readonly
  }, false))], 1), _c('v-col', {
    staticClass: "pa-1",
    attrs: {
      "cols": "6"
    }
  }, [_c('image-input-btn', _vm._b({
    attrs: {
      "title": "인사장",
      "large": "",
      "outlined": "",
      "block": "",
      "color": "primary",
      "accept": "image/*"
    },
    model: {
      value: _vm.form.greetingCard,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "greetingCard", $$v);
      },
      expression: "form.greetingCard"
    }
  }, 'image-input-btn', {
    readonly: _vm.readonly
  }, false))], 1)], 1), !_vm.readonly ? [_c('v-divider'), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "x-large": "",
      "text": "",
      "block": "",
      "color": "primary"
    },
    on: {
      "click": _vm.emit
    }
  }, [_vm._v("첨부완료")])], 1)] : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }