<template>
    <span v-if="readonly"> {{ item?.name || "-" }} </span>
    <v-autocomplete v-else v-model="$data._group" v-bind="{ ...$attrs, items, itemText, itemValue: '_id' }" @click="alert" />
</template>

<script>
import api from "@/api";

export default {
    props: {
        value: { type: [String, Array], default: null },
        readonly: { type: Boolean, default: false },
    },
    data: () => ({
        _group: null,
        items: [],
    }),
    computed: {
        item() {
            return this.items.find(({ _id }) => _id == this.$data._group);
        },
    },
    mounted() {
        this.sync();
        this.init();
    },
    watch: {
        value() {
            this.sync();
        },
        "$data._group"(_group) {
            this.$emit("input", _group);
        },
    },
    methods: {
        sync() {
            this.$data._group = this.value;
        },
        async init() {
            this.items = (await api.console.users.groups.gets())?.groups || [];
        },
        itemText({ name }) {
            return `${name}`;
        },
        alert() {
            if (this.$attrs.readonly && this.$attrs.readonlyMessage) alert(this.$attrs.readonlyMessage);
            if (this.$attrs.disabled && this.$attrs.disabledMessage) alert(this.$attrs.readonlyMessage);
        },
    },
};
</script>

<style></style>
