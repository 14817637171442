<template>
    <v-data-table v-bind="{ items, headers }" :mobile-breakpoint="10000" height="262" hide-default-footer disable-filtering disable-pagination disable-sort class="v-sheet--outlined" style="position: relative">
        <template #top>
            <div class="table-label px-1 white"><span> 선택 항목 보내시는분 </span></div>
        </template>
        <template #[`item.total`]> {{ selected.length }} </template>
    </v-data-table>
</template>

<script>
export default {
    props: {
        selected: { type: Array, default: () => [] },
    },
    computed: {
        items() {
            return [
                this.selected.reduce((o, { sender }) => {
                    const { name, phone } = sender || {};

                    let key = "";
                    if (name) key += `${name} /`;
                    if (phone) key += ` ${phone}`;
                    if (!key) key = "-";

                    return { ...o, [key]: (o[key] || 0) + 1 };
                }, {}),
            ];
        },
        headers() {
            return Object.keys(this.items[0])
                .sort((a, b) => (a < b ? -1 : a > b ? 1 : 0))
                .map((text) => ({ text, value: text }))
                .concat({ text: "합계", value: "total" });
        },
    },
};
</script>

<style lang="scss" scoped>
.table-label {
    position: absolute;
    left: 12px;
    bottom: 100%;
    transform: translateY(50%);

    color: rgba(0, 0, 0, 0.6);
    font-size: 12px;
}
::v-deep {
    .v-data-table__mobile-row {
        padding: 4px 16px !important;
        &,
        & * {
            height: auto !important;
            min-height: auto;
            font-size: 12px;
            font-weight: normal;
            white-space: pre-line;
        }
        &:first-child {
            margin-top: 12px;
        }
        &:last-child {
            margin-bottom: 12px;
        }
    }
}
</style>
