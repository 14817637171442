<template>
    <v-layout justify-center>
        <v-responsive max-width="1520" width="100%" height="100%" class="px-3 mx-n3">
            <list-heading title="클레임 목록" showsFilterButton v-model="showsSearch" />
            <claim-list-search v-bind="{ showsSearch }" />

            <v-row no-gutters class="mr-n2">
                <v-col cols="" md="auto" class="mb-2 mr-2">
                    <claim-list-state-toggle />
                </v-col>
                <v-spacer />
                <v-col cols="" md="auto" class="mb-2 mr-2">
                    <dialog-claim-input @input="updateItem">
                        <template #activator="{ attrs, on }">
                            <v-sheet outlined rounded>
                                <v-btn block color="white primary--text" v-bind="attrs" v-on="on">
                                    <v-icon class="mr-1">mdi-note-alert-outline</v-icon>
                                    클레임 추가
                                </v-btn>
                            </v-sheet>
                        </template>
                    </dialog-claim-input>
                </v-col>
            </v-row>

            <v-data-table v-bind="{ headers, items }" disable-filtering disable-sort disable-pagination hide-default-footer :items-per-page="-1" class="v-sheet--outlined">
                <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>
                <template #[`item.orderNumber`]="{ item, value }">
                    <div class="d-flex align-center px-4">{{ value }}</div>
                    <to-purchase-order :order="item.order">
                        <template #activator="{ to }">
                            <v-btn text icon tile color="primary" v-bind="{ to }"> <v-icon size="18">mdi-link-variant</v-icon> </v-btn>
                        </template>
                    </to-purchase-order>
                </template>
                <template #[`item.reply`]="{ item }">
                    <v-edit-dialog large @save="update({ _id: item._id, _handler, reply: item.reply, handlerName: item.handlerName, repliedAt: Date.now() })" cancel-text="취소" save-text="답변하기">
                        <v-icon>mdi-message-reply-text</v-icon>
                        <template #input>
                            <v-text-field v-model="item.reply" label="처리" v-bind="attrs_input" class="my-3" />
                            <v-text-field v-model="item.handlerName" label="처리자" v-bind="attrs_input" class="my-3" />
                        </template>
                    </v-edit-dialog>
                </template>
            </v-data-table>
            <v-pagination :value="page" :length="pageCount" :total-visible="11" color="primary" class="mt-2" @input="(page) => $router.push({ query: { ...$route.query, page } })"></v-pagination>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { attrs_input, PURCHASE_ORDER_STATES, PURCHASE_ORDER_TYPES } from "@/assets/variables";

import ListHeading from "@/components/console/dumb/list-heading.vue";
import ToPurchaseOrder from "@/components/console/shop/purchase-orders/to-purchase-order.vue";
import ClaimListSearch from "@/components/console/shop/purchase-order-claims/claim-list-search.vue";
import ClaimListStateToggle from "@/components/console/shop/purchase-order-claims/claim-list-state-toggle.vue";
import DialogClaimInput from "@/components/console/shop/purchase-order-claims/dialog-claim-input.vue";

const states = Object.values(PURCHASE_ORDER_STATES);

const defualtFormatter = (value) => value ?? "-";

const headers = [
    { width: 170, text: "발주번호", value: "orderNumber", divider: true, cellClass: "d-flex justify-space-between align-center pa-0" },
    { width: 110, text: "발주일시", value: "order.createdAt", formatter: (value) => value?.toDateTime?.(), divider: true },
    { width: 110, text: "입고/발송일", value: "order.targetsAt", divider: true, formatter: (value) => value?.toDate?.() || value || "-" },
    { width: 100, text: "발주유형", value: "order.type", divider: true, formatter: (value) => PURCHASE_ORDER_TYPES[value]?.textShorten || "-" },
    { width: 160, text: "상품명", value: "product.name", divider: true },
    { width: 320, text: "클레임내용", value: "query", divider: true },
    { width: 120, text: "발주자", value: "orderer.username", divider: true },
    { width: 110, text: "클레임일시", value: "createdAt", formatter: (value) => value?.toDateTime?.(), divider: true },
    { width: 110, text: "클레임처리", value: "repliedAt", divider: true, formatter: (value) => value?.toDateTime?.() || value || "미처리", cellClass: "white-space-pre-line" },
    { width: 120, text: "처리자", value: "handlerName", divider: true },
    { width: +60, text: "", value: "reply", align: "center" },
].map((item) => ({ ...item, formatter: item.formatter ?? defualtFormatter }));

export default {
    components: {
        ListHeading,
        ToPurchaseOrder,
        ClaimListSearch,
        ClaimListStateToggle,
        DialogClaimInput,
    },
    data() {
        return {
            showsSearch: true,

            claims: [],

            limit: 10,
            summary: { totalCount: 0 },

            states,
            headers,
            attrs_input,
        };
    },
    computed: {
        items() {
            return this.claims.map((item, index) => ({ index: this.summary.totalCount - (this.page - 1) * this.limit - index, ...item }));
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { query } = this.$route;
            return { ...query };
        },
        _handler() {
            return this.$store.state.payload._user;
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.search();
        },
    },
    methods: {
        async init() {
            try {
                this.search();
            } catch (error) {
                console.error(error);
            }
        },

        async search() {
            try {
                let { skip, limit, params } = this;
                var { summary, claims } = await api.console.shop.purchaseOrderClaims.gets({
                    headers: { skip, limit },
                    params,
                });

                this.claims = claims;
                this.summary = summary;
            } catch (error) {
                this.$handleError(error);
            }
        },

        async update(claim) {
            try {
                claim = (await api.console.shop.purchaseOrderClaims.put(claim))?.claim;
                this.updateItem(claim);
            } catch (error) {
                this.$handleError(error);
            }
        },

        updateItem(claim) {
            const index = this.claims.findIndex(({ _id }) => _id == claim._id);
            if (0 <= index) this.claims.splice(index, 1, claim);
            else {
                this.claims = [claim, ...this.claims];
                this.summary.totalCount += 1;
            }
            this.search();
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .max-width-0 {
        max-width: 0;
    }
    .white-space-pre-line {
        white-space: pre-line;
    }
    .v-pagination button {
        box-shadow: none !important;
        border: thin solid rgba(0, 0, 0, 0.12);
    }
}
</style>