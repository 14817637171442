<template></template>

<script>
import XLSX from "xlsx";
import dayjs from 'dayjs';

const nameData = (row) => ({
    rawData: row,

    ["택배상품구분"]: row?.A || "",
    ["배송상태"]: row?.B || "",
    ["매입점코드"]: row?.C || "",
    ["매입점포명"]: row?.D || "",
    ["상품코드"]: row?.E || "",
    ["상품명"]: row?.F || "",
    ["센터명"]: row?.G || "",
    ["신청일자"]: row?.H || "",
    ["출고일자"]: row?.I || "",
    ["매입일자"]: row?.J || "",
    ["판매가"]: row?.K || "",
    ["최소수량"]: row?.L || "",
    ["증정수량"]: row?.M || "",
    ["주문수량"]: row?.N || "",
    ["총수량"]: row?.O || "",
    ["보낸사람 성명"]: row?.P || "",
    ["보낸사람 연락처"]: row?.Q || "",
    ["보낸사람 우편번호"]: row?.R || "",
    ["보낸사람주소"]: row?.S || "",
    ["받는사람성명"]: row?.T || "",
    ["연락처1"]: row?.U || "",
    ["연락처2"]: row?.V || "",
    ["받는사람 우편번호"]: row?.W || "",
    ["받는사람 주소"]: row?.X || "",
    ["택배회사"]: row?.Y || "",
    ["송장번호"]: row?.Z || "",
    ["거래처메모"]: row?.AA || "",
    ["요청사항"]: row?.AB || "",
});

const structureData = ({ rawData, ...data }) => {
    return async ({ getProductByCodeAlt }) => ({
        sender: {
            name: data["보낸사람 성명"],
            phone: data["보낸사람 연락처"],
            address: "경기도 안성시 대덕면 모산로 394",
        },

        receiver: {
            name: data["받는사람성명"],
            phone: data["연락처1"],
            postcode: null,
            address1: data["받는사람 주소"],
            address2: null,
        },

        products: [
            {
                name: data["상품명"],
                codeAlt: data["상품코드"],
                quantity: data["총수량"],
                ...((await getProductByCodeAlt(data["상품코드"])) || {}),
            },
        ],

        targetsAt: null,

        rawData,
    });
};

export default {
    props: {
        file: { type: File, default: null },
        value: { type: Array, default: () => [] }, // items
        getProductByCodeAlt: { type: Function, default: () => () => {} },
    },
    data: () => ({
        items: [],
    }),
    mounted() {
        this.processFile();
    },
    watch: {
        file() {
            this.processFile();
        },
    },
    methods: {
        processFile() {
            if (this.file) {
                try {
                    this.$emit("loading", true);

                    let reader = new FileReader();
                    reader.onload = async () => {
                        let workBook = XLSX.read(reader.result, { type: "binary" });
                        let workSheets = workBook.SheetNames.map((sheetName) => XLSX.utils.sheet_to_json(workBook.Sheets[sheetName], { range: 4, header: "A" }));
                        this.items = await Promise.all(workSheets[0].map(nameData).map(structureData).map(this.makeData));

                        this.$emit("loading", false);
                        this.$emit("input", this.items);
                    };
                    reader.readAsBinaryString(this.file);
                } catch (error) {}
            }
        },
        async makeData(callback) {
            let { getProductByCodeAlt } = this;
            return callback({ getProductByCodeAlt });
        },
    },
};
</script>

<style>
</style>