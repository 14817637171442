<template>
    <v-edit-dialog :return-value.sync="item.receiver" large cancel-text="취소" save-text="변경" v-on="{ save }">
        <slot />
        <template #input>
            <v-responsive min-width="370" min-height="470">
                <v-layout column justify-center fill-height>
                    <v-sheet>
                        <v-text-field label="받으시는분" v-model="item.receiver.name" v-bind="attrs_input" class="mt-4" style="flex: 0 0 auto" @input="input('RECEIVER_NAME')" @keydown.enter="save" />
                        <v-text-field label="연락처" v-model="item.receiver.phone" v-bind="attrs_input" class="mt-4" style="flex: 0 0 auto" @input="input('RECEIVER_PHONE')" @keydown.enter="save" />
                        <v-divider class="mt-5 mb-6" />
                        <v-address-field v-model="item.receiver" v-bind="attrs_input" noRules :btnAttrs="{ className: 'mt-n2 mb-n1', color: 'secondary', height: '40' }" className="mt-4" style="flex: 0 0 auto" @input="input('RECEIVER_ADDRESS')" @keydown.enter="save" />
                    </v-sheet>
                </v-layout>
            </v-responsive>
        </template>
    </v-edit-dialog>
</template>

<script>
import { attrs_input, PURCHASE_ORDER_STATES, PURCHASE_ORDER_UPDATE_COLUMN } from "@/assets/variables";

import VAddressField from "@/components/dumb/v-address-field.vue";

const states = Object.values(PURCHASE_ORDER_STATES);

export default {
    components: {
        VAddressField,
    },
    props: {
        value: { type: Object, default: () => ({}) },
    },
    data: () => ({
        item: { receiver: {} },
        states,
        attrs_input,
    }),
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            const defaultItem = JSON.parse(JSON.stringify(this.value)) || {};
            this.item = { ...defaultItem, receiver: { ...(defaultItem?.receiver || { name: null, phone: null, postcode: null, address1: null, address2: null }) } };
        },
        input(value) {
            if (value === PURCHASE_ORDER_UPDATE_COLUMN.RECEIVER_NAME.value) {
                if (this.value?.receiver?.name !== this.item.receiver?.name) this.item.updatedColumn = value;
            }
            if (value === PURCHASE_ORDER_UPDATE_COLUMN.RECEIVER_PHONE.value) {
                if (this.value?.receiver?.phone !== this.item.receiver?.phone) this.item.updatedColumn = value;
            }
            if (value === PURCHASE_ORDER_UPDATE_COLUMN.RECEIVER_ADDRESS.value) {
                if (this.value?.receiver?.postcode !== this.item.receiver?.postcode || this.value?.receiver?.address1 !== this.item.receiver?.address1 || this.value?.receiver?.address2 !== this.item.receiver?.address2) this.item.updatedColumn = value;
            }
        },
        save() {
            let { _id, receiver, updatedColumn } = this.item;
            let form = { _id, receiver, updatedColumn };

            const message_change = `발주번호: ${this.value?.number}\n변경사항: ${this.value?.receiver?.name || "-"} / ${this.value?.receiver?.phone || "-"} => ${receiver?.name || "-"} / ${receiver?.phone}`;
            const message_success = `보내시는분 변경이 처리되었습니다.\n\n${message_change}`;
            const message_failure = `보내시는분 변경에 실패하였습니다.\n\n${message_change}`;

            this.$emit("update", form, message_success, message_failure);
        },
    },
};
</script>
