<template></template>

<script>
import XLSX from "xlsx";
import dayjs from "dayjs";

const nameData = (row) => ({
    ["보내는사람(지정)"]: `${row?.A || ""}`.trim(),
    ["전화번호1(지정)"]: `${row?.B || ""}`.trim(),
    ["주소(지정)"]: `${row?.C || ""}`.trim(),
    ["받는사람"]: `${row?.D || ""}`.trim(),
    ["전화번호1"]: `${row?.E || ""}`.trim(),
    ["우편번호"]: `${row?.F || ""}`.trim(),
    ["주소"]: `${row?.G || ""}`.trim(),
    ["수량(A타입)"]: `${row?.H || ""}`.trim(),
    ["상품명1"]: `${row?.I || ""}`.trim(),
    // ["상품명(표기)"]: `${row?.J || ""}`.trim(),
    ["배송메시지"]: `${row?.J || ""}`.trim(),
    ["주문상태"]: `${row?.K || ""}`.trim(),
    ["발주번호"]: `${row?.L || ""}`.trim(),

    rawData: row,
});

const structureData = ({ rawData, ...data }) => {
    return async ({ getProductByCodeAlt }) => ({
        number: data["발주번호"],

        sender: {
            name: data["보내는사람(지정)"],
            phone: data["전화번호1(지정)"],
            address: "경기도 안성시 대덕면 모산로 394",
        },

        receiver: {
            name: data["받는사람"],
            phone: data["전화번호1"],
            postcode: data["우편번호"],
            address1: data["주소"],
            address2: null,
        },

        products: [
            {
                // name: data["상품명(표기)"],
                code: data["상품명1"],
                quantity: data["수량(A타입)"],
            },
        ],

        shippingMessage: data["배송메시지"] || null,

        stateText: data["주문상태"] || null,

        targetsAt: null,

        rawData,
    });
};

export default {
    props: {
        file: { type: File, default: null },
        value: { type: Array, default: () => [] }, // items
        getProductByCodeAlt: { type: Function, default: () => () => {} },
    },
    data: () => ({
        items: [],
    }),
    mounted() {
        this.processFile();
    },
    watch: {
        file() {
            this.processFile();
        },
    },
    methods: {
        processFile() {
            if (this.file) {
                try {
                    this.$emit("loading", true);

                    let reader = new FileReader();
                    reader.onload = async () => {
                        let workBook = XLSX.read(reader.result, { type: "binary" });
                        let workSheets = workBook.SheetNames.map((sheetName) => XLSX.utils.sheet_to_json(workBook.Sheets[sheetName], { range: 1, header: "A" }));
                        this.items = await Promise.all(workSheets[0].map(nameData).map(structureData).map(this.makeData));
                        this.$emit("loading", false);
                        this.$emit("input", this.items);
                    };
                    reader.readAsBinaryString(this.file);
                } catch (error) {}
            }
        },
        async makeData(callback) {
            let { getProductByCodeAlt } = this;
            return callback({ getProductByCodeAlt });
        },
    },
};
</script>

<style>
</style>