<template>
    <v-dialog v-model="dialog" width="320" persistent>
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card light>
            <v-card-title> {{ title }} <v-spacer /> <v-icon @click="dialog = false">mdi-close</v-icon> </v-card-title>
            <v-card-text class="py-0">
                <v-row>
                    <v-col cols="12"> <autocomplete-shop-category v-model="inputs._category" label="카테고리" showsParentName :depth="1" placeholder="카테고리를 선택하세요" v-bind="attrs_input" ref="category-autocomplete" /> </v-col>
                    <v-col cols="12"> <v-text-field v-model="inputs.name" label="상품명" v-bind="attrs_input" /> </v-col>
                    <v-col cols="12"> <v-text-field v-model="inputs.size" label="규격" v-bind="attrs_input" /> </v-col>
                    <v-col cols="12"> <v-text-field v-model="inputs.code" label="암호코드" :placeholder="inputs.name" v-bind="attrs_input" /> </v-col>
                    <v-col cols="12"> <v-text-field v-model="inputs.codeAlt" label="상품코드" :placeholder="inputs.name" v-bind="attrs_input" /> </v-col>
                    <v-col cols="12"> <autocomplete-user-group v-model="inputs.access._userGroups" label="노출그룹" multiple v-bind="attrs_input" ref="group-autocomplete" /> </v-col>
                    <v-col cols="12"> <v-select v-model="inputs.access.siteFeatures" multiple :items="siteFeatures" label="노출기능" v-bind="attrs_input" /> </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn text color="primary" @click="save">저장</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import { attrs_input, SITE_FEATURES } from "@/assets/variables";

import AutocompleteUserGroup from "../dumb/autocomplete-user-group.vue";
import AutocompleteShopCategory from "../dumb/autocomplete-shop-category.vue";

const initInputs = () => ({
    _category: null,
    name: "",
    size: "",
    code: "",
    codeAlt: "",
    access: {
        _userGroups: [],
        siteFeatures: [],
    },
});

const siteFeatures = Object.values(SITE_FEATURES);

export default {
    components: {
        AutocompleteUserGroup,
        AutocompleteShopCategory,
    },
    props: {
        _id: { type: String, default: null },
    },
    data: () => ({
        inputs: initInputs(),

        dialog: false,

        attrs_input,
        siteFeatures,
    }),
    computed: {
        isCreate() {
            return !this._id;
        },
        title() {
            let title = "상품";
            title += this.isCreate ? " 생성" : " 수정";
            return title;
        },
    },
    watch: {
        dialog() {
            if (this.dialog) this.init();
        },
    },
    methods: {
        async init() {
            if (this.isCreate) this.inputs = initInputs();
            else {
                let { _id } = this;
                this.inputs = (await api.console.shop.products.get({ _id }))?.product;
                this.$emit("input", { ...this.inputs });
            }

            this.$refs["category-autocomplete"]?.init?.();
            this.$refs["group-autocomplete"]?.init?.();
        },
        validates() {
            let { _category, name, size } = this.inputs;
            if (!_category) throw new Error("카테고리를 선택해주세요");
            if (!name || name.replace(/\s/, "").length == 0) throw new Error("상품명을 작성해주세요");
            if (!size || size.replace(/\s/, "").length == 0) throw new Error("규격을 작성해주세요");
        },
        async save() {
            try {
                this.validates();

                let { post, put } = api.console.shop.products;
                let product = (await (this.isCreate ? post : put)(this.inputs))?.product;

                alert(this.isCreate ? "생성되었습니다" : "수정되었습니다");
                this.$emit("input", product);
                this.dialog = false;
            } catch (error) {
                this.$handleError(error);
            }
        },
    },
};
</script>
