var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', [_c('v-card-subtitle', {
    staticClass: "px-6"
  }, [_vm._v(" 유형을 선택하세요 ")]), _c('v-divider'), _c('v-card-text', [_c('v-list', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-list-item-group', {
    attrs: {
      "color": "primary"
    },
    on: {
      "change": _vm.emit
    },
    model: {
      value: _vm.form.type,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "type", $$v);
      },
      expression: "form.type"
    }
  }, _vm._l(_vm.types, function (_ref) {
    var text = _ref.text,
        value = _ref.value;
    return _c('v-list-item', {
      key: text,
      staticClass: "px-6",
      attrs: {
        "value": value,
        "dense": "",
        "tile": ""
      }
    }, [_c('v-list-item-title', {
      staticClass: "text-center"
    }, [_vm._v(_vm._s(text))])], 1);
  }), 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "x-large": "",
      "text": "",
      "block": "",
      "color": "primary",
      "disabled": !_vm.form.type
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('forward');
      }
    }
  }, [_vm._v("선택완료")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }