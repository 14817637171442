<template>
    <div>
        <slot name="activator" v-bind="{ to }" />
    </div>
</template>

<script>
import { PURCHASE_ORDER_TYPES } from "@/assets/variables";
export default {
    props: {
        order: { type: Object, default: () => ({}) },
    },
    computed: {
        to() {
            let { CUSTOMER_ORDER_BY_UNIT, CUSTOMER_ORDER_BY_BULK, ADMIN_UPLOADED_ORDER_BC, ADMIN_UPLOADED_ORDER_CU, ADMIN_UPLOADED_ORDER_DC, ADMIN_UPLOADED_ORDER_SH, ADMIN_MANUAL_ORDER_BULK, ADMIN_MANUAL_ORDER_UNIT } = PURCHASE_ORDER_TYPES;

            let path;
            switch (this.order?.type) {
                case CUSTOMER_ORDER_BY_UNIT.value:
                case CUSTOMER_ORDER_BY_BULK.value:
                case ADMIN_UPLOADED_ORDER_BC.value:
                case ADMIN_UPLOADED_ORDER_CU.value:
                case ADMIN_UPLOADED_ORDER_DC.value:
                case ADMIN_UPLOADED_ORDER_SH.value:
                case ADMIN_MANUAL_ORDER_BULK.value:
                case ADMIN_MANUAL_ORDER_UNIT.value:
                    path = "/console/shop/purchase-orders-shipped";
                    break;
                default:
                    path = "/console/shop/purchase-orders-processed";
                    break;
            }

            return { path, query: { searchKey: "number", searchValue: this.order?.number } };
        },
    },
};
</script>

<style>
</style>