var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return ['CUSTOMER_ORDER_BY_UNIT', 'CUSTOMER_ORDER_BY_BULK', 'ADMIN_MANUAL_ORDER_BULK', 'ADMIN_MANUAL_ORDER_UNIT'].includes(this.value.type) ? _c('v-data-table', _vm._b({
    staticClass: "transparent",
    attrs: {
      "dense": "",
      "disable-filtering": "",
      "disable-sort": "",
      "hide-default-footer": ""
    },
    scopedSlots: _vm._u([{
      key: "top",
      fn: function fn() {
        return [_c('v-card-subtitle', {
          staticClass: "font-weight-bold",
          staticStyle: {
            "background-color": "rgba(0, 0, 0, 0.05)"
          }
        }, [_vm._v("첨부 파일")]), _vm._v(" "), _c('v-divider')];
      },
      proxy: true
    }, _vm._l(_vm.headers.filter(function (header) {
      return header.hasOwnProperty('formatter');
    }), function (header) {
      return {
        key: "item.".concat(header.value),
        fn: function fn(_ref) {
          var value = _ref.value;
          return [_vm._v(" " + _vm._s(header.formatter(value)) + " ")];
        }
      };
    }), {
      key: "item.receipt",
      fn: function fn(_ref2) {
        var value = _ref2.value;
        return [value ? [_c('v-btn', {
          attrs: {
            "small": "",
            "text": "",
            "icon": "",
            "tile": "",
            "href": value.src,
            "target": "_blank"
          }
        }, [_c('v-icon', [_vm._v("mdi-image")])], 1), _c('v-btn', {
          attrs: {
            "small": "",
            "text": "",
            "icon": "",
            "tile": "",
            "href": value.src,
            "download": ""
          }
        }, [_c('v-icon', [_vm._v("mdi-download")])], 1), _c('v-btn', {
          attrs: {
            "small": "",
            "text": "",
            "icon": "",
            "tile": ""
          },
          on: {
            "click": function click($event) {
              return _vm.remove('receipt');
            }
          }
        }, [_c('v-icon', [_vm._v("mdi-close")])], 1)] : [_c('v-btn', {
          attrs: {
            "small": "",
            "text": "",
            "icon": "",
            "tile": ""
          },
          on: {
            "click": function click($event) {
              return _vm.inputOpen('receipt');
            }
          }
        }, [_c('v-icon', [_vm._v("mdi-upload")])], 1), _c('v-file-input', {
          ref: "receipt",
          staticClass: "d-none",
          on: {
            "change": function change(file) {
              return _vm.upload(file, 'receipt');
            }
          }
        })]];
      }
    }, {
      key: "item.businessCard",
      fn: function fn(_ref3) {
        var value = _ref3.value;
        return [value ? [_c('v-btn', {
          attrs: {
            "small": "",
            "text": "",
            "icon": "",
            "tile": "",
            "href": value.src,
            "target": "_blank"
          }
        }, [_c('v-icon', [_vm._v("mdi-image")])], 1), _c('v-btn', {
          attrs: {
            "small": "",
            "text": "",
            "icon": "",
            "tile": "",
            "href": value.src,
            "download": ""
          }
        }, [_c('v-icon', [_vm._v("mdi-download")])], 1), _c('v-btn', {
          attrs: {
            "small": "",
            "text": "",
            "icon": "",
            "tile": ""
          },
          on: {
            "click": function click($event) {
              return _vm.remove('businessCard');
            }
          }
        }, [_c('v-icon', [_vm._v("mdi-close")])], 1)] : [_c('v-btn', {
          attrs: {
            "small": "",
            "text": "",
            "icon": "",
            "tile": ""
          },
          on: {
            "click": function click($event) {
              return _vm.inputOpen('businessCard');
            }
          }
        }, [_c('v-icon', [_vm._v("mdi-upload")])], 1), _c('v-file-input', {
          ref: "businessCard",
          staticClass: "d-none",
          on: {
            "change": function change(file) {
              return _vm.upload(file, 'businessCard');
            }
          }
        })]];
      }
    }, {
      key: "item.greetingCard",
      fn: function fn(_ref4) {
        var value = _ref4.value;
        return [value ? [_c('v-btn', {
          attrs: {
            "small": "",
            "text": "",
            "icon": "",
            "tile": "",
            "href": value.src,
            "target": "_blank"
          }
        }, [_c('v-icon', [_vm._v("mdi-image")])], 1), _c('v-btn', {
          attrs: {
            "small": "",
            "text": "",
            "icon": "",
            "tile": "",
            "href": value.src,
            "download": ""
          }
        }, [_c('v-icon', [_vm._v("mdi-download")])], 1), _c('v-btn', {
          attrs: {
            "small": "",
            "text": "",
            "icon": "",
            "tile": ""
          },
          on: {
            "click": function click($event) {
              return _vm.remove('greetingCard');
            }
          }
        }, [_c('v-icon', [_vm._v("mdi-close")])], 1)] : [_c('v-btn', {
          attrs: {
            "small": "",
            "text": "",
            "icon": "",
            "tile": ""
          },
          on: {
            "click": function click($event) {
              return _vm.inputOpen('greetingCard');
            }
          }
        }, [_c('v-icon', [_vm._v("mdi-upload")])], 1), _c('v-file-input', {
          ref: "greetingCard",
          staticClass: "d-none",
          on: {
            "change": function change(file) {
              return _vm.upload(file, 'greetingCard');
            }
          }
        })]];
      }
    }, {
      key: "footer",
      fn: function fn() {
        return [_c('v-divider')];
      },
      proxy: true
    }], null, true)
  }, 'v-data-table', {
    headers: _vm.headers,
    items: _vm.items
  }, false)) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }