<template>
    <v-card height="100%">
        <v-card-title class="subtitle-1">이미지</v-card-title>
        <v-divider />
        <v-card-text>
            <images-input v-model="images" @input="emit" />
        </v-card-text>
    </v-card>
</template>

<script>
import ImagesInput from "../dumb/images-input.vue";
export default {
    components: { ImagesInput },
    props: {
        value: { type: Array, default: () => [] },
    },
    data: () => ({
        images: [],
    }),
    mounted() {
        this.sync();
    },
    methods: {
        sync() {
            this.images = this.value;
        },
        emit() {
            this.$emit("input", this.images);
        },
    },
};
</script>

<style></style>
