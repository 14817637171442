<template>
    <v-dialog v-model="shows" max-width="480" scrollable>
        <template v-slot:activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card flat tile>
            <v-card-title> 상품선택 <v-spacer /> <v-icon @click="shows = false">mdi-close</v-icon> </v-card-title>
            <v-divider />
            <v-expand-transition>
                <div v-show="!filter._category">
                    <products-filter-name v-model="filter.name" v-bind="attrs_input" placeholder="상품명" clearable class="mt-4 mx-4" />
                </div>
            </v-expand-transition>
            <v-expand-transition>
                <div v-show="!filter.name">
                    <products-filter-category v-model="filter._category" v-bind="{ ...attrs_input, access }" clearable @input="search" class="mb-n4" />
                </div>
            </v-expand-transition>
            <v-divider class="mt-4" />
            <table-products v-model="products" v-bind="attrs_input" noDataText="검색된 상품이 없습니다" hideRemoveBtn />
            <v-divider />
            <v-btn x-large text block color="primary" v-bind="{ disabled }" @click="emit">선택완료</v-btn>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import { attrs_input } from "@/assets/variables";

import TableProducts from "../table-products/table-products.vue";
import ProductsFilterName from "./products-filter-name.vue";
import ProductsFilterCategory from "./products-filter-category.vue";

const initFilter = () => ({
    name: null,
    _category: null,
});

export default {
    components: {
        TableProducts,
        ProductsFilterName,
        ProductsFilterCategory,
    },
    props: {
        form: { type: Object, default: () => ({}) },
    },
    data: () => ({
        shows: false,
        filter: initFilter(),
        products: [],

        attrs_input,
    }),
    computed: {
        access() {
            let { _ordererGroup: _userGroups } = this.form;
            return { _userGroups };
        },
        selected() {
            return this.products.filter(({ quantity }) => +quantity);
        },
        disabled() {
            return !this.selected.length;
        },
    },
    watch: {
        shows() {
            if (this.shows) this.init();
        },
    },
    methods: {
        init() {
            this.products = [];
            this.filter = initFilter();
        },
        emit() {
            this.$emit("input", this.selected);
            this.shows = false;
        },
        async search() {
            if (this.filter.name || this.filter._category) {
                let { ...access } = this.access;
                if (!access?._userGroups) delete access._userGroups || {};

                let { products } = await api.v1.shop.products.gets({
                    params: { ...this.filter, access },
                });
                this.products = products.map((product) => ({ ...product, quantity: 0 }));
            } else {
                this.products = [];
            }
        },
    },
};
</script>
