<template>
    <v-edit-dialog :return-value.sync="item.sender" large cancel-text="취소" save-text="변경" v-on="{ save }">
        <slot />
        <template #input>
            <v-text-field label="보내시는분" v-model="item.sender.name" v-bind="attrs_input" class="mt-3" @keydown.enter="save" />
            <v-text-field label="연락처" v-model="item.sender.phone" v-bind="attrs_input" class="mt-3" @keydown.enter="save" />
        </template>
    </v-edit-dialog>
</template>

<script>
import { attrs_input, PURCHASE_ORDER_STATES, PURCHASE_ORDER_UPDATE_COLUMN } from "@/assets/variables";
const states = Object.values(PURCHASE_ORDER_STATES);

export default {
    props: {
        value: { type: Object, default: () => ({}) },
    },
    data: () => ({
        item: { sender: {} },
        states,
        attrs_input,
    }),
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            const defaultItem = JSON.parse(JSON.stringify(this.value)) || {};
            this.item = { ...defaultItem, sender: { ...(defaultItem?.sender || { name: null, phone: null }) } };
        },
        save() {
            let { _id, sender } = this.item;
            let form = { _id, sender, updatedColumn: PURCHASE_ORDER_UPDATE_COLUMN.SENDER.value };

            const message_change = `발주번호: ${this.value?.number}\n변경사항: ${this.value?.sender?.name || "-"} / ${this.value?.sender?.phone || "-"} => ${sender?.name || "-"} / ${sender?.phone}`;
            const message_success = `보내시는분 변경이 처리되었습니다.\n\n${message_change}`;
            const message_failure = `보내시는분 변경에 실패하였습니다.\n\n${message_change}`;

            this.$emit("update", form, message_success, message_failure);
        },
    },
};
</script>
