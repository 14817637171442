var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "ma-0 pa-2"
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-select', _vm._b({
    attrs: {
      "placeholder": "대분류",
      "items": _vm.categories0,
      "item-text": "name",
      "item-value": "_id",
      "clearable": ""
    },
    model: {
      value: _vm.category0,
      callback: function callback($$v) {
        _vm.category0 = $$v;
      },
      expression: "category0"
    }
  }, 'v-select', _vm.$attrs, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-select', _vm._b({
    attrs: {
      "placeholder": "중분류",
      "items": _vm.filteredSubCategories,
      "item-text": "name",
      "item-value": "_id",
      "disabled": !_vm.category0,
      "clearable": ""
    },
    model: {
      value: _vm.category1,
      callback: function callback($$v) {
        _vm.category1 = $$v;
      },
      expression: "category1"
    }
  }, 'v-select', _vm.$attrs, false))], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }