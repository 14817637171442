<template>
    <process-data-bc v-if="type == ADMIN_UPLOADED_ORDER_BC.value" v-bind="{ value, file, getProductByCodeAlt }" v-on="{ input, loading }" />
    <process-data-cu v-else-if="type == ADMIN_UPLOADED_ORDER_CU.value" v-bind="{ value, file, getProductByCodeAlt }" v-on="{ input, loading }" />
    <process-data-dc v-else-if="type == ADMIN_UPLOADED_ORDER_DC.value" v-bind="{ value, file, getProductByCodeAlt }" v-on="{ input, loading }" />
    <process-data-mb v-else-if="type == ADMIN_MANUAL_ORDER_BULK.value" v-bind="{ value, file, getProductByCodeAlt }" v-on="{ input, loading }" />
    <process-data-sh v-else-if="type == ADMIN_UPLOADED_ORDER_SH.value" v-bind="{ value, file, getProductByCodeAlt }" v-on="{ input, loading }" />
</template>

<script>
import api from "@/api";
import { PURCHASE_ORDER_TYPES } from "@/assets/variables";

import ProcessDataBc from "./process-data-bc.vue";
import ProcessDataCu from "./process-data-cu.vue";
import ProcessDataDc from "./process-data-dc.vue";
import ProcessDataMb from "./process-data-mb.vue";
import ProcessDataSh from "./process-data-sh.vue";

let { ADMIN_UPLOADED_ORDER_BC, ADMIN_UPLOADED_ORDER_CU, ADMIN_UPLOADED_ORDER_DC, ADMIN_UPLOADED_ORDER_SH, ADMIN_MANUAL_ORDER_BULK } = PURCHASE_ORDER_TYPES;

export default {
    components: {
        ProcessDataBc,
        ProcessDataCu,
        ProcessDataDc,
        ProcessDataMb,
        ProcessDataSh,
    },
    props: {
        file: { type: File, default: null },
        type: { type: String, default: null },
        value: { type: Array, default: () => [] }, // items
    },
    data: () => ({
        ADMIN_UPLOADED_ORDER_BC,
        ADMIN_UPLOADED_ORDER_CU,
        ADMIN_UPLOADED_ORDER_DC,
        ADMIN_UPLOADED_ORDER_SH,
        ADMIN_MANUAL_ORDER_BULK,
    }),
    methods: {
        async getProductByCodeAlt(codeAlt) {
            return (await api.v1.shop.products.getByCodeAlt(codeAlt)).product;
        },
        input(items) {
            items = items.map((item) => ({ ...item, tempId: Math.random() }));
            this.$emit("input", items);
        },
        loading(loading) {
            this.$emit("loading", loading);
        },
    },
};
</script>

<style>
</style>