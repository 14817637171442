var render = function () {
  var _vm$form;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "max-width": "480"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var attrs = _ref.attrs,
            on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs: attrs,
          on: on,
          disabled: _vm.disabled,
          title: _vm.title
        })];
      }
    }], null, true),
    model: {
      value: _vm.shows,
      callback: function callback($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(_vm._s(_vm.title))]), _c('v-card-text', {
    staticClass: "py-0"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('autocomplete-user-group', _vm._b({
    ref: "autocomplete-user-group",
    attrs: {
      "label": "주문자그룹"
    },
    on: {
      "input": function input($event) {
        _vm.form._orderer = null;
      }
    },
    model: {
      value: _vm.form._ordererGroup,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "_ordererGroup", $$v);
      },
      expression: "form._ordererGroup"
    }
  }, 'autocomplete-user-group', _vm.attrs_input, false))], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('autocomplete-user', _vm._b({
    ref: "autocomplete-user",
    attrs: {
      "label": "주문자",
      "disabled": !((_vm$form = _vm.form) !== null && _vm$form !== void 0 && _vm$form._ordererGroup)
    },
    model: {
      value: _vm.form._orderer,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "_orderer", $$v);
      },
      expression: "form._orderer"
    }
  }, 'autocomplete-user', Object.assign({}, _vm.attrs_input, {
    params: _vm.params
  }), false))], 1)], 1)], 1), _c('v-card-actions', {
    staticClass: "justify-end"
  }, [_c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v("취소")]), _c('v-btn', _vm._b({
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, 'v-btn', {
    disabled: _vm.disabled
  }, false), [_vm._v("변경")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }