<template>
    <v-dialog v-model="dialog" width="320" persistent>
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card light>
            <v-card-title> {{ title }} <v-spacer /> <v-icon @click="dialog = false">mdi-close</v-icon> </v-card-title>
            <v-card-text class="py-0">
                <v-row>
                    <v-col cols="12"> <v-text-field label="그룹명" v-model="inputs.name" v-bind="attrs_input"></v-text-field> </v-col>
                    <v-col cols="12">
                        <v-autocomplete label="권한" v-model="inputs.scope" :items="siteFeatures" multiple v-bind="attrs_input">
                            <template #selection="{ item }">
                                <v-chip outlined label class="ma-1" close @click:close="inputs.scope = inputs.scope.filter((scope) => scope != item.value)">{{ item.text }}</v-chip>
                            </template>
                        </v-autocomplete>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn text color="primary" @click="save">저장</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import { attrs_input, SITE_FEATURES } from "@/assets/variables";

const initInputs = () => ({
    name: "",
    scope: [],
});

const siteFeatures = Object.values(SITE_FEATURES);

export default {
    props: {
        _id: { type: String, default: null },
    },
    data: () => ({
        inputs: initInputs(),

        dialog: false,
        attrs_input,
        siteFeatures,
    }),
    computed: {
        isCreate() {
            return !this._id;
        },
        title() {
            let title = "사용자 그룹";
            title += this.isCreate ? " 생성" : " 수정";
            return title;
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        dialog() {
            if (this.dialog) this.init();
        },
    },
    methods: {
        async init() {
            if (this.isCreate) this.inputs = initInputs();
            else {
                let { _id } = this;
                this.inputs = (await api.console.users.groups.get({ _id }))?.group;
            }
        },
        async save() {
            try {
                let { post, put } = api.console.users.groups;
                const { group } = await (this.isCreate ? post : put)(this.inputs);

                alert(this.isCreate ? "생성되었습니다" : "수정되었습니다");
                this.$emit("input", group);
                this.dialog = false;
            } catch (error) {
                this.$handleError(error);
            }
        },
    },
};
</script>
