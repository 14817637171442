var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "width": "320"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var attrs = _ref.attrs,
            on = _ref.on;
        return [_c('v-btn', _vm._g(_vm._b({
          staticClass: "mr-n4",
          attrs: {
            "text": "",
            "tile": ""
          }
        }, 'v-btn', attrs, false), on), [_c('span', {
          staticClass: "font-weight-regular",
          staticStyle: {
            "text-transform": "initial"
          }
        }, [_vm._v(" " + _vm._s(_vm.value || "-") + " ")])])];
      }
    }]),
    model: {
      value: _vm.shows,
      callback: function callback($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_c('v-card', [_c('v-card-text', {
    staticClass: "pa-4"
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "autofocus": ""
    },
    on: {
      "keydown": function keydown($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.emit.apply(null, arguments);
      }
    },
    model: {
      value: _vm.data,
      callback: function callback($$v) {
        _vm.data = $$v;
      },
      expression: "data"
    }
  }, 'v-text-field', _vm.$attrs, false))], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }