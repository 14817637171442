var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "width": "320"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var _vm$item;

        var attrs = _ref.attrs,
            on = _ref.on;
        return [_c('v-btn', _vm._g(_vm._b({
          staticClass: "mr-n4",
          attrs: {
            "text": "",
            "tile": ""
          }
        }, 'v-btn', attrs, false), on), [_vm._v(_vm._s(((_vm$item = _vm.item) === null || _vm$item === void 0 ? void 0 : _vm$item.address) || "-"))])];
      }
    }]),
    model: {
      value: _vm.shows,
      callback: function callback($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_c('v-card', [_c('v-card-text', {
    staticClass: "pa-4"
  }, [_c('v-address-field', _vm._g(_vm._b({
    staticClass: "mb-n3",
    attrs: {
      "no-rules": "",
      "className": "mb-3"
    },
    model: {
      value: _vm.item,
      callback: function callback($$v) {
        _vm.item = $$v;
      },
      expression: "item"
    }
  }, 'v-address-field', Object.assign({}, _vm.$attrs, {
    btnAttrs: _vm.btnAttrs
  }), false), {
    emit: _vm.emit
  }))], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }