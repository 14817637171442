<template>
    <list-search v-bind="{ showsSearch, loading }" v-on="{ search }">
        <v-row>
            <v-col cols="12">
                <v-select v-model="query.state" label="주문상태" :items="states" multiple clearable v-bind="attrs_input" />
            </v-col>
            <v-col cols="6" lg="3">
                <v-select v-model="query.searchDateKey" label="검색기준" :items="dateTypes" v-bind="attrs_input" />
            </v-col>
            <v-col cols="6" lg="3">
                <v-date-field v-model="query.searchDateValue[0]" label="시작일시" clearable v-bind="attrs_input" />
            </v-col>
            <v-col cols="6" lg="3">
                <v-date-field v-model="query.searchDateValue[1]" label="종료일시" clearable v-bind="attrs_input" />
            </v-col>
            <v-col cols="6" lg="3" class="d-flex align-center">
                <v-btn-toggle dense color="primary">
                    <v-btn v-for="{ text, value } in dates" :key="text" v-bind="{ value }" height="40" color="white" :class="{ 'v-btn--active': isActive(value) }" @click="setDate(value)">{{ text }}</v-btn>
                </v-btn-toggle>
            </v-col>
        </v-row>
    </list-search>
</template>

<script>
import dayjs from "dayjs";
import { attrs_input, PURCHASE_ORDER_STATES } from "@/assets/variables";

import ListSearch from "../../dumb/list-search.vue";
import VDateField from "@/components/plugins/vuetify/v-date-field.vue";

let states = Object.values(PURCHASE_ORDER_STATES);

const initQuery = () => ({
    state: [],
    searchDateValue: [null, null],
    searchDateKey: null,
});

const dateTypes = [
    { text: "입고/발송일", value: "targetsAt" },
    { text: "발주일시", value: "createdAt" },
    { text: "발송처리일", value: "processedAt" },
];

export default {
    components: {
        ListSearch,
        VDateField,
    },
    props: {
        loading: { type: Boolean, default: false },
        showsSearch: { type: Boolean, default: false },
    },
    data: () => ({
        states,
        dateTypes,
        attrs_input,

        query: initQuery(),
    }),
    computed: {
        dates() {
            return [
                { text: "오늘", value: [dayjs().subtract(1, "day").toISOString().toDate(), dayjs().toISOString().toDate()] },
                { text: "어제", value: [dayjs().subtract(2, "day").toISOString().toDate(), dayjs().subtract(1, "day").toISOString().toDate()] },
            ];
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        "$route.path"() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.query = {
                state: JSON.parse(JSON.stringify(this.$route.query?.state || [])),
                searchDateValue: JSON.parse(JSON.stringify(this.$route.query?.searchDateValue || [null, null])),
                searchDateKey: this.$route.query?.searchDateKey || null,
            };
        },
        search() {
            let { ...query } = this.query;
            if (!query.searchDateKey) delete query.searchDateKey;
            this.$router.push({ query });
        },
        setDate(value) {
            if (value[0] == this.query.searchDateValue[0] && value[1] == this.query.searchDateValue[1]) this.query.searchDateValue = [null, null];
            else this.query.searchDateValue = [...value];
        },
        isActive(value) {
            return value[0] == this.query.searchDateValue[0] && value[1] == this.query.searchDateValue[1];
        },
    },
};
</script>

<style></style>
