<template>
    <v-data-table v-if="value?.receiver" v-bind="{ headers, items }" dense disable-filtering disable-sort hide-default-footer class="transparent">
        <template #footer> <v-divider /> </template>
    </v-data-table>
</template>

<script>
import { makeShippingMessage } from "@/assets/variables";
const headers = [
    {
        text: "배송메시지",
        value: "shippingMessage",
    },
].map((item, index, array) => ({ ...item, divider: index != array.length - 1, align: item.align ?? "center", cellClass: item.cellClass || "caption", formatter: item.formatter ?? ((value) => value ?? "-") }));

export default {
    props: {
        value: { type: Object, default: () => ({}) }, // order
    },
    data: () => ({
        headers,
    }),
    computed: {
        items() {
            let { shippingMessage } = this.value || {};
            shippingMessage = shippingMessage ?? makeShippingMessage(this.value);
            return [{ shippingMessage }];
        },
    },
};
</script>

<style>
</style>