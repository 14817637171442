<template>
    <v-btn-toggle dense color="primary">
        <v-btn v-for="{ to, text } in items" :key="text" v-bind="{ to }" exact color="white" @click.prevent.stop.capture="go(to)" height="40">{{ text }}</v-btn>
    </v-btn-toggle>
</template>

<script>
export default {
    computed: {
        items() {
            let { ...query } = this.$route.query;
            delete query.isReplied;

            return [{ text: "전체", to: { query } }].concat({ text: "미처리", to: { query: { ...query, isReplied: false } } }, { text: "처리", to: { query: { ...query, isReplied: true } } });
        },
    },
    methods: {
        go(to) {
            to = JSON.parse(JSON.stringify(to));
            delete to.query.page;
            this.$router.push(to);
        },
    },
};
</script>

<style>
</style>