<template>
    <v-layout justify-center>
        <v-responsive max-width="1280" width="100%" height="100%" class="px-3 mx-n3">
            <list-heading title="공지사항 목록" />

            <v-data-table :headers="notificationsHeaders" :items="notifications" disable-sort disable-pagination hide-default-footer class="v-sheet--outlined">
                <template v-slot:[`item.no`]="{ item }">
                    {{ (page - 1) * limit + notifications.indexOf(item) + 1 }}
                </template>
                <template v-slot:[`item.notification`]="{ item }" style="height: auto">
                    <v-row @click="$set(item, 'show', !item.show)">
                        <v-col cols="auto" class="text-start">{{ item.subject }}</v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="auto">
                            <v-icon v-if="!item.show">mdi-chevron-down</v-icon>
                            <v-icon v-else>mdi-chevron-up</v-icon>
                        </v-col>
                    </v-row>
                    <v-expand-transition>
                        <v-row v-show="item.show">
                            <v-col cols="12" class="text-start">
                                <p v-html="item.content.replace(/\n/g, '<br>')"></p>
                            </v-col>
                        </v-row>
                    </v-expand-transition>
                </template>
                <!-- <template v-slot:[`item.sites`]="{ item }">
                        <notification-sites :notification="item" />
                    </template> -->
                <template v-slot:[`item.isNotice`]="{ item }">
                    <v-switch v-model="item.isNotice" class="mt-0" hide-details @change="save(item)"></v-switch>
                </template>
                <template v-slot:[`item.createdAt`]="{ item }">
                    {{ item.createdAt.toDate() }}
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <v-icon small class="mr-2" @click="edit(item)"> mdi-pencil </v-icon>
                    <v-icon small @click="remove(item)"> mdi-delete </v-icon>
                </template>
            </v-data-table>
            <v-pagination v-model="page" :length="pageCount" :total-visible="11" class="mt-4 mb-12"></v-pagination>

            <v-dialog v-model="notificationDialog" max-width="820px">
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" bottom color="accent" dark fab fixed right @click="create">
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                </template>
                <v-card v-if="notificationDialog">
                    <v-card-title>
                        <span v-if="!editItem._id" class="subtitle-1">공지사항 등록</span>
                        <span v-else class="subtitle-1">공지사항 수정</span>
                    </v-card-title>
                    <v-card-text>
                        <v-row class="mt-3">
                            <v-col class="py-0">
                                <v-text-field v-model="editItem.subject" label="제목" persistent-placeholder hide-details></v-text-field>
                            </v-col>
                            <!-- <v-col cols="12" md="4" class="py-0">
                                    <v-select v-model="editItem.sites" multiple :items="sites" persistent-placeholder item-text="text" item-value="value" label="사이트" hide-details small-chips />
                                </v-col> -->
                            <v-col cols="12" md="auto" class="py-0">
                                <div>
                                    <div class="caption">상단고정</div>
                                    <v-switch v-model="editItem.isNotice" class="mt-0" hide-details></v-switch>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row align="center" class="mt-4">
                            <v-col class="py-0 pr-0">
                                <v-file-input v-model="editItem.upload" label="첨부파일" persistent-placeholder hide-details />
                            </v-col>
                            <v-col class="py-0" cols="auto">
                                <v-icon>mdi-delete</v-icon>
                            </v-col>
                        </v-row>
                        <div class="caption mt-4 mb-1">내용</div>
                        <naver-smarteditor v-model="editItem.content" rows="10"></naver-smarteditor>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text @click="close">취소</v-btn>
                        <v-btn color="primary" text :loading="notificationDialogLoading" @click="save(editItem)">저장</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";

import ListHeading from "@/components/console/dumb/list-heading.vue";
import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";
import NotificationSites from "@/components/console/center/notification-sites.vue";

const sites = [
    { text: "Y-Knowledge", value: "yknowledge" },
    { text: "Y-Factory", value: "yfactory" },
];

export default {
    components: {
        ListHeading,
        NaverSmarteditor,
        NotificationSites,
    },
    name: "Notifications",
    computed: {
        site() {
            return this.$route.query?.site;
        },
    },
    data: () => {
        return {
            showsSearch: false,

            page: 1,
            pageCount: 0,
            limit: 10,

            notificationDialogLoading: false,
            notificationDialog: false,
            notifications: [],
            notificationsHeaders: [
                {
                    text: "no",
                    align: "center",
                    width: 100,
                    value: "no",
                },
                {
                    text: "공지사항",
                    align: "center",
                    value: "notification",
                },
                // {
                //     text: "사이트",
                //     align: "center",
                //     width: 120,
                //     value: "sites",
                // },
                {
                    text: "작성일자",
                    align: "center",
                    width: 120,
                    value: "createdAt",
                },
                {
                    text: "상단고정",
                    align: "center",
                    width: 80,
                    value: "isNotice",
                },
                {
                    text: "Actions",
                    align: "center",
                    width: 100,
                    value: "actions",
                },
            ],
            editItem: {
                _id: null,
                subject: "",
                content: "",
                sites: null,
                upload: null,
            },

            defaultItem: {
                _id: null,
                subject: "",
                content: "",
                sites: null,
                upload: null,
            },
            sites,
        };
    },
    created() {
        this.init();
    },
    methods: {
        async init() {
            try {
                await this.search();
            } catch (error) {
                console.error(error);
            }
        },

        async search() {
            try {
                var { summary, notifications } = await api.console.center.notifications.gets({
                    headers: {
                        skip: (this.page - 1) * this.limit,
                        limit: this.limit,
                    },
                    params: {
                        sites: this.site,
                    },
                });

                for (let notification of notifications) {
                    if (notification.upload) notification.upload = await api.getResource(notification.upload);
                }

                this.notifications = notifications;
                this.pageCount = Math.ceil(summary.totalCount / this.limit);
            } catch (error) {
                console.error(error);
            }
        },

        close() {
            this.notificationDialog = false;
        },

        create() {
            this.editItem = Object.assign({}, this.defaultItem);
            this.notificationDialog = true;
        },

        edit(notification) {
            this.editItem = Object.assign({}, notification);
            this.notificationDialog = true;
        },

        async save(notification) {
            if (this.notificationDialog) {
                this.notificationDialogLoading = true;
            }
            try {
                let {
                    notification: { _id },
                } = notification._id ? await api.console.center.notifications.put(notification) : await api.console.center.notifications.post({ ...notification, sites: this.site });
                if (notification.upload) await api.console.center.notifications.upload.post(_id, notification.upload);
                await this.search();
                this.notificationDialog = false;
            } finally {
                if (this.notificationDialogLoading) this.notificationDialogLoading = false;
            }
        },

        async remove(notification) {
            try {
                if (confirm("공지사항을 삭제하시겠습니까?")) {
                    await api.console.center.notifications.delete(notification);
                    await this.search();
                }
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
            }
        },
    },

    watch: {
        site() {
            this.search();
        },
    },
};
</script>

<style>
.console-notification .v-data-table__mobile-row {
    height: auto !important;
}
.v-pagination button {
    box-shadow: none !important;
    border: thin solid rgba(0, 0, 0, 0.12);
}
</style>
