var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.notification.sites.length ? _c('v-chip-group', _vm._l(_vm.notification.sites, function (type) {
    return _c('v-chip', {
      key: type,
      attrs: {
        "outlined": "",
        "color": "primary",
        "disabled": ""
      }
    }, [_vm._v(_vm._s(_vm.getName(type)))]);
  }), 1) : _c('div', [_vm._v("-")]);
}
var staticRenderFns = []

export { render, staticRenderFns }