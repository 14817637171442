<template>
    <v-layout justify-center>
        <v-responsive max-width="1080" width="100%" height="calc(100vh - 48px - 12px - 12px)" class="px-3 mx-n3">
            <v-layout d-flex column fill-height>
                <list-heading title="롯데백화점 구매주문 집계" showsFilterButton v-model="showsSearch" style="flex: 0 1 auto" />
                <purchase-order-status-search v-bind="{ loading, showsSearch }" style="flex: 0 1 auto" />
                <v-row no-gutters justify="end" class="mr-n2">
                    <template>
                        <v-col lg="auto" class="pb-2 pr-2">
                            <excel-download-purchase-order-status v-bind="{ title, items, foot }">
                                <template #activator="{ click, loading }">
                                    <v-sheet outlined rounded>
                                        <v-btn block color="white green--text" v-bind="{ loading }" v-on="{ click }">
                                            <v-icon class="mr-1">mdi-microsoft-excel</v-icon>
                                            전체 다운로드
                                        </v-btn>
                                    </v-sheet>
                                </template>
                            </excel-download-purchase-order-status>
                        </v-col>
                    </template>
                </v-row>
                <v-data-table v-bind="{ headers, items, loading }" fixed-header disable-filtering disable-sort disable-pagination hide-default-footer :items-per-page="-1" class="v-sheet--outlined" style="flex: 0 1 auto; overflow: hidden" height="100%" mobile-breakpoint="0">
                    <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>
                    <template #foot v-if="items.length">
                        <tfoot style="position: sticky; bottom: 0; background-color: white">
                            <td :colspan="headers.length"><v-divider /></td>
                            <tr class="subtitle-2 text-center font-weight-bold primary--text">
                                <td v-for="({ value }, index) in headers" :key="value" :class="{ 'sticky-left': index == 0, 'v-data-table__divider': index != headers.length - 1 }">{{ foot[value] || "-" }}</td>
                            </tr>
                        </tfoot>
                    </template>
                </v-data-table>
            </v-layout>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import dayjs from 'dayjs';
import { PURCHASE_ORDER_TYPES } from "@/assets/variables";

import ListHeading from "@/components/console/dumb/list-heading.vue";
import PurchaseOrderStatusSearch from "@/components/console/shop/purchase-order-status/purchase-order-status-search.vue";
import ExcelDownloadPurchaseOrderStatus from "@/components/console/shop/purchase-order-status/excel-download-purchase-order-status.vue";

const headers = [
    { align: "center", width: +80, text: "상품", value: "code", divider: true, class: "sticky-left z-index-3", cellClass: "sticky-left" },
    { align: "center", width: +80, text: "합계", value: "total", divider: true },
    { align: "center", width: +80, text: "닷컴", value: "dc", divider: true },
    { align: "center", width: +80, text: "매장", value: "store", divider: true },
].map((item) => ({ ...item, formatter: item.formatter ?? ((value) => value || "-") }));

let { ADMIN_UPLOADED_ORDER_SH, ADMIN_UPLOADED_ORDER_CU, ADMIN_UPLOADED_ORDER_BC, ADMIN_UPLOADED_ORDER_DC, BUNDANG_HANDLING_ORDER } = PURCHASE_ORDER_TYPES;
const excelOrderTypes = [ADMIN_UPLOADED_ORDER_SH, ADMIN_UPLOADED_ORDER_CU, ADMIN_UPLOADED_ORDER_BC, ADMIN_UPLOADED_ORDER_DC].map(({ value }) => value);
const plainOrderTypes = Object.keys(PURCHASE_ORDER_TYPES).filter((key) => ![...excelOrderTypes, BUNDANG_HANDLING_ORDER.value].includes(key));

let getProducts = ({ products }) => products;
let cleanCode = (order) => {
    let prefix = ["홈_", "롯_", "백_", "두레_", "CU_", "한비-", "한비_"].find((prefix) => order?.code?.startsWith?.(prefix));
    if (prefix) order.code = order.code.slice(prefix.length);
    return order;
};
let summarizeByCode = (items, { code, quantity, ...item }) => {
    if (code) {
        let itemsExceptThis = items.filter((item) => item.code != code);
        let sameItemInItems = items.find((item) => item.code == code) || { code, quantity: 0, ...item };
        sameItemInItems.quantity += quantity;
        return itemsExceptThis.concat(sameItemInItems);
    } else return items.concat({ code, quantity, ...item });
};
let makeItemsFrom = (orders) => orders.flatMap(getProducts).map(cleanCode).reduce(summarizeByCode, []);

export default {
    components: {
        ListHeading,
        PurchaseOrderStatusSearch,
        ExcelDownloadPurchaseOrderStatus
    },
    data: () => ({
        headers,
        loading: false,
        showsSearch: true,

        title: "구매주문 집계",

        userGroups: [], // 롯데백화점 구분용

        dc: [], // 닷컴
        store: [], // 매장
    }),
    computed: {
        total() {
            // 합계
            return [...this.store, ...this.dc].reduce(summarizeByCode, []);
        },
        items() {
            return this.total
                .map(({ code, quantity, ...item }) => ({
                    ...item,
                    code,
                    total: quantity,
                    store: this.store.find((item) => item.code == code)?.quantity || 0,
                    dc: this.dc.find((item) => item.code == code)?.quantity || 0,
                }))
                .sort((a, b) => (a.code < b.code ? -1 : a.code > b.code ? 1 : 0));
        },
        foot() {
            return this.headers.reduce((foot, { value }) => {
                let data;
                if (value == "code") data = "합계";
                else data = this.items.reduce((sum, item) => (sum += item[value] || 0), 0);

                return { ...foot, [value]: data };
            }, {});
        },
        params() {
            let { ...query } = this.$route.query;
            if (query.searchDateValue) query.searchDateValue = [query.searchDateValue[0] ? dayjs(query.searchDateValue[0]).startOf("day").toISOString() : null, query.searchDateValue[1] ? dayjs(query.searchDateValue[1]).endOf("day").toISOString() : null];
            return { ...query };
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.init();
        },
    },
    methods: {
        async init() {
            this.userGroups = (await api.console.users.groups.gets())?.groups || [];
            this.search();
        },

        async search() {
            this.loading = true;
            try {
                // 닷컴
                var { orders: dcOrders } = await api.console.shop.purchaseOrders.gets({ params: { ...this.params, type: ADMIN_UPLOADED_ORDER_DC.value } });
                this.dc = makeItemsFrom(dcOrders);

                // 매장
                let _store = this.userGroups.reduce((_groups, { _id, name }) => (name.includes("롯데백화점") ? _groups.concat(_id) : _groups), []);
                var { orders: storeOrders } = await api.console.shop.purchaseOrders.gets({ params: { ...this.params, _ordererGroup: _store, type: plainOrderTypes } });
                this.store = makeItemsFrom(storeOrders);
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .max-width-0 {
        max-width: 0;
    }
    .white-space-pre-line {
        white-space: pre-line;
    }
    .cursor-pointer {
        cursor: pointer;
    }
    .v-pagination button {
        box-shadow: none !important;
        border: thin solid rgba(0, 0, 0, 0.12);
    }
    .z-index-3 {
        z-index: 3 !important;
    }
    .sticky-left {
        position: sticky;
        left: 0;
        background-color: white;
    }
}
</style>