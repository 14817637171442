<template>
    <v-combobox v-model="limit" v-bind="{ ...$attrs, items }" prefix="페이지 당" suffix="건" @change="pushQuery" />
</template>

<script>
export default {
    props: {
        value: { type: Number, default: 10 },
        items: { type: Array, default: () => [10, 30, 100] },
        loading: { type: Boolean, default: false },
        initialLimit: { type: Number, default: 10 },
    },
    data: () => ({
        limit: 30,
    }),
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.limit = this.value;
        },
        pushQuery() {
            let { ...query } = this.$route.query;
            query.limit = this.limit;
            if (this.limit == this.initialLimit) delete query.limit;
            this.$router.push({ query });
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .v-text-field {
        &__prefix,
        &__suffix {
            font-size: 12px;
        }
    }
    input {
        min-width: auto !important;
        text-align: center;
    }
}
</style>