var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("영상(유튜브)")]), _c('v-divider'), _c('v-card-text', [_c('v-text-field', {
    attrs: {
      "dense": "",
      "outlined": "",
      "label": "URL",
      "persistent-placeholder": ""
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('v-btn', {
          staticClass: "ma-0 mt-n2",
          attrs: {
            "small": "",
            "text": "",
            "height": "40",
            "color": "primary"
          },
          on: {
            "click": _vm.setVideoId
          }
        }, [_vm._v(" 미리보기 ")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.youtube,
      callback: function callback($$v) {
        _vm.youtube = $$v;
      },
      expression: "youtube"
    }
  }), _c('v-expand-transition', [_c('v-responsive', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.ready,
      expression: "ready"
    }],
    attrs: {
      "max-height": "300"
    }
  }, [_c('v-sheet', [_c('youtube', _vm._b({
    ref: "youtube",
    attrs: {
      "fitParent": ""
    },
    on: {
      "ready": _vm.check
    }
  }, 'youtube', {
    videoId: _vm.videoId
  }, false))], 1)], 1)], 1), _c('v-expand-transition', [_c('v-sheet', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.ready,
      expression: "!ready"
    }]
  }, [_c('v-skeleton-loader', {
    attrs: {
      "type": "image"
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }