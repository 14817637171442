<template>
    <v-autocomplete v-model="$data._category" v-bind="{ ...$attrs, items, itemText, itemValue: '_id' }" @click="alert" />
</template>

<script>
import api from "@/api";

export default {
    props: {
        test: { type: Function, default: () => true },
        value: { type: [String, Array], default: null },
        depth: { type: Number, default: null },
        showsParentName: { type: Boolean, default: false },
    },
    data: () => ({
        _category: null,
        items: [],
    }),
    mounted() {
        this.sync();
        this.init();
    },
    watch: {
        value() {
            this.sync();
        },
        "$data._category"(_category) {
            this.$emit("input", _category);
        },
    },
    methods: {
        sync() {
            this.$data._category = this.value;
        },
        async init() {
            let { depth } = this;
            let params = { depth };
            if (Number.isNaN(depth)) delete params.depth;
            this.items = ((await api.console.shop.categories.gets({ params }))?.categories || []).filter((item) => this.test(item));
        },
        itemText({ parent, name }) {
            if (this.showsParentName) return parent ? `${parent.name} - ${name}` : `${name}`;
            else return `${name}`;
        },
        alert() {
            if (this.$attrs.readonly && this.$attrs.readonlyMessage) alert(this.$attrs.readonlyMessage);
            if (this.$attrs.disabled && this.$attrs.disabledMessage) alert(this.$attrs.readonlyMessage);
        },
    },
};
</script>

<style></style>
