<template>
    <v-edit-dialog :return-value.sync="item.message" large cancel-text="취소" save-text="변경" v-on="{ save }">
        <slot />
        <template #input> <v-text-field v-model="item.message" v-bind="attrs_input" class="mt-3" clearable @click:clear="item.message = null" @keydown.enter="save" /> </template>
    </v-edit-dialog>
</template>

<script>
import { attrs_input } from "@/assets/variables";

export default {
    props: {
        value: { type: Object, default: () => ({}) },
    },
    data: () => ({
        item: {},
        attrs_input,
    }),
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            let item = JSON.parse(JSON.stringify(this.value));
            item.message = item?.message || null;
            this.item = item;
        },
        save() {
            let { _id, message } = this.item;
            if (!message) message = null;
            let form = { _id, message };
            const message_change = `발주번호: ${this.item?.number}\n변경사항: ${this.value?.message || "-"} => ${message || "-"}`;
            const message_success = `메시지 변경이 처리되었습니다.\n\n${message_change}`;
            const message_failure = `메시지 변경에 실패하였습니다.\n\n${message_change}`;
            this.$emit("update", form, message_success, message_failure);
        },
    },
};
</script>

<style>
</style>