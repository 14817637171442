var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-edit-dialog', _vm._g({
    attrs: {
      "return-value": _vm.item.receiver,
      "large": "",
      "cancel-text": "취소",
      "save-text": "변경"
    },
    on: {
      "update:returnValue": function updateReturnValue($event) {
        return _vm.$set(_vm.item, "receiver", $event);
      },
      "update:return-value": function updateReturnValue($event) {
        return _vm.$set(_vm.item, "receiver", $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "input",
      fn: function fn() {
        return [_c('v-responsive', {
          attrs: {
            "min-width": "370",
            "min-height": "470"
          }
        }, [_c('v-layout', {
          attrs: {
            "column": "",
            "justify-center": "",
            "fill-height": ""
          }
        }, [_c('v-sheet', [_c('v-text-field', _vm._b({
          staticClass: "mt-4",
          staticStyle: {
            "flex": "0 0 auto"
          },
          attrs: {
            "label": "받으시는분"
          },
          on: {
            "input": function input($event) {
              return _vm.input('RECEIVER_NAME');
            },
            "keydown": function keydown($event) {
              if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
              return _vm.save.apply(null, arguments);
            }
          },
          model: {
            value: _vm.item.receiver.name,
            callback: function callback($$v) {
              _vm.$set(_vm.item.receiver, "name", $$v);
            },
            expression: "item.receiver.name"
          }
        }, 'v-text-field', _vm.attrs_input, false)), _c('v-text-field', _vm._b({
          staticClass: "mt-4",
          staticStyle: {
            "flex": "0 0 auto"
          },
          attrs: {
            "label": "연락처"
          },
          on: {
            "input": function input($event) {
              return _vm.input('RECEIVER_PHONE');
            },
            "keydown": function keydown($event) {
              if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
              return _vm.save.apply(null, arguments);
            }
          },
          model: {
            value: _vm.item.receiver.phone,
            callback: function callback($$v) {
              _vm.$set(_vm.item.receiver, "phone", $$v);
            },
            expression: "item.receiver.phone"
          }
        }, 'v-text-field', _vm.attrs_input, false)), _c('v-divider', {
          staticClass: "mt-5 mb-6"
        }), _c('v-address-field', _vm._b({
          staticStyle: {
            "flex": "0 0 auto"
          },
          attrs: {
            "noRules": "",
            "btnAttrs": {
              className: 'mt-n2 mb-n1',
              color: 'secondary',
              height: '40'
            },
            "className": "mt-4"
          },
          on: {
            "input": function input($event) {
              return _vm.input('RECEIVER_ADDRESS');
            },
            "keydown": function keydown($event) {
              if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
              return _vm.save.apply(null, arguments);
            }
          },
          model: {
            value: _vm.item.receiver,
            callback: function callback($$v) {
              _vm.$set(_vm.item, "receiver", $$v);
            },
            expression: "item.receiver"
          }
        }, 'v-address-field', _vm.attrs_input, false))], 1)], 1)], 1)];
      },
      proxy: true
    }])
  }, {
    save: _vm.save
  }), [_vm._t("default")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }