var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-data-table', _vm._b({
    staticClass: "v-sheet--outlined",
    staticStyle: {
      "position": "relative"
    },
    attrs: {
      "mobile-breakpoint": 10000,
      "hide-default-footer": "",
      "disable-filtering": "",
      "disable-pagination": "",
      "disable-sort": ""
    },
    scopedSlots: _vm._u([{
      key: "top",
      fn: function fn() {
        return [_c('div', {
          staticClass: "table-label px-1 white"
        }, [_c('span', [_vm._v(" 선택 항목 주문상태 ")])])];
      },
      proxy: true
    }, {
      key: "item.total",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.selected.length) + " ")];
      },
      proxy: true
    }], null, true)
  }, 'v-data-table', {
    items: _vm.items,
    headers: _vm.headers
  }, false));
}
var staticRenderFns = []

export { render, staticRenderFns }