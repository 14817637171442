<template>
    <div><slot name="activator" v-bind="{ click, loading }" /></div>
</template>

<script>
import api from "@/api";
import XLSX from "xlsx";
import dayjs from "dayjs";

export default {
    data: () => ({
        loading: false,
    }),
    methods: {
        async click() {
            this.loading = true;
            try {
                let workbook = new XLSX.utils.book_new();
                let form = XLSX.utils.json_to_sheet([
                    {
                        ["보내는사람(지정)"]: "",
                        ["전화번호1(지정)"]: "",
                        ["주소(지정)"]: "",
                        ["받는사람"]: "",
                        ["전화번호1"]: "",
                        ["우편번호"]: "",
                        ["주소"]: "",
                        ["수량(A타입)"]: "",
                        ["상품명1"]: "",
                        // ["상품명(표기)"]: "",
                        ["배송메시지"]: "",
                        ["주문상태"]: "",
                        ["발주번호"]: "",
                    },
                ]);
                XLSX.utils.book_append_sheet(workbook, form, "발주양식");
                let rows = (this.selected || (await api.console.shop.products.gets())?.products).map(({ _id: _product, category, name, code }) => ({ _product, categoryFullName: category?.fullName, code, name }));
                let products = XLSX.utils.json_to_sheet(rows);
                XLSX.utils.book_append_sheet(workbook, products, "상품목록");
                XLSX.writeFile(workbook, `수동일괄 및 건별 발주양식-${dayjs().format("YYMMDDHHmmss")}.xlsx`.replaceAll(" ", "_"));
            } catch (error) {
                alert(error?.message || "오류가 발생했습니다");
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style>
</style>