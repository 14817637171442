<template>
    <v-layout justify-center>
        <v-responsive max-width="640" width="100%" height="100%" class="px-3 mx-n3">
            <list-heading title="사용자 그룹 목록">
                <template #add-button>
                    <user-group-input @input="updateItem">
                        <template #activator="{ attrs, on }">
                            <v-btn small fab tile color="transparent primary--text" class="my-n1" v-bind="attrs" v-on="on"> <v-icon>mdi-plus</v-icon> </v-btn>
                        </template>
                    </user-group-input>
                </template>
            </list-heading>

            <v-data-table v-bind="{ headers, items }" disable-sort disable-pagination hide-default-footer class="v-sheet--outlined">
                <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>
                <template #[`item.actions`]="{ item }">
                    <user-group-input v-bind="item" @input="updateItem">
                        <template #activator="{ attrs, on }">
                            <v-icon small v-bind="attrs" v-on="on"> mdi-pencil </v-icon>
                        </template>
                    </user-group-input>
                    <v-icon small @click="remove(item)" class="ml-2"> mdi-delete </v-icon>
                </template>
            </v-data-table>
            <v-pagination :value="page" :length="pageCount" :total-visible="11" color="primary" class="mt-2" @input="(page) => $router.push({ query: { ...$route.query, page } })"></v-pagination>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { USER_SCOPES, attrs_input } from "@/assets/variables";

import ListHeading from "@/components/console/dumb/list-heading.vue";
import UserGroupInput from "@/components/console/user/user-group-input.vue";

const headers = [
    { width: +60, text: "#", value: "index", divider: true },
    { width: 140, text: "그룹명", value: "name", divider: true },
    { width: 180, text: "권한", value: "scope", divider: true, formatter: (scope) => scope.map((item) => Object.values(USER_SCOPES).find(({ value }) => value == item)?.text), cellClass: "white-space-pre" },
    { width: 110, text: "생성일자", value: "createdAt", divider: true, formatter: (value) => value?.toDateTime?.() },
    { width: +80, text: "", value: "actions", align: "center" },
];

export default {
    components: {
        ListHeading,
        UserGroupInput,
    },
    data() {
        return {
            groups: [],

            limit: 10,
            summary: { totalCount: 0 },

            headers,
            attrs_input,
        };
    },
    computed: {
        items() {
            return this.groups.map((item, index) => ({ index: this.summary.totalCount - (this.page - 1) * this.limit - index, ...item }));
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { query } = this.$route;
            return { ...query };
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.search();
        },
    },
    methods: {
        async init() {
            try {
                this.search();
            } catch (error) {
                console.error(error);
            }
        },

        async search() {
            try {
                let { skip, limit, params } = this;
                var { summary, groups } = await api.console.users.groups.gets({
                    headers: { skip, limit },
                    params,
                });

                this.groups = groups;
                this.summary = summary;
            } catch (error) {
                this.$handleError(error);
            }
        },

        async update(group) {
            try {
                group = (await api.console.users.groups.put(group))?.group;
                this.updateItem(group);
                alert("성공적으로 처리되었습니다.");
            } catch (error) {
                this.$handleError(error);
            }
        },

        async remove(group) {
            const go = confirm(`"${group?.name}" 사용자 그룹을 삭제하시겠습니까?`);
            if (go) {
                try {
                    [{ group }] = [await api.console.users.groups.delete(group)];
                    this.groups = this.groups.filter(({ _id }) => _id !== group._id);
                    alert(`"${group?.name}" 사용자 그룹이 삭제되었습니다.`);
                    this.summary.totalCount -= 1;
                } catch (error) {
                    this.$handleError(error);
                }
            }
        },

        updateItem(group) {
            const index = this.groups.findIndex(({ _id }) => _id == group._id);
            if (0 <= index) this.groups.splice(index, 1, group);
            else {
                this.groups = [group, ...this.groups];
                this.summary.totalCount += 1;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .white-space-pre {
        white-space: pre;
    }
    .v-pagination button {
        box-shadow: none !important;
        border: thin solid rgba(0, 0, 0, 0.12);
    }
}
</style>