<template>
    <v-sheet>
        <v-card-subtitle class="px-6">{{ subtitle }}</v-card-subtitle>
        <v-divider />
        <v-card-text>
            <v-row>
                <v-col cols="6"> <autocomplete-user-group v-model="form._ordererGroup" label="주문자그룹" v-bind="attrs_input" @input="((form._orderer = null) || 1) && emit()" /> </v-col>
                <v-col cols="6"> <autocomplete-user v-model="form._orderer" label="주문자" v-bind="{ ...attrs_input, params, disabled }" ref="autocomplete-user" @input="emit" /> </v-col>
            </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
            <v-btn x-large text block color="primary" :disabled="!form._orderer" @click="$emit('forward')">선택완료</v-btn>
        </v-card-actions>
    </v-sheet>
</template>

<script>
import { attrs_input } from "@/assets/variables";

import AutocompleteUser from "@/components/console/dumb/autocomplete-user.vue";
import AutocompleteUserGroup from "@/components/console/dumb/autocomplete-user-group.vue";

export default {
    components: {
        AutocompleteUser,
        AutocompleteUserGroup,
    },
    props: {
        value: { type: Object, default: () => ({ _orderer: null, _ordererGroup: null }) }, // form
    },
    data: () => ({
        attrs_input,
        form: {
            _orderer: null,
            _ordererGroup: null,
        },
    }),
    computed: {
        params() {
            return { _group: this.form?._ordererGroup };
        },
        disabled() {
            return !this.form?._ordererGroup;
        },
        subtitle() {
            return this.disabled ? "그룹을 선택하세요" : "주문자를 선택하세요";
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.form = { ...this.value };
        },
        emit() {
            this.$emit("input", this.form);
        },
    },
};
</script>

<style>
</style>