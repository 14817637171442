<template>
    <list-search v-bind="{ showsSearch, loading }" v-on="{ search }">
        <v-row>
            <v-col cols="6" lg="3">
                <v-select v-model="query.searchDateKey" label="검색기준" :items="dateTypes" v-bind="attrs_input" />
            </v-col>
            <v-col cols="6" lg="3">
                <v-text-field v-model="query.searchDateValue[0]" label="시작일시" type="date" clearable v-bind="attrs_input" />
            </v-col>
            <v-col cols="6" lg="3">
                <v-text-field v-model="query.searchDateValue[1]" label="종료일시" type="date" clearable v-bind="attrs_input" />
            </v-col>
            <v-col cols="6" lg="3" class="d-flex align-center">
                <v-btn-toggle v-model="query.searchDateValue" dense color="primary">
                    <v-btn v-for="{ text, value } in dateToggleItems" :key="text" v-bind="{ value }" height="40" color="white">{{ text }}</v-btn>
                </v-btn-toggle>
            </v-col>
            <v-col cols="6" lg="3" v-if="hasStateFilter">
                <v-select v-model="query.state" :items="states" label="주문상태" placeholder="전체" clearable multiple v-bind="attrs_input" />
            </v-col>
            <v-col cols="6" lg="3" v-if="hasStateFilter">
                <v-select v-model="query.updatedColumn" :items="columns" label="변경내역" placeholder="전체" clearable v-bind="attrs_input" />
            </v-col>
            <v-col :cols="hasStateFilter ? 6 : 12" lg="3">
                <v-select v-model="query.type" :items="types" item-text="textShorten" label="발주유형" placeholder="전체" clearable v-bind="attrs_input" />
            </v-col>
            <v-col col="6" lg="3">
                <v-select v-model="query.searchKey" :items="searchKeys" item-text="text" item-value="value" label="검색대상" placeholder="전체" clearable v-bind="attrs_input" />
            </v-col>
            <v-col col="6" lg="3">
                <v-select v-model="query._orderers" :items="users" item-text="username" item-value="_id" label="주문자" multiple clearable v-bind="attrs_input" />
            </v-col>
            <v-col col="6" lg="3">
                <v-text-field v-model="query.searchValue" label="검색어" clearable v-bind="attrs_input" @keydown.enter="search" />
            </v-col>
        </v-row>
    </list-search>
</template>

<script>
import api from "@/api";
import dayjs from "dayjs";
import { attrs_input, PURCHASE_ORDER_STATES, PURCHASE_ORDER_TYPES, PURCHASE_ORDER_UPDATE_COLUMN } from "@/assets/variables";
import AutocompleteUser from "@/components/console/dumb/autocomplete-user.vue";
import AutocompleteUserGroup from "@/components/console/dumb/autocomplete-user-group.vue";

import ListSearch from "../../dumb/list-search.vue";

const initQuery = () => ({
    type: null,
    state: null,
    searchKey: null,
    searchValue: null,
    searchDateValue: [null, null],
});

const types = Object.values(PURCHASE_ORDER_TYPES);
const states = Object.values(PURCHASE_ORDER_STATES);
const columns = Object.values(PURCHASE_ORDER_UPDATE_COLUMN);

export default {
    components: {
        ListSearch,
        AutocompleteUser,
        AutocompleteUserGroup,
    },
    props: {
        excel: { type: String, default: null },
        types: { type: Array, default: types },
        states: { type: Array, default: states },
        columns: { type: Array, default: columns },
        loading: { type: Boolean, default: false },
        showsSearch: { type: Boolean, default: false },
    },
    data: () => ({
        attrs_input,

        query: initQuery(),
        users: [],
    }),
    computed: {
        hasStateFilter() {
            return (this.states || []).length > 1;
        },
        dateTypes() {
            let items = [
                { text: "입고/발송일", value: "targetsAt" },
                { text: "발주일시", value: "createdAt" },
            ];
            if (this.hasStateFilter) items.push({ text: "발송처리일", value: "processedAt" });
            return items;
        },
        dateToggleItems() {
            return [
                { text: "오늘", value: [Date.now().toDate(), Date.now().toDate()] },
                { text: "어제", value: [dayjs().subtract(1, "day").toISOString().toDate(), dayjs().subtract(1, "day").toISOString().toDate()] },
            ];
        },
        searchKeys() {
            const items = [];
            items.push({ text: "발주번호", value: "number" });
            items.push({ text: "발주차수", value: "purchaseOrderNumber" });
            items.push({ text: "상품명", value: "products.name" });
            if (["upload", "invoice"].includes(this.excel)) {
                items.push({ text: "보내시는분 성함", value: "sender.name" });
                items.push({ text: "보내시는분 연락처", value: "sender.phone" });
                items.push({ text: "받으시는분 성함", value: "receiver.name" });
                items.push({ text: "받으시는분 연락처", value: "receiver.phone" });
                items.push({ text: "받으시는분 기본주소", value: "receiver.address1" });
                items.push({ text: "받으시는분 상세주소", value: "receiver.address2" });
                items.push({ text: "암호코드", value: "products.code" });
                items.push({ text: "수량", value: "products.quantity" });
            }
            return items;
        },
    },
    async mounted() {
        let { users } = await api.console.users.gets({});
        this.users = users;
        this.sync();
    },
    watch: {
        "$route.path"() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.query = {
                type: this.$route.query?.type || null,
                state: this.$route.query?.state || null,
                updatedColumn: this.$route.query?.updatedColumn || null,
                searchKey: this.$route.query?.searchKey || null,
                searchValue: this.$route.query?.searchValue || null,
                memberSearchValue: this.$route.query?.memberSearchValue || null,
                searchDateKey: this.$route.query?.searchDateKey || null,
                searchDateValue: JSON.parse(JSON.stringify(this.$route.query?.searchDateValue || [null, null])),
            };
        },
        search() {
            let { ...query } = this.query;

            if (!query.type) delete query.type;
            if (!query.state) delete query.state;
            if (!query.updatedColumn) delete query.updatedColumn;
            if (!query.searchKey) delete query.searchKey;
            if (!query.searchValue) delete query.searchValue;
            if (!query.memberSearchValue) delete query.memberSearchValue;
            if (!query.searchDateKey) delete query.searchDateKey;
            if (!query.searchDateValue.some((item) => item != null)) delete query.searchDateValue;

            query.page = 1;
            query._manager = this.$route.query?._manager;
            if (!query._manager) delete query._manager;

            this.$router.push({ query });
        },
    },
};
</script>

<style></style>
