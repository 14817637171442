<template>
    <v-layout justify-center>
        <v-responsive max-width="880" width="100%" height="100%" class="px-3 mx-n3">
            <list-heading title="관리자 목록">
                <template #add-button>
                    <admin-form @input="search">
                        <template #activator="{ attrs, on }">
                            <v-btn small fab tile color="transparent primary--text" class="my-n1" v-bind="attrs" v-on="on"> <v-icon>mdi-plus</v-icon> </v-btn>
                        </template>
                    </admin-form>
                </template>
            </list-heading>

            <v-data-table v-model="selected" v-bind="{ loading, headers, items }" disable-sort disable-pagination hide-default-footer class="v-sheet--outlined">
                <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value }">{{ header.formatter(value) }}</template>
                <template #[`item.password`]="{ value }">
                    <v-row align="center" no-gutters>
                        <v-col class="pr-2">
                            <v-hover v-slot="{ hover }">
                                <code class="d-inline-flex justify-center"> {{ hover ? value : value.replaceAll(/./g, "*") }} </code>
                            </v-hover>
                        </v-col>
                        <v-col cols="auto">
                            <v-btn x-small outlined icon color="grey" @click="copy(value)">
                                <v-icon x-small color="black">mdi-content-copy</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </template>
                <template #[`item.actions`]="{ item }">
                    <admin-form v-bind="item" @input="search">
                        <template #activator="{ attrs, on }">
                            <v-icon small v-bind="attrs" v-on="on"> mdi-pencil </v-icon>
                        </template>
                    </admin-form>
                    <v-icon small @click="remove(item)" class="ml-2"> mdi-delete </v-icon>
                </template>
            </v-data-table>
            <v-pagination :value="page" :length="pageCount" :total-visible="11" color="primary" class="mt-2" @input="(page) => $router.push({ query: { ...$route.query, page } })"></v-pagination>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import CryptoAES from "@/plugins/crypto-aes";
import { attrs_switch, USER_SCOPES } from "@/assets/variables";

import AdminForm from "@/components/console/user/admin-form.vue";
import ListHeading from "@/components/console/dumb/list-heading.vue";

const headers = [
    { width: +70, text: "#", value: "index", align: "end" },
    { width: 120, text: "관리자명", value: "name" },
    { width: 140, text: "아이디", value: "username" },
    { width: 180, text: "비밀번호", value: "password" },
    { width: 160, text: "담당그룹", value: "groups__manage", cellClass: "white-space-pre", formatter: (value) => value?.map?.(({ name }) => name) },
    { width: 100, text: "생성일자", value: "createdAt", formatter: (value) => value?.toDateTime?.() },
    { width: +80, text: "", value: "actions", align: "center" },
].map((item, index, array) => ({ ...item, divider: index != array.length - 1, formatter: item.formatter ?? ((value) => value ?? "-"), cellClass: (item?.cellClass || "") + " caption line-height-1-5" }));

export default {
    components: {
        AdminForm,
        ListHeading,
    },
    data: () => ({
        loading: false,
        showsSearch: true,
        attrs_switch,
        headers,

        users: [],
        selected: [],

        limit: 10,
        summary: { totalCount: 0 },
    }),
    computed: {
        items() {
            return [...this.users];
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;
            query.scope = {
                $in: [USER_SCOPES.console.value],
                $nin: [USER_SCOPES.executive.value],
            };
            return { ...query };
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.search();
        },
    },
    methods: {
        async init() {
            try {
                this.search();
            } catch (error) {
                console.error(error);
            }
        },
        async search() {
            this.loading = true;
            try {
                let { skip, limit, params } = this;
                var { summary, users } = await api.console.users.gets({
                    headers: { skip, limit },
                    params,
                });

                this.summary = summary;
                this.users = users.map((item, index) => ({ index: summary.totalCount - (this.page - 1) * this.limit - index, ...item, password: CryptoAES.decrypt(item.password) }));
            } finally {
                this.loading = false;
            }
        },

        async update(user) {
            try {
                user = (await api.console.users.put(user))?.user;
                this.updateItem(user);
                this.search();
            } catch (error) {
                this.$handleError(error);
            }
        },

        async remove(user) {
            const go = confirm(`관리자 (ID: ${user?.username})를 삭제하시겠습니까?`);
            if (go) {
                try {
                    await api.console.users.delete(user);
                    this.users = this.users.filter(({ _id }) => _id !== user._id);
                    alert(`관리자 (ID: ${user?.username})가 삭제되었습니다.`);
                    this.summary.totalCount -= 1;
                    this.search();
                } catch (error) {
                    this.$handleError(error);
                }
            }
        },

        updateItem(user) {
            const index = this.users.findIndex(({ _id }) => _id == user._id);
            if (0 <= index) this.users.splice(index, 1, user);
            else {
                this.users = [user, ...this.users];
                this.summary.totalCount += 1;
            }
        },

        updateItems(users) {
            for (const user of users) {
                this.updateItem(user);
            }
        },

        copy(value) {
            navigator.clipboard.writeText(value);
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .white-space-pre {
        white-space: pre;
    }
    .white-space-pre-line {
        white-space: pre-line;
    }
    .v-pagination button {
        box-shadow: none !important;
        border: thin solid rgba(0, 0, 0, 0.12);
    }
}
</style>