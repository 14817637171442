<template>
    <v-layout justify-center>
        <v-responsive max-width="1280px" width="100%">

            <v-row class="mt-8" justify="center" align="center">
                <v-col cols="auto" class="pt-0 pb-0 headline text-start">폼메일 목록</v-col>
                <v-spacer></v-spacer>
                <v-col cols="auto" class="pt-0 pb-0">
                    <v-btn class="ma-2" color="secondary" rounded @click="showsSearch = !showsSearch"><v-icon small class="mr-2">mdi-filter-variant</v-icon>검색필터</v-btn>
                </v-col>
            </v-row>

            <v-expand-transition>
                <v-card v-show="showsSearch" class="elevation-1 mx-auto">
                    <v-card-title class="subtitle-2 font-weight-bold">검색필터</v-card-title>
                    <v-card-text class="pt-4 pb-0">
                        <v-row>
                            <v-col cols="12" sm="6" class="py-0">
                                <v-date-field v-model="filter.createdAt[0]" label="작성일자" persistent-placeholder dense hide-details></v-date-field>
                            </v-col>
                            <v-col cols="12" sm="6" class="py-0">
                                <v-date-field v-model="filter.createdAt[1]" label="작성일자" persistent-placeholder dense hide-details></v-date-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6" md="3">
                                <v-select v-model="filter.searchKey" :items="searchKeys" label="검색조건" persistent-placeholder></v-select>
                            </v-col>
                            <v-col cols="6" md="3">
                                <v-text-field v-model="filter.searchValue" label="검색어" persistent-placeholder :disabled="!filter.searchKey"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" rounded text @click="page = 1; search()"><v-icon small class="mt-1 mr-2">mdi-magnify</v-icon>검색</v-btn>
                    </v-card-actions>
                </v-card>
            </v-expand-transition>

            <v-data-table :items="forms" :headers="formsHeaders.filter(header => header.code ? header.code.includes($route.query.code) : true)" disable-sort disable-pagination hide-default-footer class="elevation-1 mt-4">
                <template v-slot:item.no="{ item }">
                    {{ summary.totalCount - (page - 1) * limit - forms.indexOf(item) }}
                </template>
                <template v-slot:item.createdAt="{ item }">
                    {{ item.createdAt.toDateTime() }}
                </template>
                <template v-slot:item.actions="{item}">
                    <v-icon small @click="edit(item)" title="수정">mdi-pencil</v-icon>
                    <v-icon v-if="false" small @click="copy(item)" title="복사" class="ml-2">mdi-content-copy</v-icon>
                    <v-icon small @click="remove(item)" title="삭제" class="ml-2">mdi-delete</v-icon>
                </template>
            </v-data-table>
            <v-pagination v-model="page" :length="pageCount" :total-visible="11" class="mt-4 mb-12" @input="search()"></v-pagination>

        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import VDateField from "@/components/plugins/vuetify/v-date-field.vue";

export default {
    components: {
        VDateField
    },
    data() {
        return {
            ///////////////////////////////////////////////
            // 검색필터
            ///////////////////////////////////////////////
            showsSearch: true,

            category: null,
            filter: {
                code: this.$route.query.code,
                searchKey: this.$route.query.searchKey,
                searchValue: this.$route.query.searchValue,
                createdAt: [(this.$route.query.createdAt || [])[0], (this.$route.query.createdAt || [])[1]]
            },

            page: +this.$route.query.page || 1,
            pageCount: 0,
            limit: 10,

            summary: { totalCount: 0 },
            forms: [],
            formsHeaders: [{
                text: "no",
                align: "center",
                width: 50,
                value: "no"
            },
            {
                text: '작성자',
                align: 'center',
                width: 50,
                value: 'writer.name',
            },
            {
                text: '제목',
                align: 'center',
                width: 250,
                value: 'subject',
            },
            {
                text: '기간',
                width: 100,
                align: 'center',
                code: ["store","event"],
                value: 'period',
            },
            {
                text: '작성일',
                align: 'center',
                width: 80,
                value: 'createdAt',
            },
            {
                text: 'Actions',
                width: 100,
                align: 'center',
                value: 'actions',
            }],

            searchKeys: [
                { text: "전체", value: null },
                // { text: "작성자", value: "writer.name" },
                { text: "제목", value: "subject" },
                { text: "내용", value: "content" },
            ],
            searchKey: null,
            searchValue: null,

        };
    },
    mounted(){
        this.init();
    },
    methods: {
        init(){
            this.search(false);
        },
        async search(routable = true){
            try{
                if(routable){
                    this.$router.push({
                        query: Object.assign({}, this.filter, { page: this.page })
                    });
                }

                var { summary, forms } = await api.console.forms.gets({
                    headers: {
                        skip: (this.page - 1) * this.limit,
                        limit: this.limit
                    },
                    params: this.filter
                });

                this.forms = forms;
                this.summary = summary;
                this.pageCount = Math.ceil(summary.totalCount / this.limit);
            }
            catch(error){
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        edit(form){
            this.$router.push(`/console/forms/${form._id}`)
        },
        async remove(form){
            try{
                if(confirm("폼메일을 삭제하시겠습니까?")){
                    await api.console.forms.delete(form);
                    await this.search(false);

                    alert("삭제되었습니다");
                }
            }
            catch(error){
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
    },
    watch: {
        $route(){
            this.search(false);

            Object.assign(this.filter, this.$route.query);
            this.page = +this.$route.query.page || 1;
        }
    }
}
</script>
