<template>
    <v-dialog v-model="shows" width="320">
        <template #activator="{ attrs, on }">
            <v-btn text tile class="mr-n4" v-bind="attrs" v-on="on">{{ item?.address || "-" }}</v-btn>
        </template>
        <v-card>
            <v-card-text class="pa-4">
                <v-address-field v-model="item" no-rules class="mb-n3" className="mb-3" v-bind="{ ...$attrs, btnAttrs }" v-on="{ emit }" />
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import VAddressField from "@/components/dumb/v-address-field.vue";

export default {
    components: {
        VAddressField,
    },
    props: {
        value: { type: Object, default: () => ({}) }, // item
        btnAttrs: { type: Object, default: () => ({ className: "mt-n2 mb-n1", height: "40px", color: "secondary" }) },
    },
    data: () => ({
        shows: false,
        item: null,
    }),
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.item = JSON.parse(JSON.stringify(this.value));
        },
        emit() {
            this.$emit("input", this.item);
            setTimeout(() => (this.shows = false), 50);
        },
    },
};
</script>

<style>
</style>