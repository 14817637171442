var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-data-table', _vm._b({
    staticClass: "transparent",
    attrs: {
      "dense": "",
      "disable-filtering": "",
      "disable-sort": "",
      "hide-default-footer": ""
    },
    scopedSlots: _vm._u([{
      key: "top",
      fn: function fn() {
        return [_c('v-card-subtitle', {
          staticClass: "d-flex font-weight-bold",
          staticStyle: {
            "background-color": "rgba(0, 0, 0, 0.05)"
          }
        }, [_vm.$slots['title'] ? _vm._t("title") : _c('span', [_vm._v("기본 정보")])], 2), _c('v-divider')];
      },
      proxy: true
    }, _vm._l(_vm.headers.filter(function (header) {
      return header.hasOwnProperty('formatter');
    }), function (header) {
      return {
        key: "item.".concat(header.value),
        fn: function fn(_ref) {
          var value = _ref.value;
          return [_vm._v(" " + _vm._s(header.formatter(value)) + " ")];
        }
      };
    }), {
      key: "footer",
      fn: function fn() {
        return [_c('v-divider')];
      },
      proxy: true
    }], null, true)
  }, 'v-data-table', {
    headers: _vm.headers,
    items: _vm.items
  }, false));
}
var staticRenderFns = []

export { render, staticRenderFns }