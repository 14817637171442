<template>
    <v-dialog v-model="shows" max-width="880" persistent :fullscreen="!$vuetify.breakpoint.lgAndUp">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on, disabled }" />
        </template>
        <v-card tile>
            <v-card-title>선택 받으시는분 변경</v-card-title>
            <v-card-text class="pb-0">
                <v-row class="mx-n2">
                    <v-col cols="12" lg="7" class="pa-2">
                        <v-row class="mx-n2">
                            <v-col cols="12" class="pa-2"> <table-receiver-summary v-bind="{ selected }" /> </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" lg="5" class="pa-2">
                        <v-row class="mx-n2">
                            <v-col cols="12" class="pa-2"> <v-text-field v-model="receiver.name" v-bind="{ ...attrs_input, disabled }" label="받으시는분" @input="input('RECEIVER_NAME')"/> </v-col>
                            <v-col cols="12" class="pa-2"> <v-phone-field v-model="receiver.phone" v-bind="{ ...attrs_input, disabled }" :colAttrs="{ class: 'pa-2' }" :rowAttrs="{ class: 'ma-n2' }" label="연락처" @input="input('RECEIVER_PHONE')"/> </v-col>
                            <v-col cols="12" class="pa-2 pt-0 mt-n2"> <v-address-field v-model="receiver" noRules className="mt-4" style="flex: 0 0 auto" :btnAttrs="{ className: 'mt-n2 mb-n1', color: 'secondary', height: '40' }" v-bind="{ ...attrs_input, disabled }" label="연락처" @input="input('RECEIVER_ADDRESS')"/> </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions class="justify-end">
                <v-subheader class="caption">
                    선택된 항목 총
                    <span class="mx-1 text-subtitle-1 font-weight-bold primary--text">
                        {{ selected.length }}
                    </span>
                    건
                </v-subheader>
                <v-spacer />
                <v-btn text color="primary" @click="shows = false">취소</v-btn>
                <v-btn text color="primary" v-bind="{ disabled }" @click="save">변경</v-btn>
            </v-card-actions>
            <v-fade-transition>
                <v-overlay v-show="loading" absolute light color="white">
                    <v-sheet width="240" rounded style="overflow: hidden">
                        <v-progress-linear :value="(updatedLength / selected.length) * 100" height="24">
                            <span> {{ updatedLength }} / {{ selected.length }} </span>
                        </v-progress-linear>
                    </v-sheet>
                </v-overlay>
            </v-fade-transition>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import { attrs_input, PURCHASE_ORDER_UPDATE_COLUMN } from "@/assets/variables";

import VPhoneField from '@/components/plugins/vuetify/v-phone-field.vue';
import VAddressField from "@/components/dumb/v-address-field.vue";
import TableReceiverSummary from "./table-receiver-summary.vue";

const initReceiver = () => ({ name: null, phone: null });

export default {
    components: {
        VPhoneField,
        VAddressField,
        TableReceiverSummary,
    },
    props: {
        selected: { type: Array, default: () => [] },
    },
    data: () => ({
        shows: false,
        loading: false,

        receiver: initReceiver(),

        updatedLength: 0,

        attrs_input,
        updatedColumn: null
    }),
    computed: {
        disabled() {
            return !this.selected.length;
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        shows(shows) {
            if (shows) this.init();
        },
    },
    methods: {
        init() {
            this.updatedLength = 0;
            this.receiver = initReceiver();
        },
        async save() {
            if (this.loading) return;
            this.loading = true;

            try {
                let { receiver } = this;
                let form = { receiver };

                let orders = [];
                for (const { _id } of this.selected) {
                    orders.push((await api.console.shop.purchaseOrders.put({ _id, ...form, updatedColumn: this.updatedColumn }))?.order);
                    this.updatedLength += 1;
                }
                setTimeout(() => {
                    alert("변경되었습니다");
                    this.$emit("updateItems", orders);
                    this.loading = false;
                    this.shows = false;
                }, 500);
            } finally {
                setTimeout(() => {
                    if (this.loading) this.loading = false;
                }, 500);
            }
        },
        input(value) {
            this.updatedColumn = value
        },
    },
};
</script>

<style>
</style>