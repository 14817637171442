var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.type == _vm.ADMIN_UPLOADED_ORDER_BC.value ? _c('process-data-bc', _vm._g(_vm._b({}, 'process-data-bc', {
    value: _vm.value,
    file: _vm.file,
    getProductByCodeAlt: _vm.getProductByCodeAlt
  }, false), {
    input: _vm.input,
    loading: _vm.loading
  })) : _vm.type == _vm.ADMIN_UPLOADED_ORDER_CU.value ? _c('process-data-cu', _vm._g(_vm._b({}, 'process-data-cu', {
    value: _vm.value,
    file: _vm.file,
    getProductByCodeAlt: _vm.getProductByCodeAlt
  }, false), {
    input: _vm.input,
    loading: _vm.loading
  })) : _vm.type == _vm.ADMIN_UPLOADED_ORDER_DC.value ? _c('process-data-dc', _vm._g(_vm._b({}, 'process-data-dc', {
    value: _vm.value,
    file: _vm.file,
    getProductByCodeAlt: _vm.getProductByCodeAlt
  }, false), {
    input: _vm.input,
    loading: _vm.loading
  })) : _vm.type == _vm.ADMIN_MANUAL_ORDER_BULK.value ? _c('process-data-mb', _vm._g(_vm._b({}, 'process-data-mb', {
    value: _vm.value,
    file: _vm.file,
    getProductByCodeAlt: _vm.getProductByCodeAlt
  }, false), {
    input: _vm.input,
    loading: _vm.loading
  })) : _vm.type == _vm.ADMIN_UPLOADED_ORDER_SH.value ? _c('process-data-sh', _vm._g(_vm._b({}, 'process-data-sh', {
    value: _vm.value,
    file: _vm.file,
    getProductByCodeAlt: _vm.getProductByCodeAlt
  }, false), {
    input: _vm.input,
    loading: _vm.loading
  })) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }