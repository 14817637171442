<template>
    <v-data-table v-bind="{ headers, items, hideDefaultHeader }" dense disable-filtering disable-sort hide-default-footer :items-per-page="-1">
        <template #no-data> {{ noDataText }} </template>
        <template #[`item.quantity`]="{ item }">
            <template v-if="readonly"> {{ item.quantity }} </template>
            <v-text-field v-else v-model="item.quantity" type="number" dense hide-details class="border-bottom-0 text-center-field" @input="update(item)" />
        </template>
        <template #[`item.actions`]="{ item }">
            <v-btn text icon @click="remove(item)"> <v-icon>mdi-close</v-icon> </v-btn>
        </template>
    </v-data-table>
</template>

<script>

export default {
    props: {
        value: { type: Array, default: () => [] }, // products
        readonly: { type: Boolean, default: false },
        noDataText: { type: String, default: "선택한 상품이 없습니다" },
        hideRemoveBtn: { type: Boolean, default: false },
        hideDefaultHeader: { type: Boolean, default: false },
    },
    data: () => ({
        items: [],
    }),
    computed: {
        headers() {
            let headers = [];
            headers.push({ width: "auto", text: "상품명", value: "name" });
            headers.push({ width: +80, text: "수량", value: "quantity", align: "center" });
            if (!this.hideRemoveBtn) headers.push({ width: +24, text: "", value: "actions", class: "pa-0", cellClass: "pa-0" });
            return headers;
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.items = JSON.parse(JSON.stringify(this.value));
        },
        emit() {
            this.$emit("input", this.items);
        },
        update(item) {
            let index = this.items.findIndex(({ _id }) => _id == item._id);
            if (index >= 0) this.items.splice(index, 1, item);
            else this.items = [...this.items, item];
            this.$nextTick(this.emit);
        },
        remove(item) {
            this.items = this.items.filter(({ _id }) => _id != item._id);
            this.$nextTick(this.emit);
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .border-bottom-0 .v-input__slot::before {
        content: none;
    }

    .text-center-field input {
        text-align: center;
    }
}
</style>