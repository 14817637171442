var render = function () {
  var _vm$value;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return (_vm$value = _vm.value) !== null && _vm$value !== void 0 && _vm$value.receiver ? _c('v-data-table', _vm._b({
    staticClass: "transparent",
    attrs: {
      "dense": "",
      "disable-filtering": "",
      "disable-sort": "",
      "hide-default-footer": ""
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function fn() {
        return [_c('v-divider')];
      },
      proxy: true
    }], null, false, 175997271)
  }, 'v-data-table', {
    headers: _vm.headers,
    items: _vm.items
  }, false)) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }