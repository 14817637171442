<template>
    <span v-if="readonly"> {{ item?.name || "-" }} </span>
    <v-autocomplete v-else v-model="$data._user" v-bind="{ ...$attrs, items, itemText, itemValue: '_id' }" @click="alert" />
</template>

<script>
import api from "@/api";

export default {
    props: {
        value: { type: [String, Array], default: null },
        params: { type: [Object, Array], default: () => ({}) },
        readonly: { type: Boolean, default: false },
    },
    data: () => ({
        _user: null,
        items: [],
    }),
    computed: {
        item() {
            return this.items.find(({ _id }) => _id == this.$data._user);
        },
    },
    mounted() {
        this.sync();
        this.init();
    },
    watch: {
        value() {
            this.sync();
        },
        params() {
            this.init();
        },
        "$data._user"(_user) {
            this.$emit("input", _user);
        },
    },
    methods: {
        sync() {
            this.$data._user = this.value;
        },
        async init() {
            let { params } = this;
            this.items = (await api.console.users.gets({ params }))?.users || [];
        },
        itemText({ name }) {
            return `${name}`;
        },
        alert() {
            if (this.$attrs.readonly && this.$attrs.readonlyMessage) alert(this.$attrs.readonlyMessage);
            if (this.$attrs.disabled && this.$attrs.disabledMessage) alert(this.$attrs.readonlyMessage);
        },
    },
};
</script>

<style></style>
