<template>
    <v-layout justify-center>
        <v-responsive max-width="1160" width="100%" height="100%" class="px-3 mx-n3">
            <list-heading title="사용자 목록" showsFilterButton v-model="showsSearch">
                <template #add-button>
                    <user-input @input="search">
                        <template #activator="{ attrs, on }">
                            <v-btn small fab tile color="transparent primary--text" class="my-n1" v-bind="attrs" v-on="on"> <v-icon>mdi-plus</v-icon> </v-btn>
                        </template>
                    </user-input>
                </template>
            </list-heading>

            <users-search v-bind="{ showsSearch }" />

            <v-row no-gutters class="mr-n2">
                <v-col cols="" md="auto" class="mb-2 mr-2">
                    <dialog-is-order-disabled-update v-bind="{ selected }" v-on="{ updateItems }">
                        <template #activator="{ attrs, on, disabled }">
                            <v-card outlined rounded v-bind="{ disabled }">
                                <v-btn block color="white secondary--text" v-bind="{ ...attrs }" v-on="on"> <v-icon class="mr-1">mdi-form-select</v-icon> 선택 발주제한 </v-btn>
                            </v-card>
                        </template>
                    </dialog-is-order-disabled-update>
                </v-col>
            </v-row>

            <v-data-table v-model="selected" v-bind="{ loading, headers, items }" show-select disable-sort disable-pagination hide-default-footer class="v-sheet--outlined">
                <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>
                <template #[`item.group`]="{ value }">
                    <v-menu max-width="320" v-if="value">
                        <template #activator="{ attrs, on }">
                            <div v-bind="attrs" v-on="on">{{ value.name || "-" }}</div>
                        </template>
                        <v-card>
                            <v-card-subtitle> 그룹명: {{ value.name }} </v-card-subtitle>
                            <v-card-text class="white-space-pre">권한: {{ value.scopeText }}</v-card-text>
                        </v-card>
                    </v-menu>
                    <template v-else> - </template>
                </template>
                <template #[`item.password`]="{ value }">
                    <v-row align="center" no-gutters>
                        <v-col class="pr-2">
                            <v-hover v-slot="{ hover }">
                                <code class="d-inline-flex justify-center"> {{ hover ? value : value.replaceAll(/./g, "*") }} </code>
                            </v-hover>
                        </v-col>
                        <v-col cols="auto">
                            <v-btn x-small outlined icon color="grey" @click="copy(value)">
                                <v-icon x-small color="black">mdi-content-copy</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </template>
                <template #[`item.isOrderDisabled`]="{ item }">
                    <v-switch v-model="item.isOrderDisabled" dense v-bind="attrs_switch" @change="(isOrderDisabled) => update({ _id: item._id, isOrderDisabled })" />
                </template>
                <template #[`item.actions`]="{ item }">
                    <user-input v-bind="item" @input="search">
                        <template #activator="{ attrs, on }">
                            <v-icon small v-bind="attrs" v-on="on"> mdi-pencil </v-icon>
                        </template>
                    </user-input>
                    <v-icon small @click="remove(item)" class="ml-2"> mdi-delete </v-icon>
                </template>
            </v-data-table>
            <v-pagination :value="page" :length="pageCount" :total-visible="11" color="primary" class="mt-2" @input="(page) => $router.push({ query: { ...$route.query, page } })"></v-pagination>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import CryptoAES from "@/plugins/crypto-aes";
import { attrs_switch } from "@/assets/variables";

import UserInput from "@/components/console/user/user-input.vue";
import UsersSearch from "@/components/console/user/users-search.vue";
import ListHeading from "@/components/console/dumb/list-heading.vue";
import DialogIsOrderDisabledUpdate from "@/components/console/user/dialog-is-order-disabled-update.vue";

const headers = [
    { width: +60, text: "#", value: "index", divider: true, align: "end" },
    { width: 170, text: "그룹", value: "group", divider: true },
    { width: 150, text: "지점명", value: "name", divider: true },
    { width: 180, text: "아이디", value: "username", divider: true },
    { width: 180, text: "비밀번호", value: "password", divider: true },
    { width: +80, text: "발주제한", value: "isOrderDisabled", divider: true, class: "px-0", align: "center" },
    { width: 170, text: "생성일자", value: "createdAt", formatter: (value) => value?.toDateTime?.(), divider: true },
    { width: +80, text: "", value: "actions", align: "center" },
].map((item) => ({ ...item, formatter: item?.formatter || ((value) => value || "-") }));

export default {
    components: {
        UserInput,
        UsersSearch,
        ListHeading,
        DialogIsOrderDisabledUpdate,
    },
    data: () => ({
        loading: false,
        showsSearch: true,
        attrs_switch,
        headers,

        users: [],
        selected: [],

        limit: 10,
        summary: { totalCount: 0 },
    }),
    computed: {
        items() {
            return [...this.users];
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { query } = this.$route;
            return { ...query };
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.search();
        },
    },
    methods: {
        async init() {
            try {
                this.search();
            } catch (error) {
                console.error(error);
            }
        },
        async search() {
            this.loading = true;
            try {
                let { skip, limit, params } = this;
                var { summary, users } = await api.console.users.gets({
                    headers: { skip, limit },
                    params,
                });

                this.summary = summary;
                this.users = users.map((item, index) => ({
                    index: summary.totalCount - (this.page - 1) * this.limit - index,
                    ...item,
                    password: item.password ? CryptoAES.decrypt(item.password) : item.password,
                }));
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },

        create() {
            this.$router.push(`/console/users/create`);
        },

        edit(user) {
            this.$router.push({
                path: `/console/users/${user._id}`,
            });
        },

        async update(user) {
            try {
                user = (await api.console.users.put(user))?.user;
                this.updateItem(user);
                this.search();
            } catch (error) {
                this.$handleError(error);
            }
        },

        async remove(user) {
            const go = confirm(`사용자 (ID: ${user?.username})를 삭제하시겠습니까?`);
            if (go) {
                try {
                    await api.console.users.delete(user);
                    this.users = this.users.filter(({ _id }) => _id !== user._id);
                    alert(`사용자 (ID: ${user?.username})가 삭제되었습니다.`);
                    this.summary.totalCount -= 1;
                    this.search();
                } catch (error) {
                    this.$handleError(error);
                }
            }
        },

        updateItem(user) {
            const index = this.users.findIndex(({ _id }) => _id == user._id);
            if (0 <= index) this.users.splice(index, 1, user);
            else {
                this.users = [user, ...this.users];
                this.summary.totalCount += 1;
            }
        },
        updateItems(users) {
            for (const user of users) {
                this.updateItem(user);
            }
        },

        copy(value) {
            navigator.clipboard.writeText(value);
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .white-space-pre {
        white-space: pre;
    }
    .white-space-pre-line {
        white-space: pre-line;
    }
    .v-pagination button {
        box-shadow: none !important;
        border: thin solid rgba(0, 0, 0, 0.12);
    }
}
</style>