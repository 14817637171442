<template>
    <v-text-field v-model="searchValue" v-bind="$attrs" @keydown.enter="search">
        <template #append-outer>
            <v-btn text icon class="my-n1" @click="search"><v-icon size="26">search</v-icon></v-btn>
        </template>
    </v-text-field>
</template>

<script>
export default {
    props: {
        value: { type: String, default: null },
    },
    data: () => ({
        searchValue: null,
    }),
    mounted() {
        this.sync();
    },
    watch: {
        searchValue() {
            this.emit();
        },
    },
    methods: {
        sync() {
            this.searchValue = this.value;
        },
        emit() {
            this.$emit("input", this.searchValue);
        },
        search() {
            this.$emit("search");
        },
    },
};
</script>

<style>
</style>