<template>
    <v-dialog v-model="shows" width="320" :disabled="disabled">
        <template #activator="{ attrs, on }">
            <v-row v-if="!!value" no-gutters class="justify-center align-center" v-bind="attrs" v-on="on">
                <v-col cols="auto" class="pa-0">
                    <v-avatar size="30" color="grey" class="elevation-3">
                        <v-img v-if="!value?.avatar?.url">
                            <v-icon color="white">mdi-account</v-icon>
                        </v-img>
                        <v-img v-else cover :src="value?.avatar?.url">
                            <template v-slot:placeholder>
                                <v-icon color="white">mdi-account</v-icon>
                            </template>
                        </v-img>
                    </v-avatar>
                </v-col>
                <v-col class="pa-0 pl-3 text-truncate">{{ value?.nickname }}</v-col>
            </v-row>
        </template>
        <v-card v-if="editable">
            <v-card-title> {{ value?.nickname }}의 아바타 <v-spacer /> <v-icon @click="shows = false">mdi-close</v-icon> </v-card-title>
            <v-divider />
            <v-card-text class="py-3">
                <v-avatar size="272" color="grey" class="elevation-24">
                    <v-img v-if="!file" cover style="cursor: pointer" @click="openFileInput">
                        <v-icon size="200" color="white">mdi-account</v-icon>
                    </v-img>
                    <v-hover v-else v-slot="{ hover }">
                        <v-img cover :src="src">
                            <v-fade-transition>
                                <v-overlay v-show="file && hover" absolute>
                                    <v-btn fab x-large dark @click.stop="file = null">
                                        <v-icon x-large>mdi-delete</v-icon>
                                    </v-btn>
                                </v-overlay>
                            </v-fade-transition>
                        </v-img>
                    </v-hover>
                </v-avatar>
                <v-file-input v-show="false" v-model="file" accept="image/*" ref="file-input" />
            </v-card-text>
            <v-divider />
            <v-card-actions> <v-spacer /> <v-btn @click="update" large color="primary">저장하기</v-btn> </v-card-actions>
        </v-card>
        <v-overlay v-else>
            <v-btn text :href="value?.avatar?.url" target="_blank">
                <v-img max-height="80vh" max-width="80vw" contain v-bind="{ src }" />
            </v-btn>
            <v-btn fixed top right icon tile x-large class="mr-n4 mt-n4 white" @click="shows = false">
                <v-icon color="black">mdi-close</v-icon>
            </v-btn>
        </v-overlay>
    </v-dialog>
</template>

<script>
import api from "@/api";
import ImageInput from "../dumb/image-input.vue";
export default {
    components: { ImageInput },
    props: {
        value: Object, //user
        editable: { type: Boolean, default: false },
    },
    data: () => ({
        shows: false,
        file: null,
    }),
    computed: {
        src() {
            if (this.file) return URL.createObjectURL(this.file);
        },
        disabled() {
            return !this.editable && !this.value?.avatar?.url;
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        shows() {
            if (this.shows) this.init();
            else this.file = null;
        },
    },
    methods: {
        async init() {
            try {
                const { url } = this.value?.avatar || {};
                if (url && url != "undefined/undefined") this.file = await api.getResource(url);
            } catch (error) {
                this.$handleError(error);
            }
        },
        openFileInput() {
            this.$refs["file-input"]?.$el.getElementsByTagName("input")[0].click();
        },
        async update() {
            try {
                // 기존 데이터 삭제
                if (this.value._avatar) await api.console.users.avatar.delete({ _id: this.value._avatar });

                // 신규 데이터 생성 및 데이터바인딩
                if (this.file) {
                    const { file: avatar } = await api.console.users.avatar.post({ _user: this.value._id }, this.file);

                    this.$emit("input", { ...(this.value || {}), _avatar: avatar._id, avatar });
                } else this.$emit("input", { ...(this.value || {}), _avatar: undefined, avatar: undefined });

                alert("성공적으로 처리되었습니다.");
                this.shows = false;
            } catch (error) {
                this.$handleError(error);
            }
        },
    },
};
</script>

<style></style>
