var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    staticClass: "px-3 mx-n3",
    attrs: {
      "max-width": "720",
      "width": "100%",
      "height": "100%"
    }
  }, [_c('list-heading', {
    attrs: {
      "title": "비밀번호 설정"
    }
  }), _c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-text', [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "password",
      "label": "비밀번호",
      "persistent-placeholder": "",
      "outlined": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.admin.password,
      callback: function callback($$v) {
        _vm.$set(_vm.admin, "password", $$v);
      },
      expression: "admin.password"
    }
  })], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "password",
      "label": "비밀번호 확인",
      "persistent-placeholder": "",
      "outlined": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.admin.passwordRepeat,
      callback: function callback($$v) {
        _vm.$set(_vm.admin, "passwordRepeat", $$v);
      },
      expression: "admin.passwordRepeat"
    }
  })], 1)], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": _vm.saveAdmin
    }
  }, [_vm._v("저장")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }