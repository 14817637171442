<template>
    <v-data-table v-if="value.sender" v-bind="{ headers, items }" dense disable-filtering disable-sort hide-default-footer class="transparent">
        <template #top> <v-card-subtitle class="font-weight-bold" style="background-color: rgba(0, 0, 0, 0.05)">보내시는분</v-card-subtitle> <v-divider /> </template>
        <template #footer> <v-divider /> </template>
    </v-data-table>
</template>

<script>
const headers = [
    { text: "이름", value: "name" },
    { text: "연락처", value: "phone" },
    // { text: "주소", value: "address" },
].map((item, index, array) => ({ ...item, divider: index != array.length - 1, align: item.align ?? "center", cellClass: item.cellClass || "caption", formatter: item.formatter ?? ((value) => value ?? "-") }));

export default {
    props: {
        value: { type: Object, default: () => ({}) }, // order
    },
    data: () => ({
        headers,
    }),
    computed: {
        items() {
            let items = [];
            if (this.value?.sender) items.push(this.value?.sender);
            return items;
        },
    },
};
</script>

<style>
</style>