<template>
    <v-sheet>
        <v-card-subtitle class="px-6"> 배송지를 입력하세요 </v-card-subtitle>
        <v-divider />
        <table-receivers v-model="orders" v-bind="{ isBulkOrder }" />
        <v-divider />
        <v-card-actions>
            <v-btn x-large text block color="primary" v-bind="{ disabled, isBulkOrder }" @click="emit">입력완료</v-btn>
        </v-card-actions>
    </v-sheet>
</template>

<script>
import TableReceivers from "./table-receivers.vue";

export default {
    components: {
        TableReceivers,
    },
    props: {
        value: { type: Array, default: () => [] }, // orders
        isBulkOrder: { type: Boolean, default: false },
    },
    data: () => ({
        orders: [],
    }),
    computed: {
        disabled() {
            return !this.orders.length || this.orders.some(({ receiver: { name, phone, postcode, address1, address2 } }) => !name || !phone || !postcode || !address1 || !address2);
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.orders = JSON.parse(JSON.stringify(this.value));
        },
        emit() {
            this.$emit("input", this.orders);
            this.$emit("forward");
        },
    },
};
</script>

<style>
</style>