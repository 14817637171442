var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-text-field', _vm._b({
    on: {
      "keydown": function keydown($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.search.apply(null, arguments);
      }
    },
    scopedSlots: _vm._u([{
      key: "append-outer",
      fn: function fn() {
        return [_c('v-btn', {
          staticClass: "my-n1",
          attrs: {
            "text": "",
            "icon": ""
          },
          on: {
            "click": _vm.search
          }
        }, [_c('v-icon', {
          attrs: {
            "size": "26"
          }
        }, [_vm._v("search")])], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.searchValue,
      callback: function callback($$v) {
        _vm.searchValue = $$v;
      },
      expression: "searchValue"
    }
  }, 'v-text-field', _vm.$attrs, false));
}
var staticRenderFns = []

export { render, staticRenderFns }