var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.immersive ? _c('v-hover', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var hover = _ref.hover;
        return [_c('v-img', _vm._b({}, 'v-img', Object.assign({}, _vm.$attrs, {
          src: _vm.src,
          size: _vm.size,
          rounded: _vm.rounded
        }), false), [_c('v-file-input', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: false,
            expression: "false"
          }],
          ref: "file-input",
          attrs: {
            "label": _vm.label,
            "accept": "image/*"
          },
          model: {
            value: _vm.file,
            callback: function callback($$v) {
              _vm.file = $$v;
            },
            expression: "file"
          }
        }), !_vm.src ? _c('v-overlay', {
          attrs: {
            "absolute": "",
            "z-index": "0"
          }
        }, [_vm._v(" 이미지가 없습니다 ")]) : _vm._e(), _c('v-fade-transition', [_c('v-overlay', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: hover,
            expression: "hover"
          }],
          attrs: {
            "absolute": "",
            "z-index": "0"
          }
        }, [_c('v-btn', {
          attrs: {
            "fab": "",
            "color": "primary"
          },
          on: {
            "click": _vm.onClick
          }
        }, [_c('v-icon', [_vm._v("mdi-pencil")])], 1)], 1)], 1)], 1)];
      }
    }], null, false, 1239245589)
  }) : _c('v-row', {
    class: {
      'flex-row-reverse': _vm.reversed
    },
    attrs: {
      "align": "baseline"
    }
  }, [_c('v-col', {
    class: {
      'pr-0': !_vm.reversed,
      'pl-0': _vm.reversed
    },
    attrs: {
      "cols": "auto"
    }
  }, [_c('image-popup', {
    attrs: {
      "src": _vm.src,
      "size": _vm.size,
      "rounded": _vm.rounded
    }
  })], 1), _c('v-col', [_c('v-file-input', _vm._b({
    attrs: {
      "label": _vm.label,
      "accept": "image/*"
    },
    model: {
      value: _vm.file,
      callback: function callback($$v) {
        _vm.file = $$v;
      },
      expression: "file"
    }
  }, 'v-file-input', Object.assign({}, _vm.inputAttrs, _vm.$attrs), false))], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }