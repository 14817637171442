var render = function () {
  var _vm$value4, _vm$value5, _vm$value5$avatar;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "width": "320",
      "disabled": _vm.disabled
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var _vm$value, _vm$value$avatar, _vm$value2, _vm$value2$avatar, _vm$value3;

        var attrs = _ref.attrs,
            on = _ref.on;
        return [!!_vm.value ? _c('v-row', _vm._g(_vm._b({
          staticClass: "justify-center align-center",
          attrs: {
            "no-gutters": ""
          }
        }, 'v-row', attrs, false), on), [_c('v-col', {
          staticClass: "pa-0",
          attrs: {
            "cols": "auto"
          }
        }, [_c('v-avatar', {
          staticClass: "elevation-3",
          attrs: {
            "size": "30",
            "color": "grey"
          }
        }, [!((_vm$value = _vm.value) !== null && _vm$value !== void 0 && (_vm$value$avatar = _vm$value.avatar) !== null && _vm$value$avatar !== void 0 && _vm$value$avatar.url) ? _c('v-img', [_c('v-icon', {
          attrs: {
            "color": "white"
          }
        }, [_vm._v("mdi-account")])], 1) : _c('v-img', {
          attrs: {
            "cover": "",
            "src": (_vm$value2 = _vm.value) === null || _vm$value2 === void 0 ? void 0 : (_vm$value2$avatar = _vm$value2.avatar) === null || _vm$value2$avatar === void 0 ? void 0 : _vm$value2$avatar.url
          },
          scopedSlots: _vm._u([{
            key: "placeholder",
            fn: function fn() {
              return [_c('v-icon', {
                attrs: {
                  "color": "white"
                }
              }, [_vm._v("mdi-account")])];
            },
            proxy: true
          }], null, true)
        })], 1)], 1), _c('v-col', {
          staticClass: "pa-0 pl-3 text-truncate"
        }, [_vm._v(_vm._s((_vm$value3 = _vm.value) === null || _vm$value3 === void 0 ? void 0 : _vm$value3.nickname))])], 1) : _vm._e()];
      }
    }]),
    model: {
      value: _vm.shows,
      callback: function callback($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_vm.editable ? _c('v-card', [_c('v-card-title', [_vm._v(" " + _vm._s((_vm$value4 = _vm.value) === null || _vm$value4 === void 0 ? void 0 : _vm$value4.nickname) + "의 아바타 "), _c('v-spacer'), _vm._v(" "), _c('v-icon', {
    on: {
      "click": function click($event) {
        _vm.shows = false;
      }
    }
  }, [_vm._v("mdi-close")])], 1), _c('v-divider'), _c('v-card-text', {
    staticClass: "py-3"
  }, [_c('v-avatar', {
    staticClass: "elevation-24",
    attrs: {
      "size": "272",
      "color": "grey"
    }
  }, [!_vm.file ? _c('v-img', {
    staticStyle: {
      "cursor": "pointer"
    },
    attrs: {
      "cover": ""
    },
    on: {
      "click": _vm.openFileInput
    }
  }, [_c('v-icon', {
    attrs: {
      "size": "200",
      "color": "white"
    }
  }, [_vm._v("mdi-account")])], 1) : _c('v-hover', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var hover = _ref2.hover;
        return [_c('v-img', {
          attrs: {
            "cover": "",
            "src": _vm.src
          }
        }, [_c('v-fade-transition', [_c('v-overlay', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.file && hover,
            expression: "file && hover"
          }],
          attrs: {
            "absolute": ""
          }
        }, [_c('v-btn', {
          attrs: {
            "fab": "",
            "x-large": "",
            "dark": ""
          },
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              _vm.file = null;
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "x-large": ""
          }
        }, [_vm._v("mdi-delete")])], 1)], 1)], 1)], 1)];
      }
    }], null, false, 2765974794)
  })], 1), _c('v-file-input', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    ref: "file-input",
    attrs: {
      "accept": "image/*"
    },
    model: {
      value: _vm.file,
      callback: function callback($$v) {
        _vm.file = $$v;
      },
      expression: "file"
    }
  })], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _vm._v(" "), _c('v-btn', {
    attrs: {
      "large": "",
      "color": "primary"
    },
    on: {
      "click": _vm.update
    }
  }, [_vm._v("저장하기")])], 1)], 1) : _c('v-overlay', [_c('v-btn', {
    attrs: {
      "text": "",
      "href": (_vm$value5 = _vm.value) === null || _vm$value5 === void 0 ? void 0 : (_vm$value5$avatar = _vm$value5.avatar) === null || _vm$value5$avatar === void 0 ? void 0 : _vm$value5$avatar.url,
      "target": "_blank"
    }
  }, [_c('v-img', _vm._b({
    attrs: {
      "max-height": "80vh",
      "max-width": "80vw",
      "contain": ""
    }
  }, 'v-img', {
    src: _vm.src
  }, false))], 1), _c('v-btn', {
    staticClass: "mr-n4 mt-n4 white",
    attrs: {
      "fixed": "",
      "top": "",
      "right": "",
      "icon": "",
      "tile": "",
      "x-large": ""
    },
    on: {
      "click": function click($event) {
        _vm.shows = false;
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "black"
    }
  }, [_vm._v("mdi-close")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }