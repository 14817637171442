var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "height": "100%"
    }
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("이미지")]), _c('v-divider'), _c('v-card-text', [_c('images-input', {
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.images,
      callback: function callback($$v) {
        _vm.images = $$v;
      },
      expression: "images"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }