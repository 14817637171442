<template>
    <v-col cols="auto" class="pt-0 pb-0 headline text-start">{{ title }}</v-col>
</template>

<script>
export default {
    props: ["value"],
    computed: {
        code() {
            return this.$route.query.code;
        },
        isReference() {
            return ["yknowledge-reference", "yfactory-reference"].includes(this.code);
        },
        title() {
            if (this.isReference) return "자료실 글목록";
            else return "게시글 목록";
        },
    },
};
</script>

<style></style>
