<template>
    <v-row align="center" :class="{ [rowAttrs.class || '']: true, hasCertification }" v-bind="rowAttrs">
        <v-col v-bind="colAttrs">
            <v-text-field v-model="phone1" v-bind="{ ...$attrs, label, readonly, dense }" v-on="{ input, click }" />
        </v-col>
        <v-col cols="auto" class="pa-0">-</v-col>
        <v-col v-bind="colAttrs">
            <v-text-field v-model="phone2" v-bind="{ ...$attrs, readonly, dense }" v-on="{ input, click }" />
        </v-col>
        <v-col cols="auto" class="pa-0">-</v-col>
        <v-col v-bind="colAttrs">
            <v-text-field v-model="phone3" v-bind="{ ...$attrs, readonly, dense }" v-on="{ input, click }" />
        </v-col>
        <template v-if="hasCertification">
            <v-col v-bind="colAttrs" cols="12" sm="auto">
                <v-btn v-bind="{ ...btnAttrs, dense }" :x-large="dense == false ? true : false" @click="certify">본인인증</v-btn>
            </v-col>
            <kcp-cert ref="kcp-cert" @input="processCertifiedData" />
        </template>
    </v-row>
</template>

<script>
import KcpCert from "@/components/plugins/kcp/kcp-cert.vue";
export default {
    components: {
        KcpCert,
    },
    props: {
        value: { type: String }, // phone

        label: { type: String },
        dense: { type: Boolean, default: false },
        hasCertification: { type: Boolean, default: false },

        btnAttrs: { type: Object, default: () => ({}) },
        colAttrs: { type: Object, default: () => ({}) },
        rowAttrs: { type: Object, default: () => ({}) },
    },
    data: () => ({
        phone1: "010",
        phone2: null,
        phone3: null,
        _certification: null,
    }),
    computed: {
        readonly() {
            return this.hasCertification;
        },
        phone() {
            const { phone1, phone2, phone3 } = this;
            if (!!phone1 && !!phone2 && !!phone3) return `${phone1}-${phone2}-${phone3}`;
            else return null;
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            if (this.phone != this.value) this.sync();
        },
        phone() {
            this.emit();
        },
        _certification() {
            this.emit();
        },
    },
    methods: {
        sync() {
            [this.phone1, this.phone2, this.phone3] = this.value?.split?.("-") || ["010", null, null];
        },
        emit() {
            this.$emit("input", this.phone);
            this.$emit("certification", this._certification);
        },
        input() {
            this.$nextTick(() => {
                this.phone1 = this.phone1?.slice?.(0, 3)?.replaceAll?.(/\D/g, "") || null;
                this.phone2 = this.phone2?.slice?.(0, 4)?.replaceAll?.(/\D/g, "") || null;
                this.phone3 = this.phone3?.slice?.(0, 4)?.replaceAll?.(/\D/g, "") || null;
            });
        },
        click() {
            if (this.hasCertification) this.certify();
        },
        certify() {
            this.$refs["kcp-cert"]?.auth?.();
        },
        processCertifiedData(payload) {
            [this.phone1, this.phone2, this.phone3] = payload?.phone?.phoneNumberFormat()?.split?.("-");
            this._certification = payload._certification;
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    input {
        text-align: center;
    }
}
.hasCertification ::v-deep input {
    cursor: pointer !important;
}
</style>
