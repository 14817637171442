var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    staticClass: "px-3 mx-n3",
    attrs: {
      "max-width": "640",
      "width": "100%",
      "height": "100%"
    }
  }, [_c('list-heading', {
    attrs: {
      "title": "사용자 그룹 목록"
    },
    scopedSlots: _vm._u([{
      key: "add-button",
      fn: function fn() {
        return [_c('user-group-input', {
          on: {
            "input": _vm.updateItem
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref) {
              var attrs = _ref.attrs,
                  on = _ref.on;
              return [_c('v-btn', _vm._g(_vm._b({
                staticClass: "my-n1",
                attrs: {
                  "small": "",
                  "fab": "",
                  "tile": "",
                  "color": "transparent primary--text"
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-plus")])], 1)];
            }
          }])
        })];
      },
      proxy: true
    }])
  }), _c('v-data-table', _vm._b({
    staticClass: "v-sheet--outlined",
    attrs: {
      "disable-sort": "",
      "disable-pagination": "",
      "hide-default-footer": ""
    },
    scopedSlots: _vm._u([_vm._l(_vm.headers.filter(function (header) {
      return header.hasOwnProperty('formatter');
    }), function (header) {
      return {
        key: "item.".concat(header.value),
        fn: function fn(_ref2) {
          var value = _ref2.value;
          return [_vm._v(" " + _vm._s(header.formatter(value)) + " ")];
        }
      };
    }), {
      key: "item.actions",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('user-group-input', _vm._b({
          on: {
            "input": _vm.updateItem
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref4) {
              var attrs = _ref4.attrs,
                  on = _ref4.on;
              return [_c('v-icon', _vm._g(_vm._b({
                attrs: {
                  "small": ""
                }
              }, 'v-icon', attrs, false), on), [_vm._v(" mdi-pencil ")])];
            }
          }], null, true)
        }, 'user-group-input', item, false)), _c('v-icon', {
          staticClass: "ml-2",
          attrs: {
            "small": ""
          },
          on: {
            "click": function click($event) {
              return _vm.remove(item);
            }
          }
        }, [_vm._v(" mdi-delete ")])];
      }
    }], null, true)
  }, 'v-data-table', {
    headers: _vm.headers,
    items: _vm.items
  }, false)), _c('v-pagination', {
    staticClass: "mt-2",
    attrs: {
      "value": _vm.page,
      "length": _vm.pageCount,
      "total-visible": 11,
      "color": "primary"
    },
    on: {
      "input": function input(page) {
        return _vm.$router.push({
          query: Object.assign({}, _vm.$route.query, {
            page: page
          })
        });
      }
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }