var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "max-width": "480",
      "scrollable": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var attrs = _ref.attrs,
            on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs: attrs,
          on: on
        })];
      }
    }], null, true),
    model: {
      value: _vm.shows,
      callback: function callback($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": "",
      "tile": ""
    }
  }, [_c('v-card-title', [_vm._v(" 상품선택 "), _c('v-spacer'), _vm._v(" "), _c('v-icon', {
    on: {
      "click": function click($event) {
        _vm.shows = false;
      }
    }
  }, [_vm._v("mdi-close")])], 1), _c('v-divider'), _c('v-expand-transition', [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.filter._category,
      expression: "!filter._category"
    }]
  }, [_c('products-filter-name', _vm._b({
    staticClass: "mt-4 mx-4",
    attrs: {
      "placeholder": "상품명",
      "clearable": ""
    },
    model: {
      value: _vm.filter.name,
      callback: function callback($$v) {
        _vm.$set(_vm.filter, "name", $$v);
      },
      expression: "filter.name"
    }
  }, 'products-filter-name', _vm.attrs_input, false))], 1)]), _c('v-expand-transition', [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.filter.name,
      expression: "!filter.name"
    }]
  }, [_c('products-filter-category', _vm._b({
    staticClass: "mb-n4",
    attrs: {
      "clearable": ""
    },
    on: {
      "input": _vm.search
    },
    model: {
      value: _vm.filter._category,
      callback: function callback($$v) {
        _vm.$set(_vm.filter, "_category", $$v);
      },
      expression: "filter._category"
    }
  }, 'products-filter-category', Object.assign({}, _vm.attrs_input, {
    access: _vm.access
  }), false))], 1)]), _c('v-divider', {
    staticClass: "mt-4"
  }), _c('table-products', _vm._b({
    attrs: {
      "noDataText": "검색된 상품이 없습니다",
      "hideRemoveBtn": ""
    },
    model: {
      value: _vm.products,
      callback: function callback($$v) {
        _vm.products = $$v;
      },
      expression: "products"
    }
  }, 'table-products', _vm.attrs_input, false)), _c('v-divider'), _c('v-btn', _vm._b({
    attrs: {
      "x-large": "",
      "text": "",
      "block": "",
      "color": "primary"
    },
    on: {
      "click": _vm.emit
    }
  }, 'v-btn', {
    disabled: _vm.disabled
  }, false), [_vm._v("선택완료")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }