<template>
    <v-dialog v-model="shows" max-width="320" persistent>
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on, disabled }" />
        </template>
        <v-card>
            <v-card-title>선택 상태변경</v-card-title>
            <v-card-text class="pb-0">
                <v-row class="mx-n2">
                    <v-col cols="12" class="pa-2"> <table-state-summary v-bind="{ selected }" /> </v-col>
                    <v-col cols="12" class="pa-2"> <v-select v-model="state" v-bind="{ ...attrs_input, items, disabled }" label="변경 주문상태" /> </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions class="justify-end">
                <v-subheader class="caption">
                    선택된 항목 총
                    <span class="mx-1 text-subtitle-1 font-weight-bold primary--text">
                        {{ selected.length }}
                    </span>
                    건
                </v-subheader>
                <v-spacer />
                <v-btn text color="primary" @click="shows = false">취소</v-btn>
                <v-btn text color="primary" v-bind="{ disabled }" @click="save">변경</v-btn>
            </v-card-actions>
            <v-fade-transition>
                <v-overlay v-show="loading" absolute light color="white">
                    <v-sheet width="240" rounded style="overflow: hidden">
                        <v-progress-linear :value="(updatedLength / selected.length) * 100" height="24">
                            <span> {{ updatedLength }} / {{ selected.length }} </span>
                        </v-progress-linear>
                    </v-sheet>
                </v-overlay>
            </v-fade-transition>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import { attrs_input, PURCHASE_ORDER_STATES } from "@/assets/variables";

import TableStateSummary from "./table-state-summary.vue";

const items = Object.values(PURCHASE_ORDER_STATES);

export default {
    components: {
        TableStateSummary,
    },
    props: {
        selected: { type: Array, default: () => [] },
    },
    data: () => ({
        shows: false,
        loading: false,

        updatedLength: 0,
        state: items[0].value,

        items,
        attrs_input,
    }),
    computed: {
        disabled() {
            return !this.selected.length;
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        shows(shows) {
            if (shows) this.init();
        },
    },
    methods: {
        init() {
            this.updatedLength = 0;
            this.state = items[0].value;
        },
        async save() {
            if (this.loading) return;
            this.loading = true;

            try {
                let { state } = this;
                let form = { state };

                switch (state) {
                    case PURCHASE_ORDER_STATES.CANCELED.value: {
                        form.canceledAt = Date.now();
                        break;
                    }
                    case PURCHASE_ORDER_STATES.TRANSPORT.value: {
                        form.transportAt = Date.now();
                        break;
                    }
                    case PURCHASE_ORDER_STATES.PROCESSED.value: {
                        form.processedAt = Date.now();
                        break;
                    }
                }

                let orders = [];
                for (const { _id } of this.selected) {
                    orders.push((await api.console.shop.purchaseOrders.put({ _id, ...form }))?.order);
                    this.updatedLength += 1;
                }
                setTimeout(() => {
                    alert("변경되었습니다");
                    this.$emit("updateItems", orders);
                    this.loading = false;
                    this.shows = false;
                }, 500);
            } finally {
                setTimeout(() => {
                    if (this.loading) this.loading = false;
                }, 500);
            }
        },
    },
};
</script>

<style>
</style>