<template>
    <v-data-table v-bind="{ headers, items }" disable-filtering disable-sort hide-default-footer mobile-breakpoint="10000">
        <template #top v-if="showsProducts && !readonlyProducts">
            <v-row no-gutters v-if="isBulkOrder">
                <v-col cols="auto" class="py-2">
                    <v-btn text tile @click.prevent.stop.capture="toggleSelectedAll"> <v-simple-checkbox :value="isAllSelected" hide-details color="primary" @click.prevent /> 전체선택 </v-btn>
                </v-col>
                <v-spacer />
                <v-col cols="auto" class="py-2 px-4">
                    <dialog-products-select v-bind="{ form }" @input="setSelectedProducts">
                        <template #activator="{ attrs, on }">
                            <v-btn outlined block color="primary" :disabled="!selected.length" v-bind="attrs" v-on="on">일괄 상품추가</v-btn>
                        </template>
                    </dialog-products-select>
                </v-col>
            </v-row>
            <v-row no-gutters v-else>
                <v-spacer />
                <v-col cols="auto" class="py-2 px-4">
                    <dialog-products-select v-bind="{ form }" @input="(items) => setProducts(items, orders[0].tempId)">
                        <template #activator="{ attrs, on }">
                            <v-btn outlined color="primary" v-bind="attrs" v-on="on">개별 상품추가</v-btn>
                        </template>
                    </dialog-products-select>
                </v-col>
            </v-row>
            <v-divider />
        </template>
        <template #[`item.select`]="{ item }">
            <v-row no-gutters class="mx-n4" style="width: 400px">
                <v-col cols="auto" class="px-2">
                    <v-btn text icon @click.prevent.stop.capture="toggleSelected(item)"> <v-simple-checkbox :value="selected.includes(item.tempId)" hide-details color="primary" class="mr-n2" @click.prevent /> </v-btn>
                </v-col>
                <v-spacer />
                <v-col cols="auto" class="px-4">
                    <dialog-products-select v-bind="{ form }" @input="(items) => setProducts(items, item.tempId)">
                        <template #activator="{ attrs, on }">
                            <v-btn outlined color="primary" v-bind="attrs" v-on="on">개별 상품추가</v-btn>
                        </template>
                    </dialog-products-select>
                </v-col>
            </v-row>
        </template>
        <template #[`item.receiver.name`]="{ item, value }">
            <template v-if="readonly">{{ value || "-" }}</template>
            <dialog-input v-else v-model="item.receiver.name" label="이름" v-bind="attrs_input" @input="update(item)" />
        </template>
        <template #[`item.receiver.phone`]="{ item, value }">
            <template v-if="readonly">{{ value || "-" }}</template>
            <dialog-input v-else v-model="item.receiver.phone" label="연락처" v-bind="attrs_input" @input="update(item)" />
        </template>
        <template #[`item.receiver.address`]="{ item, value }">
            <template v-if="readonly">{{ value || "-" }}</template>
            <dialog-address v-else v-model="item.receiver" v-bind="attrs_input" @input="update(item)" />
        </template>
        <template #[`item.products`]="{ item }">
            <div style="position: relative"><v-divider style="position: absolute; top: -1px; left: calc(-28px - 16px - 16px - 17px); width: 400px; max-width: 400px" /></div>
            <div class="mr-n4" style="border-left: thin solid rgba(0, 0, 0, 0.12)"><table-products v-model="item.products" @input="update(item)" style="width: calc(400px - 16px - 28px - 16px - 17px)" :readonly="readonlyProducts" :hideRemoveBtn="readonlyProducts" /></div>
        </template>
        <template #[`item.actions`]="{ item }">
            <v-icon v-if="!readonly" color="error" @click="remove(item)">mdi-delete</v-icon>
        </template>
        <template #foot v-if="isBulkOrder && !readonly">
            <v-divider />
            <v-btn x-large text block color="primary" @click="addItem"> <v-icon class="mr-1">mdi-plus</v-icon> 항목 추가 </v-btn>
        </template>
    </v-data-table>
</template>

<script>
import { initReceiver } from "@/lib";
import { attrs_input } from "@/assets/variables";

import DialogInput from "@/components/console/dumb/dialog-input.vue";
import TableProducts from "../table-products/table-products.vue";
import DialogAddress from "@/components/console/dumb/dialog-address.vue";
import DialogProductsSelect from "../dialog-products-select/dialog-products-select.vue";

export default {
    components: {
        DialogInput,
        TableProducts,
        DialogAddress,
        DialogProductsSelect,
    },
    props: {
        form: { type: Object, default: () => ({}) }, // form
        value: { type: Array, default: () => [] }, // orders
        readonly: { type: Boolean, default: false },
        isBulkOrder: { type: Boolean, default: false },
        showsProducts: { type: Boolean, default: false },
        readonlyProducts: { type: Boolean, default: false },
    },
    data: () => ({
        orders: [],
        selected: [],

        attrs_input,
    }),
    computed: {
        headers() {
            let headers = [];
            if (this.showsProducts && this.isBulkOrder) {
                if (!this.readonlyProducts) {
                    headers.push({ text: "", value: "select" });
                } else {
                    headers.push({ text: "#", value: "index" });
                }
            }
            headers.push({ text: "이름", value: "receiver.name" });
            headers.push({ text: "연락처", value: "receiver.phone" });
            headers.push({ text: "주소", value: "receiver.address" });
            if (this.showsProducts) headers.push({ text: "상품", value: "products" });
            if (this.isBulkOrder && !this.readonly) headers.push({ text: "", value: "actions" });
            return headers;
        },
        items() {
            return this.orders.map((order = {}, index) => {
                let { postcode, address1, address2 } = order.receiver || {};

                let address = "";
                if (postcode) address += `[${postcode}] `;
                if (address1) address += `${address1} `;
                if (address2) address += `${address2} `;
                address = address.trim();

                return { ...order, receiver: { ...(order.receiver || {}), address }, index: index + 1 };
            });
        },
        disabled() {
            return this.orders.some(({ products }) => !products.length);
        },
        isAllSelected() {
            return !this.orders.some(({ tempId }) => !this.selected.includes(tempId));
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.orders = JSON.parse(JSON.stringify(this.value));
        },
        emit() {
            this.$emit("input", this.orders);
        },
        update(item) {
            let orders = [...this.orders];
            let index = orders.findIndex(({ tempId }) => tempId == item.tempId);
            if (index >= 0) orders.splice(index, 1, item);
            else orders = [...orders, item];
            this.orders = orders;
            this.emit();
        },
        addItem() {
            this.orders.push({
                tempId: Math.random(),
                receiver: initReceiver(),
                products: [],
            });
            this.orders = [...this.orders];
            this.emit();
        },
        remove(item) {
            let orders = [...this.orders];
            let index = orders.findIndex(({ tempId }) => tempId == item.tempId);
            if (index >= 0) orders.splice(index, 1);
            this.orders = orders;
            this.emit();
        },
        toggleSelected({ tempId }) {
            if (!this.selected.includes(tempId)) this.selected.push(tempId);
            else this.selected = this.selected.filter((item) => item != tempId);
        },
        toggleSelectedAll() {
            if (this.isAllSelected) this.selected = [];
            else this.selected = this.orders.map(({ tempId }) => tempId);
        },
        setSelectedProducts(items) {
            for (const tempId of this.selected) {
                this.setProducts(items, tempId);
            }
        },
        setProducts(items, tempId) {
            let index = this.orders.findIndex((order) => order.tempId == tempId);

            if (index >= 0) {
                let order = JSON.parse(JSON.stringify(this.orders[index]));
                let { products = [] } = order;
                for (const item of items) {
                    let index = products.findIndex(({ _id }) => _id == item._id);
                    if (index >= 0) products[index].quantity = +products[index].quantity + +item.quantity;
                    else products.push(item);
                }
                order.products = products;
                this.setOrder(order);
            }
        },
        setOrder(item) {
            let index = this.orders.findIndex(({ tempId }) => tempId == item.tempId);
            if (index >= 0) {
                this.orders.splice(index, 1, item);
                this.orders = [...this.orders];
                this.emit();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .v-data-table__mobile-table-row {
        display: block;
        padding-top: 12px;
    }
    .v-data-table__mobile-row {
        height: auto !important;
        min-height: 24px;

        &:last-child {
            margin-top: 12px;
        }
    }
}
</style>
