var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    staticClass: "px-3 mx-n3",
    attrs: {
      "max-width": "1520",
      "width": "100%",
      "height": "100%"
    }
  }, [_c('list-heading', {
    attrs: {
      "title": "클레임 목록",
      "showsFilterButton": ""
    },
    model: {
      value: _vm.showsSearch,
      callback: function callback($$v) {
        _vm.showsSearch = $$v;
      },
      expression: "showsSearch"
    }
  }), _c('claim-list-search', _vm._b({}, 'claim-list-search', {
    showsSearch: _vm.showsSearch
  }, false)), _c('v-row', {
    staticClass: "mr-n2",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "mb-2 mr-2",
    attrs: {
      "cols": "",
      "md": "auto"
    }
  }, [_c('claim-list-state-toggle')], 1), _c('v-spacer'), _c('v-col', {
    staticClass: "mb-2 mr-2",
    attrs: {
      "cols": "",
      "md": "auto"
    }
  }, [_c('dialog-claim-input', {
    on: {
      "input": _vm.updateItem
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var attrs = _ref.attrs,
            on = _ref.on;
        return [_c('v-sheet', {
          attrs: {
            "outlined": "",
            "rounded": ""
          }
        }, [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "block": "",
            "color": "white primary--text"
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', {
          staticClass: "mr-1"
        }, [_vm._v("mdi-note-alert-outline")]), _vm._v(" 클레임 추가 ")], 1)], 1)];
      }
    }])
  })], 1)], 1), _c('v-data-table', _vm._b({
    staticClass: "v-sheet--outlined",
    attrs: {
      "disable-filtering": "",
      "disable-sort": "",
      "disable-pagination": "",
      "hide-default-footer": "",
      "items-per-page": -1
    },
    scopedSlots: _vm._u([_vm._l(_vm.headers.filter(function (header) {
      return header.hasOwnProperty('formatter');
    }), function (header) {
      return {
        key: "item.".concat(header.value),
        fn: function fn(_ref2) {
          var value = _ref2.value;
          return [_vm._v(" " + _vm._s(header.formatter(value)) + " ")];
        }
      };
    }), {
      key: "item.orderNumber",
      fn: function fn(_ref3) {
        var item = _ref3.item,
            value = _ref3.value;
        return [_c('div', {
          staticClass: "d-flex align-center px-4"
        }, [_vm._v(_vm._s(value))]), _c('to-purchase-order', {
          attrs: {
            "order": item.order
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref4) {
              var to = _ref4.to;
              return [_c('v-btn', _vm._b({
                attrs: {
                  "text": "",
                  "icon": "",
                  "tile": "",
                  "color": "primary"
                }
              }, 'v-btn', {
                to: to
              }, false), [_c('v-icon', {
                attrs: {
                  "size": "18"
                }
              }, [_vm._v("mdi-link-variant")])], 1)];
            }
          }], null, true)
        })];
      }
    }, {
      key: "item.reply",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('v-edit-dialog', {
          attrs: {
            "large": "",
            "cancel-text": "취소",
            "save-text": "답변하기"
          },
          on: {
            "save": function save($event) {
              _vm.update({
                _id: item._id,
                _handler: _vm._handler,
                reply: item.reply,
                handlerName: item.handlerName,
                repliedAt: Date.now()
              });
            }
          },
          scopedSlots: _vm._u([{
            key: "input",
            fn: function fn() {
              return [_c('v-text-field', _vm._b({
                staticClass: "my-3",
                attrs: {
                  "label": "처리"
                },
                model: {
                  value: item.reply,
                  callback: function callback($$v) {
                    _vm.$set(item, "reply", $$v);
                  },
                  expression: "item.reply"
                }
              }, 'v-text-field', _vm.attrs_input, false)), _c('v-text-field', _vm._b({
                staticClass: "my-3",
                attrs: {
                  "label": "처리자"
                },
                model: {
                  value: item.handlerName,
                  callback: function callback($$v) {
                    _vm.$set(item, "handlerName", $$v);
                  },
                  expression: "item.handlerName"
                }
              }, 'v-text-field', _vm.attrs_input, false))];
            },
            proxy: true
          }], null, true)
        }, [_c('v-icon', [_vm._v("mdi-message-reply-text")])], 1)];
      }
    }], null, true)
  }, 'v-data-table', {
    headers: _vm.headers,
    items: _vm.items
  }, false)), _c('v-pagination', {
    staticClass: "mt-2",
    attrs: {
      "value": _vm.page,
      "length": _vm.pageCount,
      "total-visible": 11,
      "color": "primary"
    },
    on: {
      "input": function input(page) {
        return _vm.$router.push({
          query: Object.assign({}, _vm.$route.query, {
            page: page
          })
        });
      }
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }