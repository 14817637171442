<template>
    <v-row no-gutters>
        <v-col cols="12" sm="4" md="3" lg="2" class="pa-2"> <v-select v-model="form.type" label="파일유형" :items="types" :disabled="excel == 'invoice'" v-bind="attrs_input" @input="((form.file = null) || 1) && emit()" /> </v-col>
        <v-col v-if="isTypeManualBulk" cols="auto" class="pa-2">
            <dialog-excel-upload-form>
                <template #activator="{ click, loading }">
                    <v-sheet outlined rounded>
                        <v-btn icon text title="수동일괄 발주양식" color="green" v-bind="{ loading }" v-on="{ click }">
                            <v-icon dense>mdi-file-download</v-icon>
                        </v-btn>
                    </v-sheet>
                </template>
            </dialog-excel-upload-form>
        </v-col>
        <v-col class="pa-2"> <v-file-input v-model="form.file" label="파일" v-bind="{ ...attrs_input, disabled }" accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" prepend-icon="" prepend-inner-icon="mdi-microsoft-excel" clearable @change="emit" @click:clear="((form.file = null) || true) && emit()" /> </v-col>
    </v-row>
</template>

<script>
import { attrs_input } from "@/assets/variables";
import { PURCHASE_ORDER_TYPES } from "@/assets/variables";

import DialogExcelUploadForm from "./dialog-excel-upload-form.vue";

let { ADMIN_UPLOADED_ORDER_BC, ADMIN_UPLOADED_ORDER_CU, ADMIN_UPLOADED_ORDER_DC, ADMIN_UPLOADED_ORDER_SH, ADMIN_MANUAL_ORDER_BULK } = PURCHASE_ORDER_TYPES;
let types = [ADMIN_UPLOADED_ORDER_BC, ADMIN_UPLOADED_ORDER_CU, ADMIN_UPLOADED_ORDER_DC, ADMIN_UPLOADED_ORDER_SH, ADMIN_MANUAL_ORDER_BULK];

export default {
    components: {
        DialogExcelUploadForm,
    },
    props: {
        value: { type: Object, default: () => ({ type: null, file: null }) }, // form

        excel: { type: String, default: "upload" },
    },
    data: () => ({
        types,
        attrs_input,

        form: {
            type: null,
            file: null,
        },
    }),
    computed: {
        disabled() {
            return !this.form?.type;
        },
        isTypeManualBulk() {
            return this.form.type == ADMIN_MANUAL_ORDER_BULK.value;
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.form = { ...this.value };
        },
        emit() {
            this.$emit("input", { ...this.form });
        },
    },
};
</script>

<style>
</style>