<template>
    <v-layout justify-center>
        <v-responsive max-width="1440" width="100%" height="100%" class="px-3 mx-n3">
            <list-heading title="상품 목록" showsFilterButton v-model="showsSearch">
                <template #add-button>
                    <product-input @input="updateItem">
                        <template #activator="{ attrs, on }">
                            <v-btn small fab tile color="transparent primary--text" class="my-n1" v-bind="attrs" v-on="on"> <v-icon>mdi-plus</v-icon> </v-btn>
                        </template>
                    </product-input>
                </template>
            </list-heading>
            <shop-products-search v-bind="{ categories, loading, showsSearch }" />

            <v-data-table v-bind="{ loading, headers, items }" disable-sort disable-pagination hide-default-footer class="v-sheet--outlined" mobile-breakpoint="1024" :items-per-page="-1">
                <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>
                <template #[`item.actions`]="{ item }">
                    <product-input v-bind="item" @input="updateItem">
                        <template #activator="{ attrs, on }">
                            <v-icon small v-bind="attrs" v-on="on"> mdi-pencil </v-icon>
                        </template>
                    </product-input>
                    <v-icon small @click="remove(item)" class="ml-2"> mdi-delete </v-icon>
                </template>
            </v-data-table>
            <v-pagination :value="page" :length="pageCount" :total-visible="11" color="primary" class="mt-2" @input="(page) => $router.push({ query: { ...$route.query, page } })"></v-pagination>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";

import ListHeading from "@/components/console/dumb/list-heading.vue";
import ProductInput from "@/components/console/shop/product-input.vue";
import ShopProductsSearch from "@/components/console/shop/shop-products/shop-products-search.vue";

const defualtFormatter = (value) => value ?? "-";

const headers = [
    { width: +80, text: "#", value: "index", divider: true },
    { width: 180, text: "카테고리명", value: "category.fullName", divider: true },
    { width: "auto", text: "상품명", value: "name", divider: true },
    { width: "auto", text: "규격", value: "size", divider: true },
    { width: "auto", text: "암호코드", value: "code", divider: true },
    { width: "auto", text: "상품코드", value: "codeAlt", divider: true },
    { width: 170, text: "생성일자", value: "createdAt", formatter: (value) => value?.toDateTime?.(), divider: true },
    { width: +80, text: "", value: "actions", align: "center" },
].map((item) => ({ ...item, formatter: item.formatter ?? defualtFormatter }));

export default {
    components: {
        ListHeading,
        ProductInput,
        ShopProductsSearch
    },
    data() {
        return {
            products: [],
            categories: [],

            limit: 20,
            summary: { totalCount: 0 },

            headers,
            loading: false,
            showsSearch: true
        };
    },
    computed: {
        items() {
            return [...this.products];
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { query } = this.$route;
            return { ...query };
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.search();
        },
    },
    methods: {
        async init() {
            try {
                this.search();
            } catch (error) {
                console.error(error);
            }
        },
        async search() {
            this.loading = true;
            try {
                let { skip, limit, params } = this;
                var { summary, products } = await api.console.shop.products.gets({
                    headers: { skip, limit },
                    params,
                });

                this.summary = summary;
                this.products = products.map((item, index) => ({ index: summary.totalCount - (this.page - 1) * this.limit - index, ...item }));
                
                var { categories } = await api.console.shop.categories.gets({})
                this.categories = categories;
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },

        async update(product) {
            try {
                product = (await api.console.shop.products.put(product))?.product;
                this.updateItem(product);
                alert("성공적으로 처리되었습니다.");
            } catch (error) {
                this.$handleError(error);
            }
        },

        async remove(product) {
            const go = confirm(`"${product?.name}" 상품을 삭제하시겠습니까?`);
            if (go) {
                try {
                    [{ product }] = [await api.console.shop.products.delete(product)];
                    this.products = this.products.filter(({ _id }) => _id !== product._id);
                    alert(`"${product?.name}" 상품이 삭제되었습니다.`);
                    this.summary.totalCount -= 1;
                } catch (error) {
                    this.$handleError(error);
                }
            }
        },

        updateItem(product) {
            const index = this.products.findIndex(({ _id }) => _id == product._id);
            if (0 <= index) this.products.splice(index, 1, product);
            else {
                this.products = [product, ...this.products];
                this.summary.totalCount += 1;
            }
            this.search();
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .max-width-0 {
        max-width: 0;
    }
    .white-space-pre-line {
        white-space: pre-line;
    }
    .v-pagination button {
        box-shadow: none !important;
        border: thin solid rgba(0, 0, 0, 0.12);
    }
}
</style>