<template>
    <v-dialog v-model="shows" max-width="320" persistent>
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on, disabled }" />
        </template>
        <v-card>
            <v-card-title>선택 문자여부 변경</v-card-title>
            <v-card-text class="pb-0">
                <v-row class="mx-n2">
                    <v-col>
                        <dir>문자여부(미발송/ 발송)</dir>
                        <v-switch v-model="smsEnabled"/>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions class="justify-end">
                <v-subheader class="caption">
                    선택된 항목 총
                    <span class="mx-1 text-subtitle-1 font-weight-bold primary--text">
                        {{ selected.length }}
                    </span>
                    건
                </v-subheader>
                <v-spacer />
                <v-btn text color="primary" @click="shows = false">취소</v-btn>
                <v-btn text color="primary" v-bind="{ disabled }" @click="save">변경</v-btn>
            </v-card-actions>
            <v-fade-transition>
                <v-overlay v-show="loading" absolute light color="white">
                    <v-sheet width="240" rounded style="overflow: hidden">
                        <v-progress-linear :value="(updatedLength / selected.length) * 100" height="24">
                            <span> {{ updatedLength }} / {{ selected.length }} </span>
                        </v-progress-linear>
                    </v-sheet>
                </v-overlay>
            </v-fade-transition>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import { attrs_input } from "@/assets/variables";

export default {
    components: {
    },
    props: {
        selected: { type: Array, default: () => [] },
    },
    data: () => ({
        shows: false,
        loading: false,

        updatedLength: 0,
        smsEnabled: true,

        attrs_input,
    }),
    computed: {
        disabled() {
            return !this.selected.length;
        },
    },
    watch: {
    },
    methods: {
        async save() {
            if (this.loading) return;
            this.loading = true;

            let orders = [];
            for (const { _id } of this.selected) {
                orders.push((await api.console.shop.purchaseOrders.put({ _id, smsEnabled: this.smsEnabled  }))?.order);
                this.updatedLength += 1;
            }
            setTimeout(() => {
                alert("변경되었습니다");
                this.$emit("updateItems", orders);
                this.loading = false;
                this.shows = false;
            }, 500);
        },

        async upload(code) {
        },
    },
};
</script>

<style>
</style>