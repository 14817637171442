<template>
    <v-dialog v-model="shows" width="880">
        <template #activator="{ attrs, on }"> <slot name="activator" v-bind="{ attrs, on }" /> </template>
        <v-card>
            <v-card-title>구매주문 상세 <v-spacer /> <v-icon @click="shows = false">mdi-close</v-icon> </v-card-title>
            <v-divider />
            <v-card-text class="pa-0" style="max-height: calc(90vh - 16px - 32px - 10px - 1px); overflow-y: auto">
                <table-info :value="item" />
                <table-sender :value="item" />
                <table-files :value="item" v-on="{ save }"/>
                <table-receiver :value="item" />
                <table-shipping-message :value="item" />
                <table-products :value="item" />
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import TableInfo from "./table-info.vue";
import TableFiles from "./table-files.vue";
import TableSender from "./table-sender.vue";
import TableReceiver from "./table-receiver.vue";
import TableProducts from "./table-products.vue";
import TableShippingMessage from "./table-shipping-message.vue";

export default {
    components: {
        TableInfo,
        TableFiles,
        TableSender,
        TableReceiver,
        TableProducts,
        TableShippingMessage,
    },
    props: {
        item: { type: Object, default: () => ({}) },
    },
    data: () => ({
        shows: false,
    }),
    methods: {
        save(file, code) {
            let codeMap = {
                receipt: "영수증",
                businessCard: "명함",
                greetingCard: "인사장"
            }

            if (file) {
                this.item[code] = file
                this.item[`_${code}`] = file._id
            } else {
                this.item[code] = null
                this.item[`_${code}`] = null
            }
            let form = { ...this.item };
            const message = file ? "등록" : "삭제"
            const message_success = `${codeMap[code]} ${message} 처리되었습니다.`;
            const message_failure = `${codeMap[code]}을 ${message}에 실패하였습니다.`;
            this.$emit("update", form, message_success, message_failure);
        }
    }
};
</script>

<style>
</style>