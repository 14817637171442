var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    attrs: {
      "max-width": "1280px",
      "width": "100%"
    }
  }, [_c('v-row', {
    staticClass: "mt-8",
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('board-list-heading'), _c('v-spacer'), _c('v-col', {
    staticClass: "pt-0 pb-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "ma-2",
    attrs: {
      "color": "secondary",
      "rounded": ""
    },
    on: {
      "click": function click($event) {
        _vm.showsSearch = !_vm.showsSearch;
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-filter-variant")]), _vm._v("검색필터")], 1)], 1)], 1), _c('v-expand-transition', [_c('v-card', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showsSearch,
      expression: "showsSearch"
    }],
    staticClass: "elevation-1 mx-auto"
  }, [_c('v-card-title', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_vm._v("검색필터")]), _c('v-card-text', [_c('v-row', [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-date-field', {
    attrs: {
      "label": "작성일자",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.filter.createdAt[0],
      callback: function callback($$v) {
        _vm.$set(_vm.filter.createdAt, 0, $$v);
      },
      expression: "filter.createdAt[0]"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-date-field', {
    attrs: {
      "label": "작성일자",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.filter.createdAt[1],
      callback: function callback($$v) {
        _vm.$set(_vm.filter.createdAt, 1, $$v);
      },
      expression: "filter.createdAt[1]"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mt-3"
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.searchKeys,
      "label": "검색조건",
      "hide-details": "",
      "persistent-placeholder": ""
    },
    model: {
      value: _vm.filter.searchKey,
      callback: function callback($$v) {
        _vm.$set(_vm.filter, "searchKey", $$v);
      },
      expression: "filter.searchKey"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "검색어",
      "persistent-placeholder": "",
      "hide-details": "",
      "disabled": !_vm.filter.searchKey
    },
    model: {
      value: _vm.filter.searchValue,
      callback: function callback($$v) {
        _vm.$set(_vm.filter, "searchValue", $$v);
      },
      expression: "filter.searchValue"
    }
  })], 1)], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary",
      "rounded": "",
      "text": ""
    },
    on: {
      "click": function click($event) {
        (_vm.page = 1) && _vm.search();
      }
    }
  }, [_c('v-icon', {
    staticClass: "mt-1 mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-magnify")]), _vm._v("검색")], 1)], 1)], 1)], 1), _c('v-data-table', {
    staticClass: "elevation-1 mt-4",
    attrs: {
      "items": _vm.boards,
      "headers": _vm.headers.filter(function (header) {
        return header.test ? header.test(_vm.$route.query.code) : true;
      }),
      "disable-sort": "",
      "disable-pagination": "",
      "hide-default-footer": ""
    },
    scopedSlots: _vm._u([_vm._l(_vm.headers.filter(function (header) {
      return header.hasOwnProperty('formatter');
    }), function (header) {
      return {
        key: "item.".concat(header.value),
        fn: function fn(_ref) {
          var value = _ref.value;
          return [_vm._v(" " + _vm._s(header.formatter(value)) + " ")];
        }
      };
    }), {
      key: "item.no",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(_vm.totalCount - (_vm.page - 1) * _vm.limit - +_vm.boards.indexOf(item)) + " ")];
      }
    }, {
      key: "item.thumb",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('v-row', [item.thumb ? _c('v-img', {
          attrs: {
            "src": item.thumb,
            "width": "54",
            "height": "54",
            "contain": ""
          }
        }) : _vm._e()], 1)];
      }
    }, {
      key: "item.content",
      fn: function fn(_ref4) {
        var item = _ref4.item,
            value = _ref4.value;
        return [_c('v-menu', {
          attrs: {
            "max-width": "480",
            "bottom": "",
            "close-on-content-click": false
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref5) {
              var attrs = _ref5.attrs,
                  on = _ref5.on;
              return [_c('span', _vm._g(_vm._b({}, 'span', attrs, false), on), [_vm._v(" " + _vm._s(value || "내용이 없습니다") + " ")])];
            }
          }], null, true)
        }, [_c('v-card', [_c('v-card-text', {
          staticStyle: {
            "white-space": "pre-line"
          }
        }, [_vm._v(" " + _vm._s(value) + " ")]), _c('v-divider'), _c('v-card-actions', [_c('v-btn', {
          attrs: {
            "href": "/honja/community/".concat(item._id),
            "target": "_blank",
            "color": "primary",
            "outlined": ""
          }
        }, [_vm._v(" 미리보기 ")]), _c('v-spacer'), _c('v-btn', {
          attrs: {
            "to": "/console/boards/".concat(item._id),
            "color": "primary"
          }
        }, [_vm._v(" 상세보기 ")])], 1)], 1)], 1)];
      }
    }, {
      key: "item.media",
      fn: function fn(_ref6) {
        var _item$files;

        var item = _ref6.item;
        return [!!(item !== null && item !== void 0 && item.youtube) ? [_c('v-btn', {
          attrs: {
            "href": item === null || item === void 0 ? void 0 : item.youtube,
            "target": "_blank",
            "icon": "",
            "text": ""
          }
        }, [_c('v-icon', [_vm._v("mdi-youtube")])], 1)] : (item === null || item === void 0 ? void 0 : (_item$files = item.files) === null || _item$files === void 0 ? void 0 : _item$files.length) > 0 ? [_c('images-overlay', {
          attrs: {
            "imgs": item.files
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref7) {
              var _item$files2, _item$files2$;

              var attrs = _ref7.attrs,
                  on = _ref7.on;
              return [_c('v-img', _vm._g(_vm._b({
                staticClass: "mx-auto",
                staticStyle: {
                  "cursor": "pointer"
                },
                attrs: {
                  "width": "32",
                  "height": "32",
                  "src": (_item$files2 = item.files) === null || _item$files2 === void 0 ? void 0 : (_item$files2$ = _item$files2[0]) === null || _item$files2$ === void 0 ? void 0 : _item$files2$.url
                }
              }, 'v-img', attrs, false), on))];
            }
          }], null, true)
        })] : [_vm._v("-")]];
      }
    }, {
      key: "item.writer",
      fn: function fn(_ref8) {
        var value = _ref8.value;
        return [_c('user-avatar-dialog', _vm._b({
          attrs: {
            "disabled": ""
          }
        }, 'user-avatar-dialog', {
          value: value
        }, false))];
      }
    }, {
      key: "item.actions",
      fn: function fn(_ref9) {
        var item = _ref9.item;
        return [_c('v-icon', {
          attrs: {
            "small": "",
            "title": "수정"
          },
          on: {
            "click": function click($event) {
              return _vm.edit(item);
            }
          }
        }, [_vm._v("mdi-pencil")]), false ? _c('v-icon', {
          staticClass: "ml-2",
          attrs: {
            "small": "",
            "title": "복사"
          },
          on: {
            "click": function click($event) {
              return _vm.copy(item);
            }
          }
        }, [_vm._v("mdi-content-copy")]) : _vm._e(), _c('v-icon', {
          staticClass: "ml-2",
          attrs: {
            "small": "",
            "title": "삭제"
          },
          on: {
            "click": function click($event) {
              return _vm.remove(item);
            }
          }
        }, [_vm._v("mdi-delete")])];
      }
    }], null, true)
  }), _c('v-pagination', {
    staticClass: "mt-4 mb-12",
    attrs: {
      "length": _vm.pageCount,
      "total-visible": 11
    },
    on: {
      "input": function input($event) {
        return _vm.search();
      }
    },
    model: {
      value: _vm.page,
      callback: function callback($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  }), _c('v-btn', {
    attrs: {
      "bottom": "",
      "color": "accent",
      "fab": "",
      "fixed": "",
      "right": ""
    },
    on: {
      "click": function click($event) {
        return _vm.create();
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-pencil")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }