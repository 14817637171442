<template>
    <v-data-table v-bind="{ headers, items }" dense disable-filtering disable-sort class="transparent">
        <template #top>
            <v-card-subtitle class="font-weight-bold" style="background-color: rgba(0, 0, 0, 0.05)"> <slot v-if="$slots['title']" name="title" /> <span v-else>상품</span> </v-card-subtitle> <v-divider />
        </template>
        <template #[`item.code`]="{ item, value }"> {{ value ? value : item.name || "-" }}</template>
        <template #[`item.codeAlt`]="{ item, value }"> {{ value ? value : item.name || "-" }}</template>
        <template #[`item.select`]="{ item }">
            <v-simple-checkbox :value="selected.includes(item._id)" class="mr-n2" @click="$emit('select', item._id)" />
        </template>
    </v-data-table>
</template>

<script>
export default {
    props: {
        value: { type: Object, default: () => ({}) }, // order
        selected: { type: Array, default: () => [] },
        showsSelect: { type: Boolean, default: false },
    },
    computed: {
        items() {
            return this.value?.products;
        },
        headers() {
            let headers = [
                { width: 170, text: "상품명", value: "name" },
                { width: 170, text: "규격", value: "size" },
                { width: 170, text: "암호코드", value: "code" },
                { width: 170, text: "상품코드", value: "codeAlt" },
                { width: +60, text: "수량", value: "quantity" },
            ];
            if (this.showsSelect) headers.push({ width: +60, value: "select" });

            return headers.map((item, index, array) => ({ ...item, divider: index != array.length - 1, align: item.align ?? "center", cellClass: item.cellClass || "caption", formatter: item.formatter ?? ((value) => value ?? "-") }));
        },
    },
};
</script>

<style>
</style>