var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-btn-toggle', {
    attrs: {
      "dense": "",
      "color": "primary"
    }
  }, _vm._l(_vm.items, function (_ref) {
    var to = _ref.to,
        text = _ref.text;
    return _c('v-btn', _vm._b({
      key: text,
      attrs: {
        "exact": "",
        "color": "white",
        "height": "40"
      },
      on: {
        "!click": function click($event) {
          $event.preventDefault();
          $event.stopPropagation();
          return _vm.go(to);
        }
      }
    }, 'v-btn', {
      to: to
    }, false), [_vm._v(_vm._s(text))]);
  }), 1);
}
var staticRenderFns = []

export { render, staticRenderFns }