<template>
    <v-dialog v-model="dialog" width="320" persistent>
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card light>
            <v-card-title> {{ title }} <v-spacer /> <v-icon @click="dialog = false">mdi-close</v-icon> </v-card-title>
            <v-card-text class="py-0">
                <v-row>
                    <v-col cols="12"> <v-text-field v-model="form.name" label="관리자명" v-bind="attrs_input" /> </v-col>
                    <v-col cols="12"> <v-text-field v-model="form.username" :disabled="!isCreate" label="아이디" v-bind="attrs_input" /> </v-col>
                    <v-col cols="12"> <v-text-field v-model="form.password" label="비밀번호" v-bind="attrs_input" /> </v-col>
                    <v-col cols="12"> <autocomplete-user-group v-model="form._groups__manage" multiple label="담당그룹" v-bind="attrs_input" /> </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn text color="primary" @click="save">저장</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import CryptoAES from "@/plugins/crypto-aes";
import { attrs_input, attrs_switch, USER_SCOPES } from "@/assets/variables";

import AutocompleteUserGroup from "../dumb/autocomplete-user-group.vue";

const initForm = () => ({
    _groups__manage: [],
    name: "",
    username: "",
    password: "",
});

export default {
    components: {
        AutocompleteUserGroup,
    },
    props: {
        _id: { type: String, default: null },
        // value: { type: Object, default: () => ({}) }, // user
    },
    data: () => ({
        form: initForm(),

        dialog: false,

        attrs_input,
        attrs_switch,
    }),
    computed: {
        isCreate() {
            return !this._id;
        },
        title() {
            let title = "사용자";
            title += this.isCreate ? " 생성" : " 수정";
            return title;
        },
    },
    watch: {
        dialog() {
            if (this.dialog) this.init();
        },
    },
    methods: {
        async init() {
            if (this.isCreate) this.form = initForm();
            else {
                let { _id } = this;
                let { user } = await api.console.users.get({ _id });
                if (user?.password) user.password = CryptoAES.decrypt(user.password);
                this.form = user;
            }
        },
        async save() {
            try {
                let { password, ...user } = this.form;
                if (password) password = CryptoAES.encrypt(password);
                user = { ...user, password, scope: [USER_SCOPES.console.value] };
                if (!password) delete user.password;

                let { post, put } = api.console.users;
                user = (await (this.isCreate ? post : put)(user))?.user;

                alert(this.isCreate ? "생성되었습니다" : "수정되었습니다");
                this.$emit("input", user);
                this.dialog = false;
            } catch (error) {
                this.$handleError(error);
            }
        },
    },
};
</script>
