var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "max-width": "320",
      "persistent": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var attrs = _ref.attrs,
            on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs: attrs,
          on: on,
          disabled: _vm.disabled
        })];
      }
    }], null, true),
    model: {
      value: _vm.shows,
      callback: function callback($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v("선택 명함 업로드")]), _c('v-card-text', {
    staticClass: "pb-0"
  }, [_c('v-row', {
    staticClass: "mx-n2"
  }, [_c('v-col', [_c('v-file-input', _vm._b({
    model: {
      value: _vm.file,
      callback: function callback($$v) {
        _vm.file = $$v;
      },
      expression: "file"
    }
  }, 'v-file-input', {
    attrs_input: _vm.attrs_input
  }, false))], 1)], 1)], 1), _c('v-card-actions', {
    staticClass: "justify-end"
  }, [_c('v-subheader', {
    staticClass: "caption"
  }, [_vm._v(" 선택된 항목 총 "), _c('span', {
    staticClass: "mx-1 text-subtitle-1 font-weight-bold primary--text"
  }, [_vm._v(" " + _vm._s(_vm.selected.length) + " ")]), _vm._v(" 건 ")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.shows = false;
      }
    }
  }, [_vm._v("취소")]), _c('v-btn', _vm._b({
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, 'v-btn', {
    disabled: _vm.disabled
  }, false), [_vm._v("업로드")])], 1), _c('v-fade-transition', [_c('v-overlay', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      "absolute": "",
      "light": "",
      "color": "white"
    }
  }, [_c('v-sheet', {
    staticStyle: {
      "overflow": "hidden"
    },
    attrs: {
      "width": "240",
      "rounded": ""
    }
  }, [_c('v-progress-linear', {
    attrs: {
      "value": _vm.updatedLength / _vm.selected.length * 100,
      "height": "24"
    }
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.updatedLength) + " / " + _vm._s(_vm.selected.length) + " ")])])], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }