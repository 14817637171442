<template></template>

<script>
export default {
    computed: {
        scope() {
            return this.$store.state.scope;
        },
    },
    mounted() {
        if (this.scope.includes("executive")) {
            this.$router.replace("/console/users");
        }
    },
};
</script>
