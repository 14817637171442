var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', [_c('v-card-subtitle', [_vm._v(" 데이터를 확인하세요 ")]), _c('v-divider'), _c('v-card-subtitle', {
    staticClass: "subtitle-2 font-weight-bold grey lighten-4"
  }, [_vm._v(" 주문자 ")]), _c('v-divider'), _c('table-orderer', _vm._b({}, 'table-orderer', {
    form: _vm.form
  }, false)), _c('v-divider'), _c('v-card-subtitle', {
    staticClass: "subtitle-2 font-weight-bold grey lighten-4"
  }, [_vm._v(" 배송지 / 상품 ")]), _c('v-divider'), _c('table-receivers', _vm._b({
    attrs: {
      "readonly": "",
      "showsProducts": "",
      "readonlyProducts": ""
    },
    model: {
      value: _vm.orders,
      callback: function callback($$v) {
        _vm.orders = $$v;
      },
      expression: "orders"
    }
  }, 'table-receivers', {
    isBulkOrder: _vm.isBulkOrder
  }, false)), _c('v-divider'), _c('v-card-subtitle', {
    staticClass: "subtitle-2 font-weight-bold grey lighten-4"
  }, [_vm._v(" 첨부 파일 ")]), _c('v-divider'), _c('card-attach-files', {
    attrs: {
      "value": _vm.form,
      "readonly": ""
    }
  }), _c('v-divider'), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "x-large": "",
      "text": "",
      "block": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('forward');
      }
    }
  }, [_vm._v("입력완료")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }