<template>
    <v-layout justify-center>
        <v-responsive width="100%" height="calc(100vh - 48px - 12px - 12px)" class="px-3 mx-n3">
            <v-layout d-flex column fill-height>
                <list-heading title="분당배송 집계" showsFilterButton v-model="showsSearch" style="flex: 0 1 auto" />
                <purchase-order-status-search v-bind="{ loading, showsSearch }" style="flex: 0 1 auto" />

                <v-row no-gutters justify="end" class="mr-n2">
                    <template>
                        <v-col lg="auto" class="pb-2 pr-2">
                            <excel-download-purchase-order-status-bundang v-bind="{ title, items, foot }">
                                <template #activator="{ click, loading }">
                                    <v-sheet outlined rounded>
                                        <v-btn block color="white green--text" v-bind="{ loading }" v-on="{ click }">
                                            <v-icon class="mr-1">mdi-microsoft-excel</v-icon>
                                            전체 다운로드
                                        </v-btn>
                                    </v-sheet>
                                </template>
                            </excel-download-purchase-order-status-bundang>
                        </v-col>
                    </template>
                </v-row>

                <v-data-table v-bind="{ headers, items, loading }" fixed-header disable-filtering disable-sort disable-pagination hide-default-footer :items-per-page="-1" class="v-sheet--outlined" style="flex: 0 1 auto; overflow: hidden" height="100%" mobile-breakpoint="0">
                    <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>
                    <template #foot v-if="items.length">
                        <tfoot style="position: sticky; bottom: 0; background-color: white">
                            <td :colspan="headers.length"><v-divider /></td>
                            <tr class="subtitle-2 text-center font-weight-bold primary--text">
                                <td v-for="({ value }, index) in headers" :key="value" :class="{ 'sticky-left': index == 0, 'v-data-table__divider': index != headers.length - 1 }">{{ foot[value] || "-" }}</td>
                            </tr>
                        </tfoot>
                    </template>
                </v-data-table>
            </v-layout>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import dayjs from 'dayjs';
import { PURCHASE_ORDER_TYPES } from "@/assets/variables";

import ListHeading from "@/components/console/dumb/list-heading.vue";
import PurchaseOrderStatusSearch from "@/components/console/shop/purchase-order-status/purchase-order-status-search.vue";
import ExcelDownloadPurchaseOrderStatusBundang from "@/components/console/shop/purchase-order-status/excel-download-purchase-order-status-bundang.vue"

let getProducts = ({ products }) => products;
let cleanCode = (order) => {
    let prefix = ["홈_", "롯_", "백_", "두레_", "CU_", "한비-", "한비_"].find((prefix) => order?.code?.startsWith?.(prefix));
    if (prefix) order.code = order.code.slice(prefix.length);
    return order;
};
let summarizeByCode = (items, { code, quantity, ...item }) => {
    if (code) {
        let itemsExceptThis = items.filter((item) => item.code != code);
        let sameItemInItems = items.find((item) => item.code == code) || { code, quantity: 0, ...item };
        sameItemInItems.quantity += quantity;
        return itemsExceptThis.concat(sameItemInItems);
    } else return items.concat({ code, quantity, ...item });
};
let makeItemsFrom = (orders) => orders.flatMap(getProducts).map(cleanCode).reduce(summarizeByCode, []);

export default {
    components: {
        ListHeading,
        PurchaseOrderStatusSearch,
        ExcelDownloadPurchaseOrderStatusBundang
    },
    data: () => ({
        loading: false,
        showsSearch: true,

        users: [], // 롯데백화점

        orders: {},
    }),
    computed: {
        total() {
            // 합계
            return Object.values(this.orders).reduce(summarizeByCode, []);
        },
        items() {
            return this.total
                .map(({ code, quantity, ...item }) => ({
                    ...item,
                    code,
                    total: quantity,

                    ...this.users.reduce(
                        (orders, { name }) => ({
                            ...orders,
                            [name]: this.orders[name]?.find?.((item) => item.code == code)?.quantity || 0,
                        }),
                        {}
                    ),
                }))
                .sort((a, b) => (a.code < b.code ? -1 : a.code > b.code ? 1 : 0));
        },
        headers() {
            let headers = [
                { align: "center", width: +80, text: "상품", value: "code", divider: true, class: "sticky-left z-index-3", cellClass: "sticky-left" },
                { align: "center", width: +80, text: "합계", value: "total", divider: true },
            ];

            headers = headers.concat(this.users.map(({ name }, index) => ({ align: "center", width: +80, text: name, value: name, divider: index != this.users.length - 1, class: "px-0" })));

            return headers.map((item) => ({ ...item, formatter: item.formatter ?? ((value) => value || "-") }));
        },
        foot() {
            return this.headers.reduce((foot, { value }) => {
                let data;
                if (value == "code") data = "합계";
                else data = this.items.reduce((sum, item) => (sum += item[value] || 0), 0);

                return { ...foot, [value]: data };
            }, {});
        },
        params() {
            let { ...query } = this.$route.query;
            if (query.searchDateValue) query.searchDateValue = [query.searchDateValue[0] ? dayjs(query.searchDateValue[0]).startOf("day").toISOString() : null, query.searchDateValue[1] ? dayjs(query.searchDateValue[1]).endOf("day").toISOString() : null];
            return { ...query };
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.init();
        },
    },
    methods: {
        async init() {
            let { groups = [] } = await api.console.users.groups.gets();
            let _group = groups.reduce((_groups, { _id, name }) => (name.includes("롯데백화점 수도권") ? _groups.concat(_id) : _groups), []);
            this.users = (await api.console.users.gets({ params: { _group } }))?.users || [];

            this.search();
        },

        async search() {
            this.loading = true;
            try {
                // 매장
                for (const { _id: _orderer, name } of this.users) {
                    let { orders } = await api.console.shop.purchaseOrders.gets({ params: { ...this.params, _orderer, type: PURCHASE_ORDER_TYPES.BUNDANG_HANDLING_ORDER.value } });
                    this.orders[name] = makeItemsFrom(orders);
                }
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .max-width-0 {
        max-width: 0;
    }
    .white-space-pre-line {
        white-space: pre-line;
    }
    .cursor-pointer {
        cursor: pointer;
    }
    .v-pagination button {
        box-shadow: none !important;
        border: thin solid rgba(0, 0, 0, 0.12);
    }
    .z-index-3 {
        z-index: 3 !important;
    }
    .sticky-left {
        position: sticky;
        left: 0;
        background-color: white;
    }
}
</style>