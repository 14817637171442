<template>
    <v-sheet>
        <v-card-subtitle> 데이터를 확인하세요 </v-card-subtitle>
        <v-divider />
        <v-card-subtitle class="subtitle-2 font-weight-bold grey lighten-4"> 주문자 </v-card-subtitle>
        <v-divider />
        <table-orderer v-bind="{ form }" />
        <v-divider />
        <v-card-subtitle class="subtitle-2 font-weight-bold grey lighten-4"> 배송지 / 상품 </v-card-subtitle>
        <v-divider />
        <table-receivers v-model="orders" v-bind="{ isBulkOrder }" readonly showsProducts readonlyProducts />
        <v-divider />
        <v-card-subtitle class="subtitle-2 font-weight-bold grey lighten-4"> 첨부 파일 </v-card-subtitle>
        <v-divider />
        <card-attach-files :value="form" readonly />
        <v-divider />
        <v-card-actions>
            <v-btn x-large text block color="primary" @click="$emit('forward')">입력완료</v-btn>
        </v-card-actions>
    </v-sheet>
</template>

<script>
import TableOrderer from "../table-orderer/table-orderer.vue";
import TableReceivers from "./table-receivers.vue";
import CardAttachFiles from "./card-attach-files.vue";

export default {
    components: {
        TableOrderer,
        TableReceivers,
        CardAttachFiles,
    },
    props: {
        form: { type: Object, default: () => ({}) },
        orders: { type: Array, default: () => [] },
        isBulkOrder: { type: Boolean, default: false },
    },
};
</script>

<style>
</style>